import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
// Font Awesome Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
/**
 * Container Above Footer Component
 */
const ContainerAboveFooter = ({ lwtbgColor, lwttext1, lwttext2, lwttext3, lwtlinkTo }) => {
  return (
    <div className="container fix-container-above-footer">
      <div className="row no-gutters">
        <div className={`col-md-12 ${lwtbgColor} text-white p-5`}>
          <div className="fix-strelica">
            <h1 className="dinpro-light mb-0">
              {lwttext1}
              <span className="dinpro-bold">
                {' '}
                {lwttext2} <br /> {lwttext3}
              </span>
              <NavLink
                exact
                to={lwtlinkTo}
                className="btn btn-link text-white text-decoration-none stretched-link p-0"
              >
                <span className="pl-3 span-ikonica-fa align-middle">
                  <FontAwesomeIcon icon={['fal', 'chevron-right']} />
                </span>
              </NavLink>
            </h1>
          </div>
        </div>
      </div>
    </div>
  );
};

ContainerAboveFooter.defaultProps = {
  lwtbgColor: 'bg-primary',
  lwttext1: "LET'S",
  lwttext2: 'WORK',
  lwttext3: 'TOGETHER',
  lwtlinkTo: '/contact',
};

ContainerAboveFooter.propTypes = {
  lwtbgColor: PropTypes.string,
  lwttext1: PropTypes.string,
  lwttext2: PropTypes.string,
  lwttext3: PropTypes.string,
  lwtlinkTo: PropTypes.string,
};

export default ContainerAboveFooter;
