import React, { useState, useEffect } from 'react';
// Provider za jezike ContextAPI
import { LanguageProvider } from '../context/Language';
// Data JSON and JS
import personsData from '../data/persons.json';
import personsData_bs from '../data/persons_bs.json';
import projectsData from '../data/projects.js';
import projectsData_bs from '../data/projects_bs.js';
import pagesData from '../data/pages.js';
import pagesData_bs from '../data/pages_bs.js';
import globalData from '../data/global.json';
import globalData_bs from '../data/global_bs.json';

// Cookies 🍪
import Cookies from 'js-cookie';

/**
 * Change Language ContextAPI
 */
const ChangeLanguage = (props) => {
  let _currentLng = false;

  // Check is there cookie
  if (Cookies.get('lang') === "true") {
    _currentLng = Boolean(Cookies.get('lang'))
  } else {
    // If not check is href contain /bs string
    _currentLng = (window.location.href.indexOf('/bs') > -1) ? true : false;
    Cookies.set('lang', _currentLng, {expires: 365})
  }

  // Glavni objekat, ovde stavljas sve jezike
  const [data, setData] = useState();
  const [curentLng, setCurentLng] = useState(_currentLng);

  // Set cookie on lng. switch
  Cookies.set('lang', curentLng, {expires: 365})

  const [osobe, setOsobe] = useState((_currentLng) ? personsData_bs : personsData);
  const [projekti, setProjekti] = useState((_currentLng) ? projectsData_bs : projectsData);
  const [stranice, setStranice] = useState((_currentLng) ? pagesData_bs : pagesData);
  const [globalno, setGlobalno] = useState((_currentLng) ? globalData_bs : globalData);

  useEffect(() => {
    let dataObj = {
      osobe,
      projekti,
      stranice,
      globalno,
      curentLng,
      change: () => {
        if (curentLng) {
          setOsobe(personsData);
          setProjekti(projectsData);
          setStranice(pagesData);
          setGlobalno(globalData);
          setCurentLng(false);
        } else {
          setOsobe(personsData_bs);
          setProjekti(projectsData_bs);
          setStranice(pagesData_bs);
          setGlobalno(globalData_bs);
          setCurentLng(true);
        }
      },
    };
    setData(dataObj);
  }, [_currentLng, curentLng, globalno, osobe, projekti, stranice]);

  return <LanguageProvider value={data}>{props.children}</LanguageProvider>;
};

export default ChangeLanguage;
