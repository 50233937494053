import React, { useState, useEffect, useRef, useContext } from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { Nav, Navbar, NavItem, Button } from 'react-bootstrap';
// Font Awesome Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// Custom CSS
import './Navbar.scss';
// Consumer za jezike ContextAPI
import LanguageConsumer from '../context/Language';
// Global Data JSON za prevode
import globalData from '../data/global.json';
/**
 * Navbar Component
 *
 * @augments {Component<Props, State>}
 */
const NavbarDocBa = ({ brandname, logo }) => {
  // Change Language na dugmetu BS/EN
  let data = useContext(LanguageConsumer);

  const handleChange = () => {
    setExpanded(false); // Ubacio ovdje isto da zatvara na mobile dropdown kao na ostalim opcijama kad se klikne
    data.change();
  };

  // Change Language u meniju
  const [leng, setLeng] = useState(globalData);
  useEffect(() => {
    if (data) {
      setLeng(data.globalno);
    }
  }, [data]);

  // Sakrivanje menija na scroll na svim velicinama ekrana
  const [navPrikaziSakrij, setnavPrikaziSakrij] = useState(false);

  const navRef = useRef();
  navRef.current = navPrikaziSakrij;
  useEffect(() => {
    const handleScroll = () => {
      const show = window.scrollY > 40;
      if (navRef.current !== show) {
        setnavPrikaziSakrij(show);
      }
    };
    document.addEventListener('scroll', handleScroll);
    return () => {
      document.removeEventListener('scroll', handleScroll);
    };
  }, []);

  // Dodao hooks da mi zatvara na odabrani link mobile toogle jer opcija <Navbar collapseOnSelect ... > ne radi
  const [expanded, setExpanded] = useState(false);

  // Dio koji se poziva na hamburgeru
  const openCloseNavbarMainClick = () => {
    setExpanded(expanded ? false : 'expanded');
  };

  // Dio koji se poziva na svim linkovima u padajucem meniju
  const openCloseNavbar = () => {
    setExpanded(false);
  };

  // Rjesavanje plave boje na klik hamburger menija na mobitelima
  const changeBgColor = () => {
    const klasa = expanded ? 'promjenaboje' : null;
    return klasa;
  };

  // Promjena className na dugmetu u meniju Client Area iz btn-secondary u btn-light za mob
  const changeButtonCta = () => {
    const ctaNavbarClientArea = expanded ? 'btn-secondary text-white' : 'btn-light';
    return ctaNavbarClientArea;
  };

  return (
    <Navbar
      expanded={expanded}
      id="nav-change-color"
      expand="lg"
      variant="dark" // ? mora biti upisana variant u suprotnom nikako ne prikazuje ikonicu hamburger
      className={`navbar-custom m-lg-5 ${changeBgColor()}`}
      fixed="top"
      style={{
        transition: 'all 0.3s ease-in-out',
        display: [navPrikaziSakrij ? 'none' : 'flex'],
      }}
    >
      <Navbar.Brand>
        <NavLink exact to={leng[0].rhslaghome} className="nav-link" activeClassName="active">
          <img
            src={logo}
            height="50"
            className="d-inline-block align-top"
            alt={brandname}
            onClick={openCloseNavbar}
          />
        </NavLink>
      </Navbar.Brand>
      <Navbar.Toggle
        aria-controls="responsive-navbar-nav"
        id="dugmezatvaranje"
        onClick={openCloseNavbarMainClick}
      />
      <Navbar.Collapse id="responsive-navbar-nav" className="">
        <Nav className="ml-auto er-vh">
          <NavItem>
            <NavLink
              exact
              to={leng[0].rhslaghome}
              className="nav-link paligasi"
              activeClassName="active"
              onClick={openCloseNavbar}
            >
              {leng[0].navbarhome}
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              exact
              to={leng[0].rhslagabout}
              className="nav-link paligasi"
              activeClassName="active"
              onClick={openCloseNavbar}
            >
              {leng[0].navbarabout}
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              exact
              to={leng[0].rhslagservices}
              className="nav-link paligasi"
              activeClassName="active"
              onClick={openCloseNavbar}
            >
              {leng[0].navbarservices}
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              exact
              to={leng[0].rhslagourwork}
              className="nav-link paligasi"
              activeClassName="active"
              onClick={openCloseNavbar}
            >
              {leng[0].navbarourwork}
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              exact
              to={leng[0].rhslagcontact}
              className="nav-link paligasi"
              activeClassName="active"
              onClick={openCloseNavbar}
            >
              {leng[0].navbarcontact}
            </NavLink>
          </NavItem>
          <NavItem>
            <Button
              size="sm"
              variant="link"
              className="text-decoration-none jezikbtn paligasi mx-auto mr-xl-2 XXd-none"
              onClick={handleChange}
            >
              {leng[0].navbarbsen}
              <span className="ml-2">
                <FontAwesomeIcon icon={['fal', 'angle-down']} size={'lg'} />
              </span>
            </Button>
          </NavItem>
          <NavItem>
            <a
              href="https://manage.doc.ba/clientarea.php"
              className={`client-area-cta btn btn-sm ${changeButtonCta()} px-4 py-2`}
              onClick={openCloseNavbar}
              target="_blank"
              rel="noopener noreferrer"
            >
              {leng[0].navbarclientarea}
            </a>
          </NavItem>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};

NavbarDocBa.defaultProps = {
  /** Default brand name */
  brandname: 'DOC.ba',
};

NavbarDocBa.propTypes = {
  /** Brand name used for alt tags */
  brandname: PropTypes.string,
};

export default NavbarDocBa;
