import React, { Fragment, useState, useEffect, useContext } from 'react';
import { NavLink, useHistory } from 'react-router-dom';
// Helmet za meta tagove dinamicko mjenjenje
import { Helmet } from 'react-helmet';
// Bootstrap components
import { Container, Row, Col } from 'react-bootstrap';
// Font Awesome Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// SCSS for Contact page
import './Contact.scss';
// Pages Data JSON
import pagesData from '../../data/pages.js';
// Icons
import iconAddress from '../../../assets/icons/icon-address-black.svg';
import iconEmail from '../../../assets/icons/icon-email-black.svg';
import iconPhone from '../../../assets/icons/icon-phone-black.svg';
import iconWorkTime from '../../../assets/icons/icon-worktime-black.svg';
// Home components
import Header from '../../Layout/Header';
import HeaderBox from '../../Layout/HeaderBox';
import HeaderBoxIcons from '../../Layout/HeaderBoxIcons';
import HeaderScrollDown from '../../Layout/HeaderScrollDown';
import HeaderSocialLink from '../../Layout/HeaderSocialLink';
// Page components
import CaseStady from '../../Layout/CaseStudy';
import ContainerAboveFooter from '../../Layout/ContainerAboveFooter';
// Carousel Projects components
import CarouselProjects from '../../Layout/CarouselProjects';
// ContactForm components
import ContactForm from '../../Layout/ContactForm';
// Consumer za jezike ContextAPI
import LanguageConsumer from '../../context/Language';
/**
 * Contact page component
 */
const pagesidx = 3;

const Contact = () => {
  let data = useContext(LanguageConsumer);
  const [leng, setLeng] = useState(pagesData);

  let history = useHistory();

  useEffect(() => {
    if (data) {
      setLeng(data.stranice);
      if (data.curentLng) {
        history.push('/bs/kontakt');
      } else {
        history.push('/contact');
      }
    }
  }, [data, history]);

  return (
    <Fragment>
      <Helmet>
        <title>{leng[pagesidx].rhtitle}</title>
        <meta name="description" content={leng[pagesidx].rhdesc} />
        <meta property="og:description" content={leng[pagesidx].rhdesc} />
        <meta property="og:locale" content={leng[pagesidx].rhlocale} />
      </Helmet>
      <Header
        height={'42.5em'}
        bgColor={leng[pagesidx].bcHeadera}
        bgImg={leng[pagesidx].headerimg}
        headerId={'contact'}
      >
        <Container className="header-main px-0 h-100">
          <Row className="no-gutters d-flex align-content-center align-content-sm-end align-content-lg-end h-100">
            <HeaderBox title={leng[pagesidx].title} subtitle={leng[pagesidx].subtitle} />
            <Col
              xs={12}
              lg={9}
              className="fix-iznad-scroll mx-auto d-flex justify-content-center text-white p-2 p-lg-3"
            >
              <HeaderBoxIcons boxBroj={'5/7'} boxText={leng[pagesidx].boxicont1}>
                <FontAwesomeIcon icon={['fad', 'calendar-alt']} size={'3x'} />
              </HeaderBoxIcons>
              <HeaderBoxIcons boxBroj={'8-12'} boxText={leng[pagesidx].boxicont2}>
                <FontAwesomeIcon icon={['fad', 'business-time']} size={'3x'} />
              </HeaderBoxIcons>
              <HeaderBoxIcons boxBroj={'24/7'} boxText={leng[pagesidx].boxicont3}>
                <FontAwesomeIcon icon={['fad', 'user-headset']} size={'3x'} />
              </HeaderBoxIcons>
              <HeaderBoxIcons boxBroj={'2'} boxText={leng[pagesidx].boxicont4}>
                <FontAwesomeIcon icon={['fad', 'building']} size={'3x'} />
              </HeaderBoxIcons>
            </Col>
            <HeaderScrollDown btnscrolldown={leng[pagesidx].btnscrolldown} />
          </Row>
        </Container>
        <HeaderSocialLink />
      </Header>

      <CaseStady
        casestadytitle={leng[pagesidx].casestadytitle}
        casestadysubtitle={leng[pagesidx].casestadysubtitle}
        casestadytext={leng[pagesidx].casestadytext}
        casestadybtn={leng[pagesidx].casestadybtn}
      />

      <Container className="px-0 mb-3 mb-sm-0">
        <Row className="no-gutters">
          <Col xs={12} sm={12} md={6} lg={6}>
            <ContactForm
              cffirstname={leng[pagesidx].cffirstname}
              cflasttname={leng[pagesidx].cflasttname}
              cfemail={leng[pagesidx].cfemail}
              cfphone={leng[pagesidx].cfphone}
              cfsubject={leng[pagesidx].cfsubject}
              cfmessage={leng[pagesidx].cfmessage}
              cfsubmit={leng[pagesidx].cfsubmit}
              cfsuccess={leng[pagesidx].cfsuccess}
              cfsucmess={leng[pagesidx].cfsucmess}
              cferror={leng[pagesidx].cferror}
              cferrormes={leng[pagesidx].cferrormes}
              cffirstnamefcfi={leng[pagesidx].cffirstnamefcfi}
              cflasttnamefcfi={leng[pagesidx].cflasttnamefcfi}
              cfemailfcfi={leng[pagesidx].cfemailfcfi}
              cfphonefcfi={leng[pagesidx].cfphonefcfi}
              cfsubjectfcfi={leng[pagesidx].cfsubjectfcfi}
              cfmessagefcfi={leng[pagesidx].cfmessagefcfi}
            />
          </Col>
          <Col xs={12} sm={12} md={6} lg={6} className="d-flex justify-content-center">
            <Row className="no-gutters er-4icons-contact">
              <Col xs={12} className="text-center">
                <Row className="no-gutters p-3">
                  <Col md={6} className="er-iconaddress border-dark">
                    <a
                      href="https://goo.gl/maps/2qB1Fo4Mto3dNNvX8"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-decoration-none text-dark"
                    >
                      <div className="fill-class-contact py-4">
                        <img src={iconAddress} className="my-2" alt="Address" />
                        <p className="my-2">
                          {leng[pagesidx].box4adresa1}
                          <br />
                          {leng[pagesidx].box4adresa2}
                        </p>
                      </div>
                    </a>
                  </Col>
                  <Col md={6} className="er-iconworktime border-dark">
                    <NavLink
                      exact
                      to={leng[pagesidx].urlTo}
                      className="text-decoration-none text-dark"
                    >
                      <div className="fill-class-contact py-4">
                        <img src={iconWorkTime} className="my-2" alt="Work Time" />
                        <p className="my-2">
                          {leng[pagesidx].box4radnov1} <br />
                          {leng[pagesidx].box4radnov2}
                        </p>
                      </div>
                    </NavLink>
                  </Col>
                  <Col md={6} className="er-iconphone border-dark">
                    <a
                      href="tel:+38733877851"
                      rel="noopener noreferrer"
                      className="text-decoration-none text-dark"
                    >
                      <div className="fill-class-contact py-4">
                        <img src={iconPhone} className="my-2" alt="Phone" />
                        <p className="my-2">+387 33 877 851</p>
                      </div>
                    </a>
                  </Col>
                  <Col md={6} className="er-iconemail">
                    <a
                      href="mailto:info@doc.ba"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-decoration-none text-dark"
                    >
                      <div className="fill-class-contact py-4">
                        <img src={iconEmail} className="my-2" alt="" />
                        <p className="my-2">info@doc.ba</p>
                      </div>
                    </a>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>

      <Container className="px-sm-0 mt-4 mb-2">
        <Row className="no-gutters bg-doc-iug-gray text-dark info-tingea rounded p-4 p-sm-5">
          <Col xs={12} sm={12} md={4} lg={4} className="pb-3">
            <p className="m-0">
              <strong>Tingea d.o.o. Sarajevo</strong>{' '}
            </p>
            <p className="m-0">ID: 4201848920002</p>
            <p className="m-0">{leng[pagesidx].tingeareg0}: 201848920002</p>
            <p className="m-0">KD BiH 2010: 62.01</p>
            <p className="m-0">KD: 72.220</p>
          </Col>
          <Col xs={12} sm={12} md={4} lg={4} className="pb-3">
            <p className="m-0">
              <strong>UniCredit bank d.d.</strong>
            </p>
            <p className="m-0">3387 2022 2279 2930</p>
            <p className="m-0">IBAN: BA39 3387 2048 2279 3346</p>
            <p className="m-0">SWIFT: UNCRBA22</p>
          </Col>
          <Col xs={12} sm={12} md={4} lg={4} className="pb-1">
            <p className="m-0">
              <strong>{leng[pagesidx].tingeareg1}</strong>
            </p>
            <p className="m-0">05.03.2013. {leng[pagesidx].tingeareg2}</p>
            <p className="m-0">{leng[pagesidx].tingeareg3} 065-0-Reg-013-000443</p>
            <p className="m-0">{leng[pagesidx].tingeareg4} 65-01-0202-13</p>
          </Col>
        </Row>
      </Container>

      <div className="fix-container-carousel">
        <Container className="px-0 mb-sm-4 mb-md-5">
          <Row className="no-gutters">
            <CarouselProjects />
          </Row>
        </Container>
      </div>

      <ContainerAboveFooter
        lwtbgColor={'bg-doc-light-blue'}
        lwttext1={leng[pagesidx].lwttext1}
        lwttext2={leng[pagesidx].lwttext2}
        lwttext3={leng[pagesidx].lwttext3}
        lwtlinkTo={leng[pagesidx].rhlinkow}
      />
    </Fragment>
  );
};

export default Contact;
