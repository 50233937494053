import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import PropTypes from 'prop-types';
/**
 * Deviders Component
 */
const Deviders = ({ striketitle, strikepoz, strikecol }) => {
  return (
    <Container className="px-0 pt-4 pt-sm-0">
      <Row className="no-gutters mx-auto">
        <Col
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          className="text-dark mb-4 mt-1 mt-sm-5 mb-sm-5 px-3 px-sm-0"
        >
          <h4 className={`${strikepoz} mb-0`}>
            <span className={`${strikecol} dinpro-bold`}>{striketitle}</span>
          </h4>
        </Col>
      </Row>
    </Container>
  );
};

Deviders.defaultProps = {
  striketitle: 'SEE MORE OF OUR WORK',
  strikepoz: 'strike-l',
  strikecol: 'strike-b',
};

Deviders.propTypes = {
  striketitle: PropTypes.string,
  strikepoz: PropTypes.string,
  strikecol: PropTypes.string,
};

export default Deviders;
