import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
// Projects Data JSON
import projectsData from '../data/projects.js';
// Consumer za jezike ContextAPI
import LanguageConsumer from '../context/Language';
/**
 * Carousel Projects Items component
 */
const CarouselProjectsItems = ({ pageidx, logoWidth }) => {
  var data = useContext(LanguageConsumer);
  const [leng, setLeng] = useState(projectsData);

  useEffect(() => {
    if (data) {
      setLeng(data.projekti);
    }
  }, [data]);

  return (
    <div className="card text-center text-white rounded-0 border-0">
      <img src={leng[pageidx].srcfi} className="card-img rounded-0 border-0" alt="" />
      <div className="card-img-overlay bg-doc-dark">
        <div className="card-body text-center d-flex align-items-center flex-column h-100 mx-auto my-0 p-0">
          <div className="card-title mt-auto mb-0">
            <img
              src={leng[pageidx].src}
              className={`card-img ${logoWidth}`}
              alt={leng[pageidx].title}
            />
          </div>
          <div className="card-text mt-auto mb-0 gasi-active">
            <NavLink
              exact
              to={leng[pageidx].urlTo}
              className="btn btn-outline-light btn-sm dinpro-light"
            >
              {leng[pageidx].btnseeprojpage}
            </NavLink>
          </div>
        </div>
      </div>
    </div>
  );
};

CarouselProjectsItems.defaultProps = {
  pageidx: 0,
  logoWidth: 'w-75',
};

CarouselProjectsItems.propTypes = {
  pageidx: PropTypes.number,
  logoWidth: PropTypes.string,
};

export default CarouselProjectsItems;
