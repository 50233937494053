import React, { Fragment, useState, useEffect, useContext } from 'react';
import { NavLink, useHistory } from 'react-router-dom';
// Helmet za meta tagove dinamicko mjenjenje
import { Helmet } from 'react-helmet';
// Bootstrap components
import { Container, Row, Col } from 'react-bootstrap';
// Font Awesome Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// SCSS for Home page
import './Home.scss';
// Global Data JSON za prevode
import globalData from '../../data/global.json';
// Static header image
import bgImgMain from '../../../assets/img/bg-home-header.png';
import bgImgMainMob from '../../../assets/img/bg-home-header-mob.png';
import bgImgRocket from '../../../assets/img/rocket_main_banner.png';
import clientAreaOverlay from '../../../assets/img/client-area-overlay.png';
// Logos
import IugLogo from '../../../assets/img/logos/logoIUG.svg';
import PoliglotLogo from '../../../assets/img/logos/logoPOLIGLOT.svg';
import PomoziLogo from '../../../assets/img/logos/logoPOMOZIBA.svg';
import OCCLogo from '../../../assets/img/logos/logoOCC.svg';
import NOSALogo from '../../../assets/img/logos/logoNOSA.svg';
// Icons
import iconAddress from '../../../assets/icons/icon-address.svg';
import iconEmail from '../../../assets/icons/icon-email.svg';
import iconPhone from '../../../assets/icons/icon-phone.svg';
import iconWorkTime from '../../../assets/icons/icon-worktime.svg';
// Home components
import Header from '../../Layout/Header';
import Widget from '../../Widget';
// Utility Component
import UseWindowWidth from '../../Layout/UseWindowWidth';
// Consumer za jezike ContextAPI
import LanguageConsumer from '../../context/Language';

/**
 * Home page component
 */
const Home = () => {
  let data = useContext(LanguageConsumer);
  const [leng, setLeng] = useState(globalData);

  let history = useHistory();

  useEffect(() => {
    if (data) {
      setLeng(data.globalno);
      if (data.curentLng) {
        history.push('/bs');
      } else {
        history.push('/');
      }
    }
  }, [data, history]);

  // Mjenjanje background img za mob i desktop na kraju je ubacen - Custom Hooks
  const imageUrl = UseWindowWidth() >= 650 ? bgImgMain : bgImgMainMob;

  return (
    <Fragment>
      <Helmet>
        <title>{leng[0].rhtitle}</title>
        <meta name="description" content={leng[0].rhdesc} />
        <meta property="og:description" content={leng[0].rhdesc} />
        <meta property="og:locale" content={leng[0].rhlocale} />
      </Helmet>
      <Header bgImg={imageUrl} bgColor={'#000f80'} headerId={'home'}>
        <div className="d-block d-sm-none text-white text-center home-social h-100">
          <div className="p-1 d-inline-flex bg-dark">
            <div className="pr-1">
              <a
                href="https://www.facebook.com/ITDOC.ba/"
                className="btn btn-dark px-0 py-0"
                target="_blank"
                rel="noopener noreferrer"
                style={{ minWidth: '26px' }}
              >
                <FontAwesomeIcon icon={['fab', 'facebook-f']} />
              </a>
            </div>
            <div className="px-1 social-home-icons">
              <a
                href="https://www.linkedin.com/company/docba"
                className="btn btn-dark px-0 py-0"
                target="_blank"
                rel="noopener noreferrer"
                style={{ minWidth: '26px' }}
              >
                <FontAwesomeIcon icon={['fab', 'linkedin-in']} />
              </a>
            </div>
            <div className="pl-1">
              <a
                href="https://goo.gl/maps/qGhN1z9yyhbxWEBW6"
                className="btn btn-dark px-0 py-0"
                target="_blank"
                rel="noopener noreferrer"
                style={{ minWidth: '26px' }}
              >
                <FontAwesomeIcon icon={['fab', 'google']} />
              </a>
            </div>
          </div>
        </div>
        <Row className="fix-row-home no-gutters vh-100">
          <Col
            sm={6}
            md={6}
            lg={6}
            className="home-rocket order-1 order-sm-0 d-flex align-items-end"
          >
            <img src={bgImgRocket} alt="" className="rocket__img img-fluid" />
          </Col>
          <Col
            sm={6}
            md={6}
            lg={6}
            className="order-0 order-sm-1 align-self-center text-white pl-lg-5"
          >
            <div className="home-text-dugme">
              <h1 className="dinpro-bold">
                {leng[0].homeslogan1}
                <br />
                {leng[0].homeslogan2}
              </h1>
              <h3 className="mt-4 text-uppercase dinpro-medium">
                {leng[0].homeslogansmall1} <br />
                {leng[0].homeslogansmall2}
              </h3>
              <NavLink
                exact
                to={leng[0].rhslagourwork}
                className="er-btn-dow btn btn-primary btn-lg text-uppercase mt-3"
              >
                {leng[0].homebtndow}
              </NavLink>
              <Col xs={12} className="text-white text-left px-0 pt-1">
                <div className="title-ikonice">
                  <a
                    id="sdtsos"
                    href="#about"
                    className="small text-white text-decoration-none align-middle dinpro-medium m-0"
                  >
                    <span className="title-ikonice align-middle pr-3">
                      <FontAwesomeIcon icon={['fal', 'mouse-alt']} size={'2x'} />
                    </span>
                    {leng[0].homebtnscrolldown}
                  </a>
                </div>
              </Col>
            </div>
          </Col>
        </Row>
      </Header>

      <Container className="home-owerlap">
        <Row className="no-gutters prvi-red">
          <Col sm={12} md={12} lg={4} className="bg-primary text-white p-5 pb-lg-0" id="about">
            <NavLink
              exact
              to={leng[0].rhslagabout}
              className="text-white text-decoration-none stretched-link"
            >
              <Row className="no-gutters">
                <Col xs={12} sm={12} md={4} lg={12}>
                  <div className="fix-strelica">
                    <h1 className="dinpro-light pb-4 d-block d-md-none">
                      {leng[0].homeaboutlight}
                      <span className="dinpro-bold">
                        {' '}
                        {leng[0].homeaboutbold}
                        <span className="pl-3 span-ikonica-fa">
                          <FontAwesomeIcon icon={['fal', 'chevron-right']} />
                        </span>
                      </span>
                    </h1>
                    <h1 className="dinpro-light pb-4 d-none d-md-block">
                      {leng[0].homeaboutlight}
                      <span className="dinpro-bold">
                        {' '}
                        <br />
                        {leng[0].homeaboutbold}
                        <span className="pl-3 span-ikonica-fa d-inline-block d-lg-none">
                          <FontAwesomeIcon icon={['fal', 'chevron-right']} />
                        </span>
                      </span>
                    </h1>
                  </div>
                </Col>
                <Col xs={12} sm={12} md={8} lg={12}>
                  <div className="aboutus">
                    <div className="d-block d-lg-none">
                      <p className="mb-0 pt-1 pb-lg-5">
                        {leng[0].homeabouttext}
                        <br />
                        <br />
                        {leng[0].homeaboutbtnrm}
                      </p>
                    </div>
                    <div className="d-none d-lg-block">
                      <p className="offset-1 mt-1 float-left">
                        <span className="span-ikonica-fa">
                          <FontAwesomeIcon icon={['fal', 'chevron-right']} />
                        </span>
                      </p>
                      <p className="offset-3 mb-0 pt-1 pb-lg-5">
                        {leng[0].homeabouttext}
                        <br />
                        <br />
                        {leng[0].homeaboutbtnrm}
                      </p>
                    </div>
                  </div>
                </Col>
              </Row>
            </NavLink>
          </Col>
          <Col sm={12} md={12} lg={8} className="bg-doc-red">
            <Row className="no-gutters">
              <Col xs={12} sm={12} md={6} lg={6} className="bg-doc-red text-white pt-5 pl-5">
                <NavLink
                  exact
                  to={leng[0].rhslagourwork}
                  className="text-white text-decoration-none stretched-link"
                >
                  <div className="fix-strelica">
                    <h1 className="dinpro-light">
                      {leng[0].homedowlight}
                      <br />
                      <span className="dinpro-bold d-block d-md-none">
                        {' '}
                        {leng[0].homedowbold1} {leng[0].homedowbold2}
                        <span className="pl-3 span-ikonica-fa">
                          <FontAwesomeIcon icon={['fal', 'chevron-right']} />
                        </span>
                      </span>
                      <span className="dinpro-bold d-none d-md-block">
                        {' '}
                        {leng[0].homedowbold1} <br /> {leng[0].homedowbold2}
                        <span className="pl-3 span-ikonica-fa">
                          <FontAwesomeIcon icon={['fal', 'chevron-right']} />
                        </span>
                      </span>
                    </h1>
                  </div>
                </NavLink>
              </Col>
              <Col xs={12} sm={12} md={6} lg={6} className="bg-doc-red pt-4 pt-md-5 pb-5 pr-md-5">
                <Row className="no-gutters">
                  <Col
                    xs={12}
                    className="d-flex justify-content-between justify-content-sm-around justify-content-md-between px-5 px-md-0 col-12"
                  >
                    <NavLink exact to={leng[0].rhslagpoliglot}>
                      <img src={PoliglotLogo} alt="Logo POLIGLOT" className="discover__image" />
                    </NavLink>
                    <NavLink exact to={leng[0].rhslagpomozi}>
                      <img src={PomoziLogo} alt="Logo POMOZI.BA" className="discover__image" />
                    </NavLink>
                  </Col>
                  <Col
                    xs={12}
                    className="d-flex justify-content-between justify-content-sm-around justify-content-md-between px-5 px-md-0"
                  >
                    <NavLink exact to={leng[0].rhslagocc}>
                      <img
                        src={OCCLogo}
                        alt={`Logo ${leng[0].rhslagocc}`}
                        className="discover__image_svg"
                      />
                    </NavLink>
                    <NavLink exact to={leng[0].rhslagiug}>
                      <img src={IugLogo} alt="Logo IUG" className="discover__image_svg" />
                    </NavLink>

                    <NavLink exact to={leng[0].rhslagnovosa}>
                      <img
                        src={NOSALogo}
                        alt="Logo NOVO SARAJEVO"
                        className="discover__image_svg"
                      />
                    </NavLink>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="no-gutters fix-drugi-red">
          <Col md={4} lg={4} className="col-lg-4 col-md-4 order-1 order-lg-0 d-none d-sm-block">
            <Row className="no-gutters">
              <Col className="col-12 d-none d-sm-block fix-col-drugired1"></Col>
              <Col className="col-12 bg-primary d-none d-sm-block fix-col-drugired2">
                <NavLink exact to={leng[0].rhslagabout}></NavLink>
              </Col>
            </Row>
          </Col>
          <Col sm={12} md={12} lg={8} className="order-0 order-lg-1 d-none d-sm-block">
            <Row className="no-gutters">
              <Col
                sm={5}
                md={5}
                lg={5}
                className="d-flex align-items-center bg-white fix-client-area text-doc-client-area py-5 pl-5 pr-0"
              >
                <a
                  href="https://manage.doc.ba/clientarea.php"
                  className="text-doc-client-area text-decoration-none stretched-link"
                  style={{ transition: 'all 0.6s' }}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <div className="fix-strelica">
                    <h1 className="dinpro-light">
                      {leng[0].homeclientarealight}
                      <br />
                      <span className="dinpro-bold">{leng[0].homeclientareabold}</span>
                      <span className="pl-3 span-ikonica-fa">
                        <FontAwesomeIcon icon={['fal', 'chevron-right']} />
                      </span>
                    </h1>
                    {leng[0].homeclientareaclick}
                  </div>
                </a>
              </Col>
              <Col
                sm={7}
                md={7}
                lg={7}
                className="p-0 text-white bg-doc-client-area"
                style={{ maxHeight: '260px' }}
              >
                <Widget bgImg={clientAreaOverlay} classes="vh-center">
                  <FontAwesomeIcon icon={['fal', 'user']} size="6x" />
                </Widget>
              </Col>
              <Col sm={5} md={5} lg={5} className="bg-white d-none d-sm-block fix-colh"></Col>
              <Col sm={7} md={7} lg={7} className="bg-white d-none d-sm-block fix-colh"></Col>
            </Row>
          </Col>
        </Row>
      </Container>

      <Container className="fix-container-home">
        <Row className="no-gutters">
          <Col sm={12} md={7} lg={8} className="order-1 order-md-0">
            <Row className="no-gutters">
              <div className="col-md-11-x5 bg-doc-light-gray text-white text-center px-2 px-lg-5 py-4">
                <Row className="no-gutters">
                  <Col md={6} className="er-iconaddress">
                    <a
                      href="https://goo.gl/maps/2qB1Fo4Mto3dNNvX8"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-decoration-none text-white"
                    >
                      <div className="fill-class-home">
                        <img src={iconAddress} className="my-2" alt="" />
                        <p className="mt-2 mb-4 px-1">
                          {leng[0].homebox4adresa1} <br />
                          {leng[0].homebox4adresa2}
                        </p>
                      </div>
                    </a>
                  </Col>
                  <Col md={6} className="er-iconworktime">
                    <NavLink
                      exact
                      to={leng[0].rhslagcontact}
                      className="text-decoration-none text-white"
                    >
                      <div className="fill-class-home">
                        <img src={iconWorkTime} className="my-2" alt="" />
                        <p className="mt-2 mb-4 px-1">
                          {leng[0].homebox4radnov1} <br />
                          {leng[0].homebox4radnov2}
                        </p>
                      </div>
                    </NavLink>
                  </Col>
                  <Col md={6} className="er-iconphone">
                    <a
                      href="tel:+38733877851"
                      rel="noopener noreferrer"
                      className="text-decoration-none text-white"
                    >
                      <div className="fill-class-home">
                        <img src={iconPhone} className="mt-4 mb-2" alt="" />
                        <p className="my-2 pb-3 px-1">+387 33 877 851</p>
                      </div>
                    </a>
                  </Col>
                  <Col md={6} className="er-iconemail">
                    <a
                      href="mailto:info@doc.ba"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-decoration-none text-white"
                    >
                      <div className="fill-class-home">
                        <img src={iconEmail} className="mt-4 mb-2" alt="" />
                        <p className="my-2 pb-3 px-1">info@doc.ba</p>
                      </div>
                    </a>
                  </Col>
                </Row>
              </div>
              <div className="col-md-0-x5 bg-doc-pink d-none d-sm-block fix-colh"></div>
              <div className="col-md-11-x5 bg-doc-light-blue text-white p-5">
                <NavLink
                  exact
                  to={leng[0].rhslagcontact}
                  className="text-white text-decoration-none"
                >
                  <div className="fix-strelica">
                    <h1 className="dinpro-light">
                      {leng[0].homelwtlight}{' '}
                      <span className="dinpro-bold">{leng[0].homelwtbold1}</span>
                      <br />
                      <span className="dinpro-bold">
                        {leng[0].homelwtbold2}
                        <span className="pl-3 span-ikonica-fa">
                          <FontAwesomeIcon icon={['fal', 'chevron-right']} />
                        </span>
                      </span>
                    </h1>
                  </div>
                </NavLink>
              </div>
              <div className="col-md-0-x5 bg-doc-pink d-none d-sm-block fix-colh"></div>
            </Row>
          </Col>
          <Col sm={12} md={5} lg={4} className="order-0 order-md-1 bg-doc-pink text-white p-5">
            <Row className="no-gutters">
              <Col xs={12} sm={7} md={12} lg={12}>
                <div className="fix-strelica ml-md-n3">
                  <h1 className="dinpro-light pb-4 d-block d-md-none">
                    {leng[0].homesoslight}
                    <span className="dinpro-bold">
                      {' '}
                      {leng[0].homesosbold1} <br />
                      {leng[0].homesosbold2}
                      <span className="pl-3 span-ikonica-fa">
                        <FontAwesomeIcon icon={['fal', 'chevron-right']} />
                      </span>
                    </span>
                  </h1>
                  <h1 className="dinpro-light pb-4 d-none d-md-block">
                    {leng[0].homesoslight}{' '}
                    <span className="dinpro-bold">
                      {leng[0].homesosbold1}
                      <br />
                      {leng[0].homesosbold2}
                      <span className="pl-3 span-ikonica-fa d-inline-block d-md-none">
                        <FontAwesomeIcon icon={['fal', 'chevron-right']} />
                      </span>
                    </span>
                  </h1>
                </div>
                <NavLink
                  exact
                  to={leng[0].rhslagservices}
                  className="text-white text-decoration-none stretched-link"
                ></NavLink>
              </Col>
              <Col xs={12} sm={5} md={12} lg={12} className="pl-sm-4">
                <NavLink
                  exact
                  to={leng[0].rhslagservices}
                  className="text-white text-decoration-none px-0 stretched-link"
                >
                  <div className="d-block d-md-none ml-md-n3">
                    <p className="mb-0 pt-1">
                      {leng[0].homesositem1}
                      <br />
                      {leng[0].homesositem2}
                      <br />
                      {leng[0].homesositem3}
                      <br />
                      {leng[0].homesositem4}
                      <br />
                      {leng[0].homesositem5}
                      <br />
                      {leng[0].homesositem6}
                      <br />
                      {leng[0].homesositem7}
                      <br />
                      {leng[0].homesositem8}
                      <br />
                      <br />
                      {leng[0].homesositembtn}
                    </p>
                  </div>
                  <div className="d-none d-md-block ml-md-n3">
                    <p className="offset-lg-1 mt-1 float-left">
                      <span className="span-ikonica-fa">
                        <FontAwesomeIcon icon={['fal', 'chevron-right']} />
                      </span>
                    </p>
                    <p className="offset-2 offset-lg-3 mb-0 pt-1">
                      {leng[0].homesositem1}
                      <br />
                      {leng[0].homesositem2}
                      <br />
                      {leng[0].homesositem3}
                      <br />
                      {leng[0].homesositem4}
                      <br />
                      {leng[0].homesositem5}
                      <br />
                      {leng[0].homesositem6}
                      <br />
                      {leng[0].homesositem7}
                      <br />
                      {leng[0].homesositem8}
                      <br />
                      <br />
                      {leng[0].homesositembtn}
                    </p>
                  </div>
                </NavLink>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default Home;
