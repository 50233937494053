import React from 'react';
import PropTypes from 'prop-types';
import './Widget.scss';

/**
 * Widget Component
 *
 * @augments {Component<Props, State>}
 */
const Widget = ({
  bgColor,
  bgImg,
  color,
  height,
  width,
  padding,
  children,
  classes,
  style,
}) => {
  const intStyle = {
    backgroundImage: bgImg && `url(${bgImg})`,
    backgroundColor: bgColor,
    height: height,
    width: width,
    padding: padding,
    position: 'relative',
    color: color,
  };

  // Format classes props
  let finalClasses = '';
  if (classes) {
    const c = classes.split(' ').map(y => {
      return 'widget-' + y;
    });
    finalClasses = c.join(' ');
  }

  // Combine internal and external styles
  const finalStyle = { ...style, ...intStyle };

  return (
    <div className={'widget ' + finalClasses} style={finalStyle}>
      {children}
    </div>
  );
};

Widget.defaultProps = {
  height: '100%',
  width: '100%',
  padding: '40px',
};

Widget.propTypes = {
  /** CSS standard background color */
  bgColor: PropTypes.string,
  height: PropTypes.string,
  width: PropTypes.string,
  padding: PropTypes.string,
};

export default Widget;
