import React, { useState, useEffect, useContext } from 'react';
// Bootstrap
import { Button } from 'react-bootstrap';
// Font Awesome Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// Carousel - react-multi-carousel
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
// Items for Carousel
import CarouselProjectsItems from './CarouselProjectsItems';
// GLOBALNI prevodi
import globalData from '../data/global.json';
// Consumer za jezike ContextAPI
import LanguageConsumer from '../context/Language';
/**
 * CarouselProjects component
 */
const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 3000 },
    items: 4,
    slidesToSlide: 2,
  },
  desktop: {
    breakpoint: { max: 2999.98, min: 1024 },
    items: 3,
    slidesToSlide: 1,
  },
  tablet: {
    breakpoint: { max: 1023.98, min: 576 },
    items: 2,
    slidesToSlide: 1,
  },
  mobile: {
    breakpoint: { max: 575.98, min: 0 },
    items: 1,
    slidesToSlide: 1,
  },
};
// Button for Prev Next
const ButtonGroupAsArrows = ({ next, previous }) => {
  var data = useContext(LanguageConsumer);
  const [leng, setLeng] = useState(globalData);

  useEffect(() => {
    if (data) {
      setLeng(data.globalno);
    }
  }, [data]);

  return (
    <div className="container my-4 my-md-5 px-3 px-sm-0 order-0">
      <div className="row no-gutters d-flex justify-content-between align-items-middle">
        <div className="col pr-2">
          <h4 className="strike-r mb-0">
            <span className="strike-b dinpro-bold">{leng[0].devidertext}</span>
          </h4>
        </div>
        <div className="col-auto">
          <div className="btn-groupx">
            <Button
              variant="outline-dark btn-sm rounded-0 px-1 mr-2"
              className=""
              onClick={previous}
            >
              <FontAwesomeIcon icon={['fas', 'chevron-left']} size="lg" fixedWidth />
            </Button>
            <Button
              variant="outline-dark btn-sm rounded-0 px-1 ml-auto"
              className=""
              onClick={next}
            >
              <FontAwesomeIcon icon={['fas', 'chevron-right']} size="lg" fixedWidth />
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};
// CAROUSEL PROJECTS
const CarouselProjects = ({ deviceType }) => {
  return (
    <Carousel
      swipeable={true}
      draggable={false}
      showDots={false}
      responsive={responsive}
      infinite={true}
      autoPlay={deviceType !== 'mobile' ? true : false}
      autoPlaySpeed={2000}
      keyBoardControl={true}
      transitionDuration={500}
      containerClass="container px-0 order-1"
      arrows={false}
      renderDotsOutside={false}
      renderButtonGroupOutside={true}
      customButtonGroup={<ButtonGroupAsArrows />}
      deviceType={deviceType}
      itemClass="projects-items"
    >
      <CarouselProjectsItems pageidx={0} />
      <CarouselProjectsItems pageidx={1} logoWidth={'w-50'} />
      <CarouselProjectsItems pageidx={2} />
      <CarouselProjectsItems pageidx={3} logoWidth={'w-50'} />
      <CarouselProjectsItems pageidx={4} />
      <CarouselProjectsItems pageidx={5} logoWidth={'w-50'} />
      <CarouselProjectsItems pageidx={6} />
      <CarouselProjectsItems pageidx={7} />
      <CarouselProjectsItems pageidx={8} logoWidth={'w-50'} />
      <CarouselProjectsItems pageidx={9} />
      <CarouselProjectsItems pageidx={10} logoWidth={'w-50'} />
    </Carousel>
  );
};

export default CarouselProjects;
