import React from 'react';
import PropTypes from 'prop-types';
/**
 * HeaderBoxIcons Component
 */
const HeaderBoxIcons = ({ boxBroj, boxText, children }) => {
  return (
    <div className="col-3 p-1 p-lg-3">
      <div className="box-part text-center">
        <div className="title-ikonice">{children}</div>
        <h2 className="dinpro-light pt-2">{boxBroj}</h2>
        <div className="mx-auto" style={{ width: '30px', borderBottom: '1px solid #fff' }}></div>
        <div className="small dinpro-medium text-center pt-2">{boxText}</div>
      </div>
    </div>
  );
};

HeaderBoxIcons.defaultProps = {
  boxBroj: '30',
  boxText: 'TEXT',
};

HeaderBoxIcons.propTypes = {
  boxBroj: PropTypes.string,
  boxText: PropTypes.string,
};

export default HeaderBoxIcons;
