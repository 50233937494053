import React from 'react';
// Font Awesome Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
/**
 * Case Study Component
 */
const CaseStudy = ({ casestadytitle, casestadysubtitle, casestadytext, casestadybtn }) => {
  return (
    <div className="container px-0">
      <div className="row no-gutters">
        <div className="col-md-8 mx-auto bg-white text-dark text-center p-5" id="scrollto">
          <div className="fix-strelica">
            <h1 className="dinpro-light vertical-align-middle">{casestadytitle}</h1>
            <h3 className="dinpro-light vertical-align-middle">{casestadysubtitle}</h3>
            <p className="py-4">{casestadytext}</p>
            <div className={`btn ${casestadybtn} py-2 dugmedole`}>
              <FontAwesomeIcon icon={['fal', 'chevron-double-down']} size="2x" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CaseStudy;
