import React, { Fragment } from 'react';
// Bootstrap components
import { Row } from 'react-bootstrap';
// Font Awesome Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
/**
 * Header Social Link component
 */
const HeaderSocialLink = () => {
  return (
    <Fragment>
      <div className="container-xl px-0 er-mr-n6">
        <Row className="no-gutters">
          <div className="col-12 d-flex justify-content-end text-white text-center">
            <div className="p-1 d-inline-flex bg-dark ">
              <div className="pr-1">
                <a
                  href="https://www.facebook.com/ITDOC.ba/"
                  className="btn btn-dark px-0 py-0"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ minWidth: '26px' }}
                >
                  <FontAwesomeIcon icon={['fab', 'facebook-f']} />
                </a>
              </div>
              <div className="px-1 social-home-icons">
                <a
                  href="https://www.linkedin.com/company/docba"
                  className="btn btn-dark px-0 py-0"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ minWidth: '26px' }}
                >
                  <FontAwesomeIcon icon={['fab', 'linkedin-in']} />
                </a>
              </div>
              <div className="pl-1">
                <a
                  href="https://goo.gl/maps/qGhN1z9yyhbxWEBW6"
                  className="btn btn-dark px-0 py-0"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ minWidth: '26px' }}
                >
                  <FontAwesomeIcon icon={['fab', 'google']} />
                </a>
              </div>
            </div>
          </div>
        </Row>
      </div>
    </Fragment>
  );
};

export default HeaderSocialLink;
