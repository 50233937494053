import React from 'react';
/**
 * Projects Pages The Solution Component
 */
const ProjectsPagesTheSolution = ({
  solutionbgcol,
  solutionlight,
  solutionbold,
  solutiontext1,
  solutiontext2,
  solutiontext3,
  strikepoz,
  strikecol,
  potitle,
  poicon1,
  poicon1txt,
  poicon2,
  poicon2txt,
  poicon3,
  poicon3txt,
  poicon4,
  poicon4txt,
}) => {
  return (
    <div className={`container ${solutionbgcol} px-0`}>
      <div className="row no-gutters pt-5 px-5">
        <div className="col-md-10 mx-auto text-white text-center">
          <div className="fix-strelica">
            <h1 className="dinpro-light vertical-align-middle mb-0 pb-5">
              {solutionlight}
              <span className="dinpro-bold"> {solutionbold}</span>
            </h1>
            <p className="mb-4">{solutiontext1}</p>
            <p className="mb-4">{solutiontext2}</p>
            <p className="mb-4">{solutiontext3}</p>
          </div>
        </div>
      </div>
      <div className="row no-gutters">
        <div className="col-md-10 mx-auto text-white py-3">
          <div className="row no-gutters">
            <div className="col-12 px-3 px-lg-4">
              <h4 className={`${strikepoz} dinpro-medium`}>
                <span className={strikecol}>{potitle}</span>
              </h4>
            </div>
          </div>
        </div>
      </div>
      <div className="row no-gutters pb-5">
        <div className="col-12 col-lg-8 mx-auto d-flex justify-content-center text-white px-3 px-lg-5">
          <div className="col-3 p-1 p-lg-3">
            <div className="box-part text-center">
              <div className="ipp-title-ikonice">
                <img src={poicon1} className="my-2" alt="" />
              </div>
              <div
                className="mx-auto pt-3"
                style={{ width: '30px', borderBottom: '1px solid #fff' }}
              ></div>
              <div className="small dinpro-medium text-center text-wrap pt-3">{poicon1txt}</div>
            </div>
          </div>
          <div className="col-3 p-1 p-lg-3">
            <div className="box-part text-center">
              <div className="ipp-title-ikonice">
                <img src={poicon2} className="my-2" alt="" />
              </div>
              <div
                className="mx-auto pt-3"
                style={{ width: '30px', borderBottom: '1px solid #fff' }}
              ></div>
              <div className="small dinpro-medium text-center text-wrap pt-3">{poicon2txt}</div>
            </div>
          </div>
          <div className="col-3 p-1 p-lg-3">
            <div className="box-part text-center">
              <div className="ipp-title-ikonice">
                <img src={poicon3} className="my-2" alt="" />
              </div>
              <div
                className="mx-auto pt-3"
                style={{ width: '30px', borderBottom: '1px solid #fff' }}
              ></div>
              <div className="small dinpro-medium text-center text-wrap pt-3">{poicon3txt}</div>
            </div>
          </div>
          <div className="col-3 p-1 p-lg-3">
            <div className="box-part text-center">
              <div className="ipp-title-ikonice">
                <img src={poicon4} className="my-2" alt="" />
              </div>
              <div
                className="mx-auto pt-3"
                style={{ width: '30px', borderBottom: '1px solid #fff' }}
              ></div>
              <div className="small dinpro-medium text-center text-wrap pt-3">{poicon4txt}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProjectsPagesTheSolution;
