import React from 'react';
/**
 * Header Box Component
 */
const HeaderBox = ({ title, subtitle }) => {
  return (
    <div className="col-9 col-sm-12 text-white text-center mx-auto px-2 pt-5 pt-sm-0 pt-md-0 pt-lg-0">
      <h1 className="text-uppercase dinpro-bold pb-2">{title}</h1>
      <div className="mx-auto" style={{ width: '60px', borderBottom: '3px solid #fff' }}></div>
      <h3 className="text-uppercase text-break pt-2 pb-4 dinpro-light">{subtitle}</h3>
    </div>
  );
};

export default HeaderBox;
