import React, { Fragment, useState, useEffect, useContext } from 'react';
import { NavLink, useHistory } from 'react-router-dom';
// Helmet za meta tagove dinamicko mjenjenje
import { Helmet } from 'react-helmet';
// Bootstrap components
import { Container, Row, Col } from 'react-bootstrap';
// Font Awesome Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// SCSS for Services page
import './Services.scss';
// Pages Data JSON
import pagesData from '../../data/pages.js';
// Icons
import softDev from '../../../assets/icons/software-development.svg';
import digMarket from '../../../assets/icons/digital-marketing.svg';
import graphDesign from '../../../assets/icons/graphic-design.svg';
// Home components
import Header from '../../Layout/Header';
import HeaderBox from '../../Layout/HeaderBox';
import HeaderBoxIcons from '../../Layout/HeaderBoxIcons';
import HeaderScrollDown from '../../Layout/HeaderScrollDown';
import HeaderSocialLink from '../../Layout/HeaderSocialLink';
// Page components
import CaseStady from '../../Layout/CaseStudy';
import ContainerAboveFooter from '../../Layout/ContainerAboveFooter';
// Carousel Projects components
import CarouselProjects from '../../Layout/CarouselProjects';
// Consumer za jezike ContextAPI
import LanguageConsumer from '../../context/Language';
/**
 * Services page component
 */
const pagesidx = 1;

const Services = () => {
  let data = useContext(LanguageConsumer);
  const [leng, setLeng] = useState(pagesData);
  let history = useHistory();

  useEffect(() => {
    if (data) {
      setLeng(data.stranice);
      if (data.curentLng) {
        history.push('/bs/usluge');
      } else {
        history.push('/services');
      }
    }
  }, [data, history]);

  return (
    <Fragment>
      <Helmet>
        <title>{leng[pagesidx].rhtitle}</title>
        <meta name="description" content={leng[pagesidx].rhdesc} />
        <meta property="og:description" content={leng[pagesidx].rhdesc} />
        <meta property="og:locale" content={leng[pagesidx].rhlocale} />
      </Helmet>
      <Header
        height={'42.5em'}
        bgColor={leng[pagesidx].bcHeadera}
        bgImg={leng[pagesidx].headerimg}
        headerId={'services'}
      >
        <Container className="header-main px-0 h-100">
          <Row className="no-gutters d-flex align-content-center align-content-sm-end align-content-lg-end h-100">
            <HeaderBox title={leng[pagesidx].title} subtitle={leng[pagesidx].subtitle} />
            <Col
              xs={12}
              lg={9}
              className="fix-iznad-scroll mx-auto d-flex justify-content-center text-white p-2 p-lg-3"
            >
              <HeaderBoxIcons boxBroj={'3'} boxText={leng[pagesidx].boxicont1}>
                <FontAwesomeIcon icon={['fad', 'flux-capacitor']} size={'3x'} />
              </HeaderBoxIcons>
              <HeaderBoxIcons boxBroj={'8'} boxText={leng[pagesidx].boxicont2}>
                <FontAwesomeIcon icon={['fad', 'bullseye-arrow']} size={'3x'} />
              </HeaderBoxIcons>
              <HeaderBoxIcons boxBroj={'6'} boxText={leng[pagesidx].boxicont3}>
                <FontAwesomeIcon icon={['fad', 'server']} size={'3x'} />
              </HeaderBoxIcons>
              <HeaderBoxIcons boxBroj={'1'} boxText={leng[pagesidx].boxicont4}>
                <FontAwesomeIcon icon={['fad', 'cart-plus']} size={'3x'} />
              </HeaderBoxIcons>
            </Col>
            <HeaderScrollDown btnscrolldown={leng[pagesidx].btnscrolldown} />
          </Row>
        </Container>
        <HeaderSocialLink />
      </Header>

      <CaseStady
        casestadytitle={leng[pagesidx].casestadytitle}
        casestadysubtitle={leng[pagesidx].casestadysubtitle}
        casestadytext={leng[pagesidx].casestadytext}
        casestadybtn={leng[pagesidx].casestadybtn}
      />

      <Container className="px-0">
        <Row className="no-gutters justify-content-center">
          <div className="col-lg-4 col-md-4 col-sm-6 col-xs-12 d-flex justify-content-center">
            <div className="card bg-secondary text-white rounded-0 border-0 p-5 px-md-0 py-md-5 px-sm-2 py-sm-5 w-100">
              <img src={softDev} className="card-img-top mx-auto" alt="" />
              <div className="card-body text-center">
                <h3 className="card-title dinpro-light mb-0">{leng[pagesidx].box3_1light}</h3>
                <h3 className="card-title dinpro-bold mb-4">{leng[pagesidx].box3_1bold}</h3>
                <p className="card-text m-0">{leng[pagesidx].box3_1item1}</p>
                <p className="card-text m-0">{leng[pagesidx].box3_1item2}</p>
                <p className="card-text m-0">{leng[pagesidx].box3_1item3}</p>
              </div>
              <div className="card-footer border-0 bg-secondary text-center">
                <NavLink
                  exact
                  to={leng[pagesidx].rhlinksd}
                  className="btn btn-sm btn-outline-light px-5"
                >
                  {leng[pagesidx].btnreadmore}
                </NavLink>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-4 col-sm-6 col-xs-12 d-flex justify-content-center">
            <div className="card bg-doc-pink-dark text-white rounded-0 border-0 p-5 px-md-0 py-md-5 px-sm-2 py-sm-5 w-100">
              <img src={digMarket} className="card-img-top mx-auto" alt="" />
              <div className="card-body text-center">
                <h3 className="card-title dinpro-light mb-0">{leng[pagesidx].box3_2light}</h3>
                <h3 className="card-title dinpro-bold mb-4">{leng[pagesidx].box3_2bold}</h3>
                <p className="card-text m-0">{leng[pagesidx].box3_2item1}</p>
                <p className="card-text m-0">{leng[pagesidx].box3_2item2}</p>
                <p className="card-text m-0">{leng[pagesidx].box3_2item3}</p>
              </div>
              <div className="card-footer border-0 bg-doc-pink-dark text-center">
                <NavLink
                  exact
                  to={leng[pagesidx].rhlinkdm}
                  className="btn btn-sm btn-outline-light px-5"
                >
                  {leng[pagesidx].btnreadmore}
                </NavLink>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-4 col-sm-6 col-xs-12 d-flex justify-content-center">
            <div className="card bg-secondary text-white rounded-0 border-0 p-5 px-md-0 py-md-5 px-sm-2 py-sm-5 w-100">
              <img src={graphDesign} className="card-img-top mx-auto" alt="" />
              <div className="card-body text-center">
                <h3 className="card-title dinpro-light mb-0">{leng[pagesidx].box3_3light}</h3>
                <h3 className="card-title dinpro-bold mb-4">{leng[pagesidx].box3_3bold}</h3>
                <p className="card-text m-0">{leng[pagesidx].box3_3item1}</p>
                <p className="card-text m-0">{leng[pagesidx].box3_3item2}</p>
                <p className="card-text m-0">{leng[pagesidx].box3_3item3}</p>
                <p className="card-text m-0">{leng[pagesidx].box3_3item4}</p>
              </div>
              <div className="card-footer border-0 bg-secondary text-center">
                <NavLink
                  exact
                  to={leng[pagesidx].rhlinkgd}
                  className="btn btn-sm btn-outline-light px-5"
                >
                  {leng[pagesidx].btnreadmore}
                </NavLink>
              </div>
            </div>
          </div>
        </Row>
      </Container>

      <Container className="px-0 mb-md-2">
        <Row className="no-gutters d-flex align-items-center">
          <div className="section-title d-flex align-items-center col-lg-6 col-md-6 col-sm-12 bg-doc-light-oker p-5">
            <div>
              <h3 className="dinpro-light pb-3">{leng[pagesidx].ltnaslov}</h3>
              <p>{leng[pagesidx].lttext}</p>
            </div>
          </div>
          <div className="section-kocka d-flex align-items-center col-lg-6 col-md-5 col-sm-7 bg-doc-pink-dark text-white p-5 mx-auto mx-md-0">
            <NavLink exact to={leng[pagesidx].rhlinkow} className="text-white text-decoration-none">
              <div className="fix-strelica">
                <h1 className="dinpro-light">
                  {leng[pagesidx].dowlight}
                  <br />
                  <span className="dinpro-bold">
                    {leng[pagesidx].dowbold1}
                    <br /> {leng[pagesidx].dowbold2}
                  </span>
                  <span className="pl-3 span-ikonica-fa">
                    <FontAwesomeIcon icon={['fal', 'chevron-right']} />
                  </span>
                </h1>
              </div>
            </NavLink>
          </div>
        </Row>
      </Container>

      <div className="fix-container-carousel">
        <Container className="px-0 mb-sm-4 mb-md-5">
          <Row className="no-gutters">
            <CarouselProjects />
          </Row>
        </Container>
      </div>

      <ContainerAboveFooter
        lwtbgColor={'bg-doc-pink'}
        lwttext1={leng[pagesidx].lwttext1}
        lwttext2={leng[pagesidx].lwttext2}
        lwttext3={leng[pagesidx].lwttext3}
        lwtlinkTo={leng[pagesidx].rhlinkco}
      />
    </Fragment>
  );
};

export default Services;
