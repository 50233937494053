import React, { useState, useEffect, useContext } from 'react';
import { NavLink } from 'react-router-dom';
// Bootstrap components
import { Container, Row, Col } from 'react-bootstrap';
// Font Awesome Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// Projects Data JSON
import projectsData from '../data/projects.js';
// Utility Component
import UseWindowWidth from '../Layout/UseWindowWidth';
// Consumer za jezike ContextAPI
import LanguageConsumer from '../../components/context/Language';
/**
 * Projects Our Work Box Component
 */
const ProjectsOurWorkBox = ({ btnloadmore }) => {
  var data = useContext(LanguageConsumer);
  const [leng, setLeng] = useState(projectsData);

  useEffect(() => {
    if (data) {
      setLeng(data.projekti);
    }
  }, [data]);

  const boxContent = (projects) => {
    return (
      <Col key={projects.id} xs={12} sm={6} md={4} className="px-0 mx-auto">
        <div className="card img-efect text-white rounded-0 border-0 mx-auto fade-in">
          <NavLink exact to={projects.urlTo} className="text-decoration-none stretched-link">
            <img
              src={projects.srcfi}
              className="card-img rounded-0 border-0"
              alt={projects.title}
            />
          </NavLink>
          <div className="card-img-overlay bg-doc-dark d-flex align-items-center">
            <div className="card-title text-center d-flex justify-content-center mx-auto my-0">
              <img
                src={projects.src}
                className={`card-img ${projects.logow}`}
                alt={projects.title}
              />
            </div>
          </div>
        </div>
      </Col>
    );
  };

  const toShow = UseWindowWidth() <= 768 ? 6 : 9;
  const toAdd = UseWindowWidth() <= 768 ? 1 : 3;

  const [index, setIndex] = useState(toShow);

  const addMore = () => {
    setIndex(index + toAdd);
  };

  const returnAdded = () => {
    setIndex(toShow);
  };

  const popuni = leng.slice(0, index).map((item) => {
    return boxContent(item);
  });

  return (
    <>
      <Container className="px-0">
        <Row className="no-gutters">
          <Col xs={12} className="">
            <div className="card-group orc-card-group mx-auto">{popuni}</div>
          </Col>
        </Row>
      </Container>
      <Container className="px-0 my-5">
        <Row className="no-gutters mx-auto">
          <div className="col-12 px-3 px-sm-0">
            <div className="strike dinpro-medium py-1">
              <span className="strike-b">
                {index < leng.length && (
                  <button
                    type="button"
                    onClick={addMore}
                    className="btn btn-sm btn-outline-dark mx-1"
                  >
                    {btnloadmore}
                  </button>
                )}
                <button onClick={returnAdded} className="btn btn-sm btn-outline-dark mx-1">
                  <FontAwesomeIcon icon={['fad', 'sync-alt']} />
                </button>
              </span>
            </div>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default ProjectsOurWorkBox;
