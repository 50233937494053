/**
 * !!! PAGES DATA !!!
 */
// Static header image
import bgImgMainDefault from '../../assets/img/bg-header-all.png';
import bgImgMainGD from '../../assets/img/graphicdesign_img/bg-header-GD.png';
import bgImgMainDM from '../../assets/img/digitalmarketing_img/bg-header-DM.png';
import bgImgMainSD from '../../assets/img/softwaredev_img/bg-header-SD.png';
// IMAGES GRAPHIC DESIGN
import kocka1GD from '../../assets/img/graphicdesign_img/kocka1GD.png';
import kocka2GD from '../../assets/img/graphicdesign_img/kocka2GD.png';
import kocka3GD from '../../assets/img/graphicdesign_img/kocka3GD.png';
// IMAGES DIGITAL MARKETING
import kocka1DM from '../../assets/img/digitalmarketing_img/kocka1DM.png';
import kocka2DM from '../../assets/img/digitalmarketing_img/kocka2DM.png';
import kocka3DM from '../../assets/img/digitalmarketing_img/kocka3DM.png';
import kocka4DM from '../../assets/img/digitalmarketing_img/kocka4DM.png';
// IMAGES SOFTWARE DEVELOPMENT
import kocka1SD from '../../assets/img/softwaredev_img/kocka1SD.png';
import kocka2SD from '../../assets/img/softwaredev_img/kocka2SD.png';
import kocka3SD from '../../assets/img/softwaredev_img/kocka3SD.png';
// ABOUT AS
import poicon1ABOUT from '../../assets/icons/poicon1.svg';
import poicon2ABOUT from '../../assets/icons/poicon2.svg';
import poicon3ABOUT from '../../assets/icons/poicon3.svg';
import poicon4ABOUT from '../../assets/icons/poicon4.svg';
// THE SOLUTION SOFTWARE DEVELOPMENT
import poicon1SD from '../../assets/icons/poicon5.svg';
import poicon2SD from '../../assets/icons/poicon6.svg';
import poicon3SD from '../../assets/icons/poicon7.svg';
import poicon4SD from '../../assets/icons/poicon8.svg';
// THE SOLUTION DIGITAL MARKETING
import poicon1DM from '../../assets/icons/poicon5.svg';
import poicon2DM from '../../assets/icons/poicon9.svg';
import poicon3DM from '../../assets/icons/poicon10.svg';
import poicon4DM from '../../assets/icons/poicon11.svg';
// THE SOLUTION GRAPHIC DESIGN
import poicon1GD from '../../assets/icons/poicon5.svg';
import poicon2GD from '../../assets/icons/poicon12.svg';
import poicon3GD from '../../assets/icons/poicon7.svg';
import poicon4GD from '../../assets/icons/poicon13.svg';

const pages = [
  // ? ABOUT US
  {
    id: 1,
    title: 'ABOUT US',
    subtitle: 'A FEW WORDS ABOUT DOC...',
    urlTo: '/about',
    bcHeadera: '#000f80',
    headerimg: bgImgMainDefault,
    boxicont1: 'YEARS OF EXPERIENCE',
    boxicont2: 'TEAM MEMBERS',
    boxicont3: 'EXTERNAL EXPERTS',
    boxicont4: 'PARTNER COMPANIES',
    // META TAG i TITLE u head za React-Helmet
    rhtitle: 'About Us',
    rhdesc:
      'DOC.BA is a company with young experts in different fields of informational technologies, computer sciences, design and digital marketing.',
    rhlocale: 'en_US',
    rhlinkco: '/contact',
    // CASE STUDY
    casestadytitle: 'WHO WE ARE',
    casestadysubtitle: 'AND WHAT WE DO',
    casestadybtn: 'btn-primary',
    casestadytext:
      'DOC.BA is a company with young experts in different fields of informational technologies, computer sciences, design and digital marketing. Our idea of operation is building trust between clients and our company, throughout timely and quality response to tasks and challenges and establishing long term cooperation. Our most important feature is that we are a one-stop-shop for all your digital needs.',
    // THE SOLUTION:
    solutionlight: 'DOC',
    solutionbold: 'FEATURES',
    solutionbgcol: 'bg-primary',
    solutiontext1:
      "At DOC.BA digital clinic, there is no web page we can't cure and there is no cold marketing campaign we can't heat up. We are capable of diagnosing your hosting weak spots and apply the correct vaccine. No matter what the problem is, DOC.BA digital team will meet all your digital needs, and will make sure that our clients are always healthy and running at peak performance.",
    solutiontext2:
      'The average age of our team is 29. We are up to date with the most modern IT & digital technologies, but that doesn’t mean we lack needed experience. Our team combined, has over 100 years of experience in reanimating old websites, and giving birth to the new ones. Creating digital campaigns with surgical precision is an easy task for our digital marketing team, and our designers can ‘beautify’ even the oldest visuals and branding.',
    solutiontext3:
      'Our digital doctors are at your disposal in all the emergency cases. Whether your servers and web pages are infected with a deadly virus, or your digital and web traffic is cold, we will apply the right therapy for the problem at hand.',
    // PROJECT OVERVIEV
    potitle: 'FEATURES OVERVIEW',
    poicon1: poicon1ABOUT,
    poicon1txt: 'ONE STOP DIGITAL CLINIC',
    poicon2: poicon2ABOUT,
    poicon2txt: 'YOUNG TEAM OF DEV DOCS',
    poicon3: poicon3ABOUT,
    poicon3txt: 'UP TO DATE TECHNOLOGY',
    poicon4: poicon4ABOUT,
    poicon4txt: 'CREATIVE SOLUTIONS',
    // BUTTON
    btnscrolldown: 'SCROLL DOWN TO SEE OUR MEMBERS',
    // DEVIDER
    devidertext: 'OUR TEAM OF DOCTORS',
    // CONTAINER ABOVE FOOTER
    lwttext1: "LET'S",
    lwttext2: 'WORK',
    lwttext3: 'TOGETHER',
  },
  // ? SERVICES
  {
    id: 2,
    title: 'AREAS OF EXPERTISE',
    subtitle: 'WHAT WE CAN DO FOR YOU',
    urlTo: '/services',
    bcHeadera: '#bd2981',
    headerimg: bgImgMainDefault,
    boxicont1: 'AREAS OF EXPERTISE',
    boxicont2: 'DIFFERENT NICHES',
    boxicont3: 'SERVERS IN OUR PRIVATE CLOUD',
    boxicont4: 'STOP SHOP FOR ALL YOUR DIGITAL NEEDS',
    // META TAG i TITLE u head za React-Helmet
    rhtitle: 'Services',
    rhdesc:
      "Our company's services are divided into three main departments, Software development, Digital Marketing & Graphic Design.",
    rhlocale: 'en_US',
    rhlinksd: '/software-development',
    rhlinkdm: '/digital-marketing',
    rhlinkgd: '/graphic-design',
    rhlinkow: '/our-work',
    rhlinkco: '/contact',
    // CASE STUDY
    casestadytitle: 'DOC SERVICES',
    casestadysubtitle: '',
    casestadybtn: 'btn-secondary',
    casestadytext:
      "Technology has changed the way we communicate with each other in each aspect of life. DOC.ba is a company of young IT experts who recognized this change and the multiple benefits it brings and who are able to respond to all the challenges of a modern business environment. Below, you can find more information about our company's services, divided into three main departments, Software development, Digital Marketing & Graphic Design.",
    // 3 BOX-a:
    box3_1light: 'SOFTWARE',
    box3_1bold: 'DEVELOPMENT',
    box3_1item1: 'Web Development',
    box3_1item2: 'GIS Development',
    box3_1item3: 'App Development',
    box3_2light: 'DIGITAL',
    box3_2bold: 'MARKETING',
    box3_2item1: 'Facebook',
    box3_2item2: 'Instagram',
    box3_2item3: 'Google',
    box3_3light: 'GRAPHIC',
    box3_3bold: 'DESIGN',
    box3_3item1: 'Branding',
    box3_3item2: 'UX/Digital Design',
    box3_3item3: 'Social Network Ad Design',
    box3_3item4: 'Web Design',
    // LIJEVI TEXT
    ltnaslov: 'A PICTURE IS WORTH...',
    lttext:
      'A picture is worth a thousand words, is probably something you heard before. In DOC.ba, we believe this to be true and have always highly focused on the visual aspects of our products and services, as well as technical. Every brand, no matter how big or small, should look beautiful as well as being useful and interesting to its users, and at DOC.ba, we do just that. We create a beautiful and impactful online presence for our clients, as well as every other software or graphic design needs they might have. As we mentioned in the first sentence of this text, we believe a picture of our work says a thousand words, so feel invited to take a look.',
    // DESNI DISCOVER OUR WORK
    dowlight: 'DISCOVER',
    dowbold1: 'OUR',
    dowbold2: 'WORK',
    // BUTTON
    btnreadmore: 'READ MORE',
    btnscrolldown: 'SCROLL DOWN TO SEE OUR SERVICES',
    // CONTAINER ABOVE FOOTER
    lwttext1: "LET'S",
    lwttext2: 'WORK',
    lwttext3: 'TOGETHER',
  },
  // ? OUR WORK
  {
    id: 3,
    title: 'WHERE THE ACTION IS',
    subtitle: 'OUR BODY OF WORK',
    urlTo: '/our-work',
    bcHeadera: '#fb4050',
    headerimg: bgImgMainDefault,
    boxicont1: 'ENTERPRISE GRADE PROJECTS',
    boxicont2: 'WEBSITES',
    boxicont3: 'FULL REBRANDS',
    boxicont4: 'DIGITAL MARKETING CAMPAIGNS',
    // META TAG i TITLE u head za React-Helmet
    rhtitle: 'Our Work',
    rhdesc:
      'You need a software or application for your business? We will be happy to show you what we did for other businesses and brands.',
    rhlocale: 'en_US',
    rhlinkco: '/contact',
    rhlinkpoliglot: '/poliglot',
    rhlinkpomoziba: '/pomoziba',
    rhlinkiug: '/iug',
    rhlinkdoc: '/',
    // CASE STUDY
    casestadytitle: 'OUR RECENT WORK',
    casestadysubtitle: '',
    casestadybtn: 'btn-doc-red',
    casestadytext:
      'You need a software or application for your business? You want it to be well-designed as well as being useful? You want to have an impactful and immersive online presence for your users and clients no matter how big or small your brand is? If your answer is “yes”, we will be happy to show you what we did for other businesses and brands that wanted the same.',
    // OUR VALUABLE CLIENTS
    ovclight1: 'OUR',
    ovclight2: 'VALUABLE',
    ovcbold: 'CLIENTS',
    // DEVIDER
    devidertext: 'RECENT PROJECTS',
    // DESNI TEXT
    dtnaslov: 'POLIGLOT',
    dttext:
      '"Škola za strane jezike i informatiku Poliglot" is Sarajevo based school for languages and computing which has long history of providing high quality classes of foreign languages. Through years, the school was always adopting new technologies with goal of providing courses with the latest market standards. Our team was tasked to refresh the school\'s brand visuals, as well as improve online presence of Poliglot through new web page and continuous social media campaigns.',
    // LIJEVI TEXT
    ltnaslov: 'IUG',
    lttext:
      'IUG is an acronym for International University of Goražde, which is located in BPK, Goražde, Bosnia and Herzegovina. The client has recognized the market needs for adequate online presence and visual identity improvements, as well as marketing via social media and other means. Therefore, our task was to deliver the necessary improvements to client’s brand identity, online presence and improve traffic via social networks...',
    // BUTTON
    btnloadmore: 'LOAD MORE',
    btnreadmore: 'Read more...',
    btnscrolldown: 'SCROLL DOWN TO SEE OUR PORTFOLIO',
    // CONTAINER ABOVE FOOTER
    lwttext1: "LET'S",
    lwttext2: 'WORK',
    lwttext3: 'TOGETHER',
  },
  // ? CONTACT US
  {
    id: 4,
    title: 'CONTACT US',
    subtitle: 'WE ARE READY TO HEAR FROM YOU...',
    urlTo: '/contact',
    bcHeadera: '#39abd4',
    headerimg: bgImgMainDefault,
    boxicont1: 'WORKING DAYS',
    boxicont2: 'WORKING HOURS PER DAY',
    boxicont3: 'ONLINE SUPORT',
    boxicont4: 'OFFICES',
    // META TAG i TITLE u head za React-Helmet
    rhtitle: 'Contact',
    rhdesc:
      'We would love for you to contact us about cooperation or any kind of inquiry you might have, and below you can find multiple ways of doing so.',
    rhlocale: 'en_US',
    rhlinkow: '/our-work',
    // CASE STUDY
    casestadytitle: 'DROP US A LINE',
    casestadysubtitle: 'OR GIVE US A CALL',
    casestadybtn: 'btn-doc-light-blue',
    casestadytext:
      'We would love for you to contact us about cooperation or any kind of inquiry you might have, and below you can find multiple ways of doing so.',
    // BUTTON
    btnscrolldown: 'SCROLL DOWN TO SEE OUR CONTACT DETAILS',
    // DEVIDER
    devidertext: 'RECENT PROJECTS',
    // ADRESA i RADNO VRIJEME
    box4adresa1: 'Kaptol no.5, Sarajevo,',
    box4adresa2: 'Bosnia & Herzegovina',
    box4radnov1: 'Mon-Fri: 8am-4pm',
    box4radnov2: 'Saturday & Sunday: Closed.',
    // TINGEA REGISTRACIJA SUD
    tingeareg0: 'VAT',
    tingeareg1: 'Company registered',
    tingeareg2: 'at Sarajevo municipal court.',
    tingeareg3: 'Registered number:',
    tingeareg4: 'Company’s registration number:',
    // CONTACT FORM
    cffirstname: 'First name',
    cflasttname: 'Last name',
    cfemail: 'Email Address',
    cfphone: 'Phone No',
    cfsubject: 'Subject',
    cfmessage: 'Message',
    cfsubmit: 'Submit',
    cfsuccess: 'Success',
    cfsucmess: 'Message sent!',
    cferror: 'Error',
    cferrormes: 'There is some error, can you please try again later?',
    cffirstnamefcfi: 'Please enter a first name.',
    cflasttnamefcfi: 'Please enter a last name.',
    cfemailfcfi: 'Please enter a valid email address.',
    cfphonefcfi: 'Please enter phone no.',
    cfsubjectfcfi: 'Please enter subject.',
    cfmessagefcfi: 'Please enter your message.',
    // CONTAINER ABOVE FOOTER
    lwttext1: 'DISCOVER',
    lwttext2: 'OUR',
    lwttext3: 'WORK',
  },
  // ? GRAPHIC DESIGN
  {
    id: 5,
    title: 'GRAPHIC DESIGN',
    subtitle: 'DEPARTMENT',
    urlTo: '/graphic-design',
    bcHeadera: '#bd2981',
    headerimg: bgImgMainGD,
    boxicont1: 'SUB DEPARTMENT',
    boxicont2: 'DIFFERENT NICHES',
    boxicont3: 'COMBINED SKILLS',
    boxicont4: 'SATISFIED CLIENTS',
    // META TAG i TITLE u head za React-Helmet
    rhtitle: 'Graphic Design',
    rhdesc:
      'We create and work with brands that build businesses, by helping them increase their brand awareness, developing their digital presence, growing their sales.',
    rhlocale: 'en_US',
    rhlinkco: '/contact',
    // CASE STUDY
    casestadytitle: 'FRESHEN IT UP',
    casestadybtn: 'btn-doc-pink',
    casestadytext:
      'DOC has a rich background and extensive knowledge developing purposeful and brand-centered creative solutions. During the years of experience and a large number of clients, we found ourselves as the generator of a new energy and life brought into the brands we worked for, giving it a new, fresh look and feel. Hence, we like to say, we FRESHEN IT UP for you.',
    // BUTTON
    btnscrolldown: 'SCROLL DOWN TO SEE MORE',
    // THE SOLUTION:
    solutiontitle: 'WHAT WE CAN DO',
    solutionbgcol: 'bg-doc-pink',
    solutiontext1:
      "At DOC, we create and work with brands that build businesses, by helping them increase their brand awareness, developing their digital presence, in turn, growing their sales. We don't believe in selling you our services for the sake of selling — we create solutions based on what your needs are, and goals you want to accomplish, respecting your vision and business plan. We work closely with our clients to create a customized solution that is built just for them based on their business objectives, target market and budget.",
    solutiontext2:
      'Regardless wheather it is a complete branding, web design, UX Design or Social Media ms, we are here waiting to hear your story, and do our best to help your business grow and reach its goals.',
    // PROJECT OVERVIEV
    potitle: 'DOC DESIGN PROCESS',
    poicon1: poicon1GD,
    poicon1txt: 'UNDERSTANDING CLIENT NEEDS',
    poicon2: poicon2GD,
    poicon2txt: 'DESIGN PROPOSALS',
    poicon3: poicon3GD,
    poicon3txt: 'FINAL TOUCHES',
    poicon4: poicon4GD,
    poicon4txt: 'EARLY FEEDBACK',
    // KOCKA
    kocka: [
      // KOCKA 1-0
      {
        img: kocka1GD,
        title: 'VISUAL IDENTITY/BRANDING',
        bgcol: 'bg-doc-pink-dark',
        text1:
          'Working closely with our clients, by identifying client vision, the story behind the business, goals and objectives, in order to get the best idea about the Visual identity suitable for client. We provide solutions for complete branding, one-off jobs like logo design, print advertisements like flyers, brochures, billboards, up to full scale ongoing cooperation with clients providing them continues help in promoting and developing their brand and business.',
      },
      {
        // KOCKA 2-1
        img: kocka2GD,
        title: 'WEBSITE DESIGN',
        bgcol: 'bg-doc-pink',
        text1:
          'When it comes to designing and developing eye catching websites, first step of the process is to identify the purpose of the web page. Depending on the purpose, whether it is to sell, inform visitors or just raise brand awareness, our designer creates mock ups which serve as a preview of the website. After selecting the final look with our clients, our team starts the development phase of the project, and in the end, the client gets 100% responsive web page which is optimized for latest search engines, as well as all devices.',
      },
      {
        // KOCKA 3-2
        img: kocka3GD,
        title: 'SOCIAL MEDIA ADS',
        bgcol: 'bg-doc-pink-dark',
        text1:
          'Promoting your brand through online channels can bring you multiple return on investment, as well as raise your brand awareness. By having precise ads, specially designed for your defined target audience, you can substantially increase conversions and leads generated through your online channels. Our design team will analyze your brand visual identity, and your target audience, and will create perfect and beautiful looking visuals which will represent your brand in its best, and will be perfectly tailored for your audience.',
      },
    ],
    // CONTAINER ABOVE FOOTER
    lwttext1: "LET'S",
    lwttext2: 'WORK',
    lwttext3: 'TOGETHER',
  },
  // ? DIGITAL MARKETING DEPARTMENT
  {
    id: 6,
    title: 'DIGITAL MARKETING',
    subtitle: 'DEPARTMENT',
    urlTo: '/digital-marketing',
    bcHeadera: '#bd2981',
    headerimg: bgImgMainDM,
    boxicont1: 'YEARS OF EXPERIENCES',
    boxicont2: 'SPECIALITIES',
    boxicont3: 'HAPPY CLIENTS',
    boxicont4: 'PROJECTS DONE',
    // META TAG i TITLE u head za React-Helmet
    rhtitle: 'Digital Marketing',
    rhdesc:
      'We can provide increased traffic to your web page, apps or other digital channels, raise brand awareness, provide community management and much more.',
    rhlocale: 'en_US',
    rhlinkco: '/contact',
    // CASE STUDY
    casestadytitle: 'GET VISIBLE!',
    casestadybtn: 'btn-doc-pink',
    casestadytext:
      'No matter how big or small your brand is, being online is a must. What is the purpose of your beautiful latest web page for example, if it has no visitors, and is left in the dust of the internet depths? Through various sets of activities, DOC can provide increased traffic to your web page, apps or other digital channels, as well as generate leads, raise brand awareness, provide community management and much, much more.',
    // BUTTON
    btnscrolldown: 'SCROLL DOWN TO SEE MORE',
    // THE SOLUTION:
    solutiontitle: 'WHAT WE CAN DO',
    solutionbgcol: 'bg-doc-pink',
    solutiontext1:
      'Following technology development, a big part of raising brand awareness is now happening online. No matter what type of services or products your business provides, chances are, your clients are online and maybe even searching for you. Not only that we are able to efficiently communicate your brand to your target audience in a way that was never possible before, but we are also capable to use all the data we gather through our online activities in the optimization of your brand advertising and community management.',
    // PROJECT OVERVIEV
    potitle: 'DOC MARKETING PROCESS',
    poicon1: poicon1DM,
    poicon1txt: 'CLIENT & AUDIENCE RESEARCH',
    poicon2: poicon2DM,
    poicon2txt: 'CREATING PROMO STRATEGY',
    poicon3: poicon3DM,
    poicon3txt: 'LAUNCH DAILY OPTIMIZED CAMPAIGN',
    poicon4: poicon4DM,
    poicon4txt: 'REPORT + RECOMMENDATIONS',
    // KOCKA
    kocka: [
      // KOCKA 1-0
      {
        img: kocka1DM,
        title: 'FACEBOOK MANAGEMENT',
        bgcol: 'bg-doc-pink-dark',
        text1:
          "Facebook is the biggest social network in the world, and it has fundamentally changed the way we communicate with each other. Facebook is a powerful tool that can generate instant feedback from followers of your brand, as well as generate new leads and clients. Whether you need a quality community management, or efficient Facebook ADS campaign, we've got you covered!",
      },
      {
        // KOCKA 2-1
        img: kocka2DM,
        title: 'INSTAGRAM MANAGEMENT',
        bgcol: 'bg-doc-pink',
        text1:
          "Instagram is fully integrated into the Facebook Ads Manager, which allows us to run campaigns with surgical precision on this platform as well. If we add the fact that it is the second-largest social media network in the world, you get the picture (Instagram is all about pictures :D) why it's necessary for every brand to be visible on this digital channel as well.",
      },
      {
        // KOCKA 3-2
        img: kocka3DM,
        title: 'CHAT BOT AUTOMATIZATION',
        bgcol: 'bg-doc-pink-dark',
        text1:
          'Imagine having a fully automated chatbot on your Facebook or Web page, which gives the required information to your clients and prospects 24/7. With us, it is possible! Things only get more interesting, because you can remember every single prospect and segment them into different groups, depending on the information you are looking for. Now you can invest more time in generating quality communication, rather than repeating the same information to every new prospect.',
      },
      {
        // KOCKA 4-3
        img: kocka4DM,
        title: 'GOOGLE MANAGEMENT',
        bgcol: 'bg-doc-pink',
        text1:
          "Google Search: Show your product or service to the people looking for it at the time they are looking for it... isn't that just smart marketing at its best? Google search is intent-based marketing, which means we are showing your ads in search results of the people looking for a specific thing.",
        text2:
          'Google Display: Use our partner display network to show your banners on the biggest web pages on the internet. You can also use our display network in your remarketing activities as well, and you can rest assured your clients will never forget you.',
        text3:
          "YouTube: You all know about it, you've all heard about it, and we know you all watched something on it. It is the biggest video sharing platform in the world, and that alone makes it perfect to advertise any kind of content as well as create a video community about your brand.",
      },
    ],
    // CONTAINER ABOVE FOOTER
    lwttext1: "LET'S",
    lwttext2: 'WORK',
    lwttext3: 'TOGETHER',
  },
  // ? SOFTWARE DEVELOPMENT
  {
    id: 7,
    title: 'SOFTWARE DEVELOPMENT',
    subtitle: 'DEPARTMENT',
    urlTo: '/software-development',
    bcHeadera: '#bd2981',
    headerimg: bgImgMainSD,
    boxicont1: 'SUBDEPARTMENT',
    boxicont2: 'SKILLS / PROGRAMING LANGUAGES',
    boxicont3: 'LINES OF CODE',
    boxicont4: 'SATISFIED CLIENTS',
    // META TAG i TITLE u head za React-Helmet
    rhtitle: 'Software Development',
    rhdesc:
      'We have experience with many software, from the simplest to the most complex, and approach each with the same level of professionalism.',
    rhlocale: 'en_US',
    rhlinkco: '/contact',
    // CASE STUDY
    casestadytitle: '>HELLO WORLD!_',
    casestadybtn: 'btn-doc-pink',
    casestadytext:
      'We are a team of developers who are ready to meet your specific requirements. We have experience with many software, from the simplest to the most complex, and approach each with the same level of professionalism. Our team is happy only when the client is happy and satisfied with the product.',
    // BUTTON
    btnscrolldown: 'SCROLL DOWN TO SEE MORE',
    // THE SOLUTION:
    solutiontitle: 'WHAT WE CAN DO',
    solutionbgcol: 'bg-doc-pink',
    solutiontext1:
      'At DOC, we believe that any software should make life easier, whether used for private or business purposes. For this reason, it is essential that any product is being developed in close cooperation with the client in order to fulfil all the wishes and requests he/she has. After many successfully completed projects, we are proud that our software is stable, reliable and easy to use as we pay special attention to user experience. It is very important to us that everything works because we sympathize with all the broken keyboards when "something just didn\'t work".',
    // PROJECT OVERVIEV
    potitle: 'DOC DEV PROCESS',
    poicon1: poicon1SD,
    poicon1txt: 'UNDERSTANDING CLIENT NEEDS',
    poicon2: poicon2SD,
    poicon2txt: 'DEVELOPING & DESIGNING',
    poicon3: poicon3SD,
    poicon3txt: 'FINAL TOUCHES',
    poicon4: poicon4SD,
    poicon4txt: 'HAPPY CLIENTS',
    // KOCKA
    kocka: [
      // KOCKA 1-0
      {
        img: kocka1SD,
        title: 'WEB DEVELOPMENT',
        bgcol: 'bg-doc-pink-dark',
        text1:
          "The best way to start a new business is often through a web presence. This includes everything from simple informative websites to serious web applications with many complex functionalities, all for the purpose of achieving the client's goal. Web development includes getting a very clear idea of what the requirements are, figuring out the best way to create, present and attract users through the flow of information, and working with designers, marketers and project managers to develop the best possible product.",
      },
      {
        // KOCKA 2-1
        img: kocka2SD,
        title: 'MOBILE DEVELOPMENT',
        bgcol: 'bg-doc-pink',
        text1:
          'Mobile usage has grown rapidly in the past few years and has no plans to stop growing. This tells us that there is great potential in mobile applications for expanding or opening a new business. Mobile applications greatly facilitate the promotion of your brand, increase the number of users and facilitate interaction with users.',
      },
      {
        // KOCKA 3-2
        img: kocka3SD,
        title: 'GEOGRAPHIC INFORMATION SYSTEMS',
        bgcol: 'bg-doc-pink-dark',
        text1:
          'A geographic information system (GIS) is a computer system for managing data related to positions on Earth’s surface. GIS can show many different kinds of data on one map, such as streets, buildings, and vegetation. This enables people to easily see, analyze, and understand patterns and relationships. We are very proud that our team holds two GIS experts.',
      },
    ],
    // CONTAINER ABOVE FOOTER
    lwttext1: "LET'S",
    lwttext2: 'WORK',
    lwttext3: 'TOGETHER',
  },
];

export default pages;
