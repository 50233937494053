/**
 * !!! PAGES DATA !!!
 */
// Static header image
import bgImgMainDefault from '../../assets/img/bg-header-all.png';
import bgImgMainGD from '../../assets/img/graphicdesign_img/bg-header-GD.png';
import bgImgMainDM from '../../assets/img/digitalmarketing_img/bg-header-DM.png';
import bgImgMainSD from '../../assets/img/softwaredev_img/bg-header-SD.png';
// IMAGES GRAPHIC DESIGN
import kocka1GD from '../../assets/img/graphicdesign_img/kocka1GD.png';
import kocka2GD from '../../assets/img/graphicdesign_img/kocka2GD.png';
import kocka3GD from '../../assets/img/graphicdesign_img/kocka3GD.png';
// IMAGES DIGITAL MARKETING
import kocka1DM from '../../assets/img/digitalmarketing_img/kocka1DM.png';
import kocka2DM from '../../assets/img/digitalmarketing_img/kocka2DM.png';
import kocka3DM from '../../assets/img/digitalmarketing_img/kocka3DM.png';
import kocka4DM from '../../assets/img/digitalmarketing_img/kocka4DM.png';
// IMAGES SOFTWARE DEVELOPMENT
import kocka1SD from '../../assets/img/softwaredev_img/kocka1SD.png';
import kocka2SD from '../../assets/img/softwaredev_img/kocka2SD.png';
import kocka3SD from '../../assets/img/softwaredev_img/kocka3SD.png';
// ABOUT AS
import poicon1ABOUT from '../../assets/icons/poicon1.svg';
import poicon2ABOUT from '../../assets/icons/poicon2.svg';
import poicon3ABOUT from '../../assets/icons/poicon3.svg';
import poicon4ABOUT from '../../assets/icons/poicon4.svg';
// THE SOLUTION SOFTWARE DEVELOPMENT
import poicon1SD from '../../assets/icons/poicon5.svg';
import poicon2SD from '../../assets/icons/poicon6.svg';
import poicon3SD from '../../assets/icons/poicon7.svg';
import poicon4SD from '../../assets/icons/poicon8.svg';
// THE SOLUTION DIGITAL MARKETING
import poicon1DM from '../../assets/icons/poicon5.svg';
import poicon2DM from '../../assets/icons/poicon9.svg';
import poicon3DM from '../../assets/icons/poicon10.svg';
import poicon4DM from '../../assets/icons/poicon11.svg';
// THE SOLUTION GRAPHIC DESIGN
import poicon1GD from '../../assets/icons/poicon5.svg';
import poicon2GD from '../../assets/icons/poicon12.svg';
import poicon3GD from '../../assets/icons/poicon7.svg';
import poicon4GD from '../../assets/icons/poicon13.svg';

const pages = [
  // ? ABOUT US
  {
    id: 1,
    title: 'O NAMA',
    subtitle: 'NEKOLIKO RIJEČI O DOC...',
    urlTo: '/bs/o-nama',
    bcHeadera: '#000f80',
    headerimg: bgImgMainDefault,
    boxicont1: 'GODINE ISKUSTVA',
    boxicont2: 'ČLANOVI TIMA',
    boxicont3: 'VANJSKIH STRUČNJAKA',
    boxicont4: 'FIRME PARTNERI',
    // META TAG i TITLE u head za React-Helmet
    rhtitle: 'O Nama',
    rhdesc:
      'DOC.BA je tvrtka sa mladim stručnjacima iz različitih oblasti informacionih tehnologija, računarskih nauka, dizajna i digitalnog marketinga.',
    rhlocale: 'bs_BA',
    rhlinkco: '/bs/kontakt',
    // CASE STUDY
    casestadytitle: 'KO SMO MI',
    casestadysubtitle: 'I ŠTA RADIMO',
    casestadybtn: 'btn-primary',
    casestadytext:
      'DOC.BA je kompanija s mladim stručnjacima iz različitih oblasti informacionih tehnologija, računarskih nauka, dizajna i digitalnog marketinga. Ideja rada je izgradnja povjerenja između klijenata i našeg tima, kroz pravovremeni i kvalitetan odgovor na zadatke i izazove i uspostavljanje dugoročne suradnje. Naša najvažnija karakteristika je da imamo sve na jednom mjestu za sve vaše digitalne potrebe.',
    // THE SOLUTION:
    solutionlight: 'DOC',
    solutionbold: 'MOGUĆNOSTI',
    solutionbgcol: 'bg-primary',
    solutiontext1:
      'Na digitalnoj klinici DOC.BA, nema web stranice koju ne možemo izliječiti i hladne marketinške kampanje koju ne možemo ugrijati. U stanju smo dijagnosticirati vaše slabe tačke i primijeniti ispravan tretman. Bez obzira u čemu je problem, digitalni tim DOC.BA može zadovoljiti sve digitalne potrebe klijenata i pobrinut će se da oni budu uvijek zdravi i da rade vrhunskim performansama.',
    solutiontext2:
      'Prosječna starost našeg tima je 29. Mi smo u toku s najmodernijim IT i digitalnim tehnologijama, ali to ne znači da nam nedostaje potrebno iskustvo. Naš tim zajedno ima preko 100 godina iskustva u reanimaciji starih web stranica i rađanju novih. Stvaranje digitalnih kampanja s hirurškom preciznošću jednostavan je zadatak za naš digitalni marketinški tim, a naši dizajneri mogu „uljepšati“ čak i najstarije vizualne prikaze i brendiranja.',
    solutiontext3:
      'Naši digitalni ljekari vam stoje na raspolaganju u svim hitnim slučajevima. Bilo da su vaši serveri i web stranice zaraženi smrtonosnim virusom ili je vaš digitalni i web promet hladan, primijenit ćemo pravu terapiju za ovaj problem.',
    // PROJECT OVERVIEV
    potitle: 'PREGLED MOGUĆNOSTI',
    poicon1: poicon1ABOUT,
    poicon1txt: 'SVE NA JEDNOM MJESTU',
    poicon2: poicon2ABOUT,
    poicon2txt: 'MLADI TIM',
    poicon3: poicon3ABOUT,
    poicon3txt: 'POSLIJEDNJE TEHNOLOGIJE',
    poicon4: poicon4ABOUT,
    poicon4txt: 'KREATIVNA REŠENJA',
    // BUTTON
    btnscrolldown: 'POMAKNITE DOLE DA VIDITE EKIPU',
    // DEVIDER
    devidertext: 'NAŠ TIM DOKTORA',
    // CONTAINER ABOVE FOOTER
    lwttext1: 'POKRENIMO',
    lwttext2: '',
    lwttext3: 'SARADNJU',
  },
  // ? SERVICES
  {
    id: 2,
    title: 'PODRUČJA EKSPERTIZE',
    subtitle: 'ŠTA MOŽEMO UČINITI ZA VAS',
    urlTo: '/bs/usluge',
    bcHeadera: '#bd2981',
    headerimg: bgImgMainDefault,
    boxicont1: 'PODRUČJA EKSPERTIZE',
    boxicont2: 'RAZLIČITE NIŠE',
    boxicont3: 'SERVERI U NAŠEM PRIVATNOM CLOUD-u',
    boxicont4: 'JEDNO MJESTO ZA SVE VAŠE DIGITALNE POTREBE',
    // META TAG i TITLE u head za React-Helmet
    rhtitle: 'Usluge',
    rhdesc:
      'Usluge naše firme su podijeljene u tri glavna odjeljenja: Razvoj Softvera, Digitalni Marketing i Grafički Dizajn.',
    rhlocale: 'bs_BA',
    rhlinksd: '/bs/razvoj-softvera',
    rhlinkdm: '/bs/digitalni-marketing',
    rhlinkgd: '/bs/grafički-dizajn',
    rhlinkow: '/bs/portfolio',
    rhlinkco: '/bs/kontakt',
    // CASE STUDY
    casestadytitle: 'DOC USLUGE',
    casestadysubtitle: '',
    casestadybtn: 'btn-secondary',
    casestadytext:
      'Tehnologija je promijenila način na koji komuniciramo jedni s drugima u svakom aspektu života. DOC.ba je kompanija mladih IT stručnjaka koji su prepoznali ovu promjenu i višestruke prednosti koje ona donosi, a koji su u stanju odgovoriti na sve izazove modernog poslovnog okruženja. Ispod možete pronaći više informacija o uslugama našeg tima, podijeljene u tri glavna odjela, Razvoj softvera, Digitalni marketing i Grafički dizajn.',
    // 3 BOX-a:
    box3_1light: 'RAZVOJ',
    box3_1bold: 'SOFTVERA',
    box3_1item1: 'Razvoj Web-a',
    box3_1item2: 'Razvoj GIS-a',
    box3_1item3: 'Razvoj Aplikacija',
    box3_2light: 'DIGITALNI',
    box3_2bold: 'MARKETING',
    box3_2item1: 'Facebook',
    box3_2item2: 'Instagram',
    box3_2item3: 'Google',
    box3_3light: 'GRAFIČKI',
    box3_3bold: 'DIZAJN',
    box3_3item1: 'Brendiranje',
    box3_3item2: 'UX/Digitalni Dizajn',
    box3_3item3: 'Dizajn oglasa za društvene mreže',
    box3_3item4: 'Web dizajn',
    // LIJEVI TEXT
    ltnaslov: 'SLIKA VRIJEDI VIŠE...',
    lttext:
      'Slika vrijedi više od hiljadu riječi, vjerovatno je nešto što ste čuli i prije. U DOC.ba vjerujemo da je to istina i uvijek smo se visoko fokusirali na vizualne aspekte naših proizvoda i usluga, kao i na tehničke aspekte. Svaki brend, bez obzira koliko veliki ili mali, trebao bi izgledati lijepo, kao i da bude koristan i zanimljiv svojim korisnicima, a na DOC.ba to i radimo. Mi stvaramo lijepo i dojmljivo online prisustvo za naše klijente, kao i sve druge potrebe za softverom ili grafičkim dizajnom koje mogu imati. Kao što smo spomenuli u prvoj rečenici ovog teksta, vjerujemo da slika našeg rada govori hiljadu riječi, osjećajte se pozvanim da pogledate naše radove.',
    // DESNI DISCOVER OUR WORK
    dowlight: 'POGLEDAJ',
    dowbold1: 'DOC',
    dowbold2: 'PORTFOLIO',
    // BUTTON
    btnreadmore: 'ČITAJ VIŠE',
    btnscrolldown: 'POMAKNITE DOLE DA VIDITE NAŠE USLUGE',
    // CONTAINER ABOVE FOOTER
    lwttext1: 'POKRENIMO',
    lwttext2: '',
    lwttext3: 'SARADNJU',
  },
  // ? OUR WORK
  {
    id: 3,
    title: 'GDJE JE AKCIJA',
    subtitle: 'NAŠI RADOVI',
    urlTo: '/bs/portfolio',
    bcHeadera: '#fb4050',
    headerimg: bgImgMainDefault,
    boxicont1: 'PROJEKTI VELIKOG ZNAČAJA',
    boxicont2: 'WEB STRANICE',
    boxicont3: 'PUNI REBRENDING',
    boxicont4: 'KAMPANJA DIGITALNOG MARKETINGA',
    // META TAG i TITLE u head za React-Helmet
    rhtitle: 'Portfolio',
    rhdesc:
      'Za svoj posao trebate softver ili aplikaciju? Rado ćemo vam pokazati šta smo učinili za druge kompanije i brendove koji su željeli isto.',
    rhlocale: 'bs_BA',
    rhlinkco: '/bs/kontakt',
    rhlinkpoliglot: '/bs/poliglot',
    rhlinkpomoziba: '/bs/pomoziba',
    rhlinkiug: '/bs/iug',
    rhlinkdoc: '/bs',
    // CASE STUDY
    casestadytitle: 'NAŠI NEDAVNI RADOVI',
    casestadysubtitle: '',
    casestadybtn: 'btn-doc-red',
    casestadytext:
      'Za svoj posao trebate softver ili aplikaciju? Želite da bude dobro osmišljen i da bude koristan? Želite da imate korisnu i impresivnu internetsku prisutnost za svoje korisnike i klijente, bez obzira koliko veliki ili mali vaš brend? Ako je vaš odgovor „da“, rado ćemo vam pokazati šta smo učinili za druge kompanije i brendove koji su željeli isto.',
    // OUR VALUABLE CLIENTS
    ovclight1: 'NAŠI',
    ovclight2: 'DRAGOCJENI',
    ovcbold: 'KLIJENTI',
    // DEVIDER
    devidertext: 'NEDAVNI PROJEKTI',
    // DESNI TEXT
    dtnaslov: 'POLIGLOT',
    dttext:
      '"Škola za strane jezike i informatiku Poliglot" je škola za jezike i računarstvo sa sjedištem u Sarajevu koja ima dugu historiju pružanja visokokvalitetnih časova stranih jezika. Tokom godina, škola je uvijek usvajala nove tehnologije s ciljem da kursevima pruži najnovije tržišne standarde. Naš tim je dobio zadatak da osvježi vizuelne detalje škole, kao i da poboljša Poliglot na mreži putem nove web stranice i kontinuiranih kampanja na društvenim mrežama.',
    // LIJEVI TEXT
    ltnaslov: 'IUG',
    lttext:
      'IUG je kratica za Međunarodni univerzitet u Goraždu koji se nalazi u BPK, Goražde, Bosna i Hercegovina. Klijent je prepoznao potrebe tržišta za adekvatnim internetskim prisustvom i poboljšanjem vizuelnog identiteta, kao i marketingom putem društvenih medija i drugih sredstava. Stoga je naš zadatak bio da pružimo potrebna poboljšanja identiteta robne marke klijenta, prisutnosti na mreži i poboljšanja prometa putem društvenih mreža...',
    // BUTTON
    btnloadmore: 'UČITAJ JOŠ',
    btnreadmore: 'Čitaj više...',
    btnscrolldown: 'POMAKNITE DOLE DA VIDITE NAŠ PORTFOLIO',
    // CONTAINER ABOVE FOOTER
    lwttext1: 'POKRENIMO',
    lwttext2: '',
    lwttext3: 'SARADNJU',
  },
  // ? CONTACT US
  {
    id: 4,
    title: 'KONTAKTIRAJTE NAS',
    subtitle: 'SPREMNI SMO DA VAS SASLUŠAMO...',
    urlTo: '/bs/kontakt',
    bcHeadera: '#39abd4',
    headerimg: bgImgMainDefault,
    boxicont1: 'RADNI DANI',
    boxicont2: 'RADNIH SATI PO DANU',
    boxicont3: 'ONLINE PODRŠKA',
    boxicont4: 'UREDI',
    // META TAG i TITLE u head za React-Helmet
    rhtitle: 'Kontakt',
    rhdesc:
      'Kontaktirajte nas u vezi suradnje ili bilo kakvih upita koje biste mogli imati, a u nastavku možete pronaći više načina da to učinite.',
    rhlocale: 'bs_BA',
    rhlinkow: '/bs/portfolio',
    // CASE STUDY
    casestadytitle: 'PIŠITE NAM',
    casestadysubtitle: 'ILI NAS POZOVITE',
    casestadybtn: 'btn-doc-light-blue',
    casestadytext:
      'Voljeli bismo da nas kontaktirate u vezi suradnje ili bilo kakvih upita koje biste mogli imati, a u nastavku možete pronaći više načina da to učinite.',
    // BUTTON
    btnscrolldown: 'POMAKNITE DOLE DA VIDITE PODATKE ZA KONTAKT',
    // DEVIDER
    devidertext: 'NEDAVNI PROJEKTI',
    // ADRESA i RADNO VRIJEME
    box4adresa1: 'Kaptol br.5, Sarajevo,',
    box4adresa2: 'Bosna i Hercegovina',
    box4radnov1: 'Pon-Pet: 8-16',
    box4radnov2: 'Subota i Nedjelja: Zatvoreno',
    // TINGEA REGISTRACIJA SUD
    tingeareg0: 'PDV',
    tingeareg1: 'Kompanija je registrovana',
    tingeareg2: 'u općinskom sudu Sarajevo.',
    tingeareg3: 'Registrirani broj:',
    tingeareg4: 'Registracijski broj kompanije:',
    // CONTACT FORM
    cffirstname: 'Ime',
    cflasttname: 'Prezime',
    cfemail: 'Email Adresa',
    cfphone: 'Telefon',
    cfsubject: 'Predmet',
    cfmessage: 'Poruka',
    cfsubmit: 'Pošalji',
    cfsuccess: 'Uspjeh',
    cfsucmess: 'Poruka poslana!',
    cferror: 'Greška',
    cferrormes: 'Postoji neka greška, molimo vas da pokušate ponovo kasnije?',
    cffirstnamefcfi: 'Molimo unesite ime.',
    cflasttnamefcfi: 'Molimo unesite prezime.',
    cfemailfcfi: 'Molimo Vas da unesete validnu email adresu.',
    cfphonefcfi: 'Molimo unesite broj telefona.',
    cfsubjectfcfi: 'Molimo unesite predmet poruke.',
    cfmessagefcfi: 'Molimo unesite svoju poruku.',
    // CONTAINER ABOVE FOOTER
    lwttext1: 'POGLEDAJ',
    lwttext2: '',
    lwttext3: 'PORTFOLIO',
  },
  // ? GRAPHIC DESIGN
  {
    id: 5,
    title: 'GRAFIČKI DIZAJN',
    subtitle: 'SEKTOR',
    urlTo: '/bs/grafički-dizajn',
    bcHeadera: '#bd2981',
    headerimg: bgImgMainGD,
    boxicont1: 'POD-SEKTOR',
    boxicont2: 'RAZLIČITE NIŠE',
    boxicont3: 'KOMBINOVANE VJEŠTINE',
    boxicont4: 'ZADOVOLJNI KLIJENTI',
    // META TAG i TITLE u head za React-Helmet
    rhtitle: 'Grafički Dizajn',
    rhdesc:
      'Stvaramo i sarađujemo sa kompanijama koje grade biznise, pomažući im da unaprijede svoj brend, razvijanje digitalne prisutnosti i povećnje prodaje.',
    rhlocale: 'bs_BA',
    rhlinkco: '/bs/kontakt',
    // CASE STUDY
    casestadytitle: 'OSVJEŽITE BREND',
    casestadybtn: 'btn-doc-pink',
    casestadytext:
      'DOC ima bogatu pozadinu i široko znanje u razvijanju smislenih rješenja usmjerenih prema brendingu. Kroz godine iskustva, i kroz veliki broj klijenata, uvijek nastojimo uliti novu energiju i "život" u brendove za koje radimo, dajući im moderan, svjež izgled i osjećaj. Zato volimo reći da ćemo "osvježiti" Vaš brend. ',
    // BUTTON
    btnscrolldown: 'POMAKNITE DOLE DA VIDITE VIŠE',
    // THE SOLUTION:
    solutiontitle: 'ŠTA MOŽEMO URADITI',
    solutionbgcol: 'bg-doc-pink',
    solutiontext1:
      'U DOC-u radimo s brendovima koji razvijaju svoje poslovanje, pomažući im da povećaju svijest o brendu, razvijajući njihovo digitalno prisustvo, te u konačnici, podižući broj prodaje usluga ili proizvoda. Mi ne vjerujemo u prodaju usluga ili proizvoda samo da biste ih prodavali, mi kreiramo rješenja na osnovu Vaših potreba i ciljeva koje želite postići, poštujući Vašu viziju i poslovni plan. Radimo blisko s našim klijentima u kreiranju posebno prilagođenih rješenja, samo za njihove potrebe i želje, kao i za njihovo ciljano tržište i budžet.',
    solutiontext2:
      'Bez obzira radi li se o kompletnom brendiranju, web dizajnu, UI/UX dizajnu, ili možda društvenim mrežama, tu smo, i čekamo da čujemo Vašu priču, koju ćemo Vam pomoći da ispričate na najbolji način.',
    // PROJECT OVERVIEV
    potitle: 'DOC DIZAJN PROCES',
    poicon1: poicon1GD,
    poicon1txt: 'RAZUMIJEVANJE POTREBA KLIJENTA',
    poicon2: poicon2GD,
    poicon2txt: 'PRIJEDLOZI DIZAJNA',
    poicon3: poicon3GD,
    poicon3txt: 'FINALNE DORADE',
    poicon4: poicon4GD,
    poicon4txt: 'RANI FEEDBACK',
    // KOCKA
    kocka: [
      // KOCKA 1-0
      {
        img: kocka1GD,
        title: 'VIZUALNI IDENTITET/BRENDING',
        bgcol: 'bg-doc-pink-dark',
        text1:
          'Blisko sarađujemo s našim klijentima, identifikujući njihovu viziju, priču koja je u pozadini tog biznisa i zadane ciljeve saradnje, kako bismo što bolje dobili uvid u želje klijenta, te definisali najadekvatniji vizualni identitet. Pružamo rješenja za kompletan brending, od jednokratnih projekata kao što su dizajn loga, print dizajn (brošure, leci, bilbordi), pa sve do kontinuirane saradnje na visokom nivou kompleksnosti i pokrivenosti.',
      },
      {
        // KOCKA 2-1
        img: kocka2GD,
        title: 'WEBSITE DIZAJN',
        bgcol: 'bg-doc-pink',
        text1:
          'Kada se radi o dizajnu i razvijanju web stranica koje će moći zapasti za oko ciljanoj skupini, prvi korak u procesu je identifikacija svrhe web stranice. U zavisnosti od svrhe, bila to prodaja, informisanje i podizanje svijesti o brendu, dizajner kreira mockup ideje, koje služe kao uvid u izgled stranice. Nakon definisanja finalnog izgleda s našim klijentima, naš tim započinje s razvojnom fazom projekta, te na kraju klijent dobiva 100% responsive web stranicu, optimizovanu za sve standardizovane pretraživače i uređaje.',
      },
      {
        // KOCKA 3-2
        img: kocka3GD,
        title: 'KAMPANJE ZA DRUŠTVENE MREŽE',
        bgcol: 'bg-doc-pink-dark',
        text1:
          'Promocija brenda kroz online digitalne kanale može donijeti višestruki povrat na investiciju, kao i značajno podići svijest o brendu. Kroz hirurški precizne reklame, posebno dizajnirane i prilagođene Vašoj ciljanoj skupini. Možete drastično povećati broj konverzija (prodaje i novi korisnici usluga) koje generišete putem digitalnih kanala. Naš dizajnerski tim će analizirati Vašu ciljanu skupinu, te će kreirati sadržaj koji će sam po sebi predstavljati Vaš brend u najboljem svjetlu.',
      },
    ],
    // CONTAINER ABOVE FOOTER
    lwttext1: 'POKRENIMO',
    lwttext2: '',
    lwttext3: 'SARADNJU',
  },
  // ? DIGITAL MARKETING DEPARTMENT
  {
    id: 6,
    title: 'DIGITALNI MARKETING',
    subtitle: 'SEKTOR',
    urlTo: '/bs/digitalni-marketing',
    bcHeadera: '#bd2981',
    headerimg: bgImgMainDM,
    boxicont1: 'GODINE ISKUSTVA',
    boxicont2: 'SPECIJALNOSTI',
    boxicont3: 'SRETNI KLIJENTI',
    boxicont4: 'PROJEKATA ZAVRŠENO',
    // META TAG i TITLE u head za React-Helmet
    rhtitle: 'Digitalni Marketing',
    rhdesc:
      'Na vašoj web stranici, aplikacijama ili drugim digitalnim kanalima možemo povećati promet, podići svijest o robnoj marki i još mnogo toga.',
    rhlocale: 'bs_BA',
    rhlinkco: '/bs/kontakt',
    // CASE STUDY
    casestadytitle: 'POSTANITE VIDLJIVI!',
    casestadybtn: 'btn-doc-pink',
    casestadytext:
      'Bez obzira na veličinu Vašeg brenda, biti online je prioritet! Koja je svrha Vaše najnovije i najljepše web stranice naprimjer, ako nema posjetilaca, te je ostavljena u zaboravu internet prostranstva. Kroz čitav spektar aktivnosti, DOC može povećati broj posjeta na Vašoj Web stranici, aplikaciji, kao i na drugim digitalnim kanalima. Pored toga, dovodimo do povećanog broja upita, podižemo svijest o brendu, pružamo usluge upravljanja zajednicom kao i mnogo, mnogo toga.',
    // BUTTON
    btnscrolldown: 'POMAKNITE DOLE DA VIDITE VIŠE',
    // THE SOLUTION:
    solutiontitle: 'ŠTA MOŽEMO URADITI',
    solutionbgcol: 'bg-doc-pink',
    solutiontext1:
      'Prateći razvoj tehnologije, veliki dio aktivnosti za podizanje svijesti o brendu se sada nalaze i dešavaju online. Bez obzira na vrstu proizvoda ili usluge, šanse su da su Vaši potencijalni klijenti online, te možda upravo traže Vaše usluge ili proizvode.  Ne samo da smo u stanju da efikasno komuniciramo Vaš brend ka ciljanoj skupini, na način koji nikada do sada nije bio moguć, već smo također sposobni da iskoristimo sve podatke skupljene u toku aktivnosti, te ih iskoristimo u optimizaciji promocije Vašeg brenda, kao i u menadžmentu ciljane skupine.',
    // PROJECT OVERVIEV
    potitle: 'DOC MARKETING PROCES',
    poicon1: poicon1DM,
    poicon1txt: 'ISTRAŽIVANJE KLIJENTA I CILJANE SKUPINE',
    poicon2: poicon2DM,
    poicon2txt: 'KREIRANJE PROMO STRATEGIJE',
    poicon3: poicon3DM,
    poicon3txt: 'DNEVNO OPTIMIZOVANE KAMPANJE',
    poicon4: poicon4DM,
    poicon4txt: 'IZVJEŠTAJ + PREPORUKE',
    // KOCKA
    kocka: [
      // KOCKA 1-0
      {
        img: kocka1DM,
        title: 'FACEBOOK MENADŽMENT',
        bgcol: 'bg-doc-pink-dark',
        text1:
          'Facebook je najveća društvena mreža na svijetu, te je temeljno promijenila način na koji se povezujemo jedni s drugima. Facebook je jako moćan alat, koji može generisati instant povratnu informaciju od Vaših korisnika i pratitelja, kao što i može donijeti nove upite, klijente itd. Bilo da trebate kvalitetan menadžment zajednicom i ciljanom skupinom, ili možda hirurški precizne reklame na Facebook Ad platformi, s nama nemate brige.',
      },
      {
        // KOCKA 2-1
        img: kocka2DM,
        title: 'INSTAGRAM MENADŽMENT',
        bgcol: 'bg-doc-pink',
        text1:
          'Instagram je u potpunosti integrisan u Facebook Ad menadžer, što nam omogućava da vodimo hirurški precizne kampanje i na ovoj platformi. Ako tome dodamo činjenicu da je Instagram druga najveća društvena mreža na svijetu, postane Vam jasno kao slika, zašto je svakom brendu neophodno da bude prisutan i vidljiv i na ovoj platformi.',
      },
      {
        // KOCKA 3-2
        img: kocka3DM,
        title: 'CHAT BOT AUOTMATIZACIJA',
        bgcol: 'bg-doc-pink-dark',
        text1:
          'Zamislite potpuno automatizovan Chat Bot za Vašu Facebook ili Web stranicu, koji pruža potrebne informacije Vašim korisnicima i zainteresovanim klijentima, 24/7. S nama, to je itekako moguće! A stvari postaju još zanimljivije, obzirom da možete zapamtiti svakog pojedinačnog posjetioca, segmentirati ih u različite grupe, u zavisnosti od informacija koje su tražili. Sada Vaši agenti mogu uložiti više vremena u razvoj smislenih konverzacija, umjesto u pružanje repetitivnih informacija.',
      },
      {
        // KOCKA 4-3
        img: kocka4DM,
        title: 'GOOGLE MENADŽMENT',
        bgcol: 'bg-doc-pink',
        text1:
          'Google Search: Prikažite Vašu uslugu ili proizvod, ljudima koji traže upravo tu uslugu ili proizvod, u trenutku kada to rade. Zar to nije “smart marketing” u punom sjaju? Google Search je marketing zasnovan na “namjeri”, što znači da se reklame prikazuju u rezultatima pretrage korisnika koji traže specifičnu stvar.',
        text2:
          'Google Display: Koristite našu mrežu partnera, te prikazujte svoje banere na najvećim web stranicama i portalima na internetu. Pored toga, možete koristiti našu display mrežu kako biste sprovodili remarketing aktivnosti, te biti sigurni da Vas klijenti zaista nikad neće zaboraviti',
        text3:
          'YouTube: Svi znate za YouTube, svi ste čuli o YouTubu, i jednostavno znamo da ste bar nešto pogledali na ovoj video platformi. To je najveća platforma na svijetu za dijeljenje video sadržaja, i samo ta činjenica ovu platformu čini savršenom da oglašavate bilo kakvu vrstu proizvoda ili usluge, kao i da kreirate video zajednicu orijentisanu prema Vašem brendu.',
      },
    ],
    // CONTAINER ABOVE FOOTER
    lwttext1: 'POKRENIMO',
    lwttext2: '',
    lwttext3: 'SARADNJU',
  },
  // ? SOFTWARE DEVELOPMENT
  {
    id: 7,
    title: 'RAZVOJ SOFTVERA',
    subtitle: 'SEKTOR',
    urlTo: '/bs/razvoj-softvera',
    bcHeadera: '#bd2981',
    headerimg: bgImgMainSD,
    boxicont1: 'POD-SEKTOR',
    boxicont2: 'VJEŠTINE / PROGRAMSKI JEZICI',
    boxicont3: 'BROJ LINIJA KODA',
    boxicont4: 'ZADOVOLJNI KLIJENTI',
    // META TAG i TITLE u head za React-Helmet
    rhtitle: 'Razvoj Softvera',
    rhdesc:
      'Imamo iskustva s mnogim softverskim rješenjima, od najjednostavnijih do najsloženijih i svakom pristupamo s istom razinom profesionalnosti.',
    rhlocale: 'bs_BA',
    rhlinkco: '/bs/kontakt',
    // CASE STUDY
    casestadytitle: '>HELLO WORLD!_',
    casestadybtn: 'btn-doc-pink',
    casestadytext:
      'Mi smo tim developera koji su spremni da odgovore na Vaše posebne individualne zahtjeve. Kroz godine rada, stekli smo iskustvo u širokom spektru softverskih rješenja, od najjednostavnijih, pa sve do najkompleksnijih, maksimalno se posvećujući svakom projektu. Naš tim je sretan samo onda kada je i naš klijent sretan i zadovoljan finalnim proizvodom.',
    // BUTTON
    btnscrolldown: 'POMAKNITE DOLE DA VIDITE VIŠE',
    // THE SOLUTION:
    solutiontitle: 'ŠTA MOŽEMO URADITI',
    solutionbgcol: 'bg-doc-pink',
    solutiontext1:
      'U DOC-u vjerujemo da svaki softver treba učiniti život lakšim, bez obzira bio korišten u privatne ili poslovne svrhe. Iz ovog razloga, esencijalno je da se svaki sistem razvija u bliskoj saradnji i komunikaciji s klijentom, a u cilju ispunjenja svih želja i ciljeva. Nakon mnogo komplikovanih i uspješnih projekata, ponosni smo da je naš sektor za razvoj softvera proizveo stabilna i pouzdana rješenja, s posebnim akcentom na jednostavnost upotrebe i kvalitetnog korisničkog iskustva. Mnogo nam je bitno da sve funkcioniše, obzirom da se poistovjećujemo sa svim polomljenim tastaturama kada stvari "jednostavno nisu radile".',
    // PROJECT OVERVIEV
    potitle: 'DOC RAZVOJNI PROCES',
    poicon1: poicon1SD,
    poicon1txt: 'RAZUMIJEVANJE POTREBA KLIJENTA',
    poicon2: poicon2SD,
    poicon2txt: 'DIZAJN I RAZVOJ',
    poicon3: poicon3SD,
    poicon3txt: 'FINALNE DORADE',
    poicon4: poicon4SD,
    poicon4txt: 'SRETNI KLIJENTI',
    // KOCKA
    kocka: [
      // KOCKA 1-0
      {
        img: kocka1SD,
        title: 'RAZVOJ WEB STRANICA',
        bgcol: 'bg-doc-pink-dark',
        text1:
          'Najbolji način da započnete uspješan novi biznis, najčešće počinje kroz dobro prisustvo na internetu. Ovo uključuje sve, od najjednostavnijih informativnih web stranica, pa do najkompleksnijih web aplikacija s mnogim naprednim funkcionalnostima, a sve u svrhu ispunjenja ciljeva klijenta. Razvojni proces web stranice uključuje veoma jasno razumijevanje definsanog cilja, te prijedlog najefikasnijeg rješenja u skladu s istim. U ovom procesu učestvuju marketari, projekt menadžeri, kao i svi ostali članovi tima, a sve u svrhu pružanja sveobuhvatnog i maksimalno prilagođenog sadržaja ciljanoj skupini.',
      },
      {
        // KOCKA 2-1
        img: kocka2SD,
        title: 'RAZVOJ MOBILNIH APLIKACIJA',
        bgcol: 'bg-doc-pink',
        text1:
          'Upotreba pametnih telefona je rapidno narasla u proteklom periodu, te ne pokazuje nikakve znakove usporavanja u skorijoj budućnosti. Ova činjenica otkriva veliki potencijal na tržištu mobilnih aplikacija, koje uveliko olakšavaju promociju Vašeg brenda, povećavaju broj korisnika, te olakšavanju interakciju Vašoj ciljanoj skupini.',
      },
      {
        // KOCKA 3-2
        img: kocka3SD,
        title: 'GEOGRAFSKI INFORMACIONI SISTEM',
        bgcol: 'bg-doc-pink-dark',
        text1:
          'Geografski informacioni sistem (GIS), je kompjuterski sistem namijenjen upravljanju podataka vezanih za geografske lokacije na Zemljinoj površini. GIS može prikazivati mnogo različitih tipova podataka na jednoj karti, kao što su ulice, zgrade, vegetacija, itd. Ovo omogućava korisnicima da na jednostavan način analiziraju geoinformacione podatke, kao i da analiziraju obrasce i poveznice. Vrlo smo ponosni na činjenicu da u našem timu imamo dva GIS eksperta.',
      },
    ],
    // CONTAINER ABOVE FOOTER
    lwttext1: 'POKRENIMO',
    lwttext2: '',
    lwttext3: 'SARADNJU',
  },
];

export default pages;
