import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
// Font Awesome Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// Main logo
import logoGrey from '../../assets/img/logo-grey.svg';
// Custom CSS
import './Footer.scss';
// ToTop
import ToTop from './ToTop';
// Consumer za jezike ContextAPI
import LanguageConsumer from '../context/Language';
// Global Data JSON za prevode
import globalData from '../data/global.json';
/**
 * Footer Component
 *
 * @augments {Component<Props, State>}
 */
const Footer = (props) => {
  var data = useContext(LanguageConsumer);
  const [leng, setLeng] = useState(globalData);

  useEffect(() => {
    if (data) {
      setLeng(data.globalno);
    }
  }, [data, props.history]);

  return (
    <footer
      style={{
        backgroundColor: '#25292b',
        padding: '70px 0 0 0',
        margin: '-40px auto auto auto',
      }}
    >
      <Container className="pb-5 d-none d-sm-block">
        <Row>
          <Col xs={12} sm={6} className="d-flex align-items-start">
            <div className="pt-1">
              <Link to={leng[0].rhslagbrand}>
                <img src={logoGrey} alt="Logo DOC.ba" height="40" />
              </Link>
              <div className="d-block d-sm-none">
                <ToTop style={{ bottom: '5rem' }} />
              </div>
            </div>
            <div className="d-flex flex-column pl-5 text-decoration-none">
              <Link to={leng[0].rhslaghome} className="footer-link">
                {leng[0].navbarhome}
              </Link>
              <Link to={leng[0].rhslagabout} className="footer-link">
                {leng[0].navbarabout}
              </Link>
              <Link to={leng[0].rhslagservices} className="footer-link">
                {leng[0].navbarservices}
              </Link>
              <Link to={leng[0].rhslagcontact} className="footer-link">
                {leng[0].navbarcontact}
              </Link>
            </div>
          </Col>
          <Col sm={6} className="d-flex justify-content-end align-self-lg-start px-sm-0">
            <div className="d-none d-sm-block">
              <div className="fix-totop">
                <ToTop />
              </div>
              <div className="d-flex align-items-center">
                <a
                  href="https://www.facebook.com/ITDOC.ba/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="footer-ikonice px-1 py-0"
                >
                  <span className="fa-layers fa-2x">
                    <FontAwesomeIcon icon={['fas', 'square']} color="#3b5998" />
                    <FontAwesomeIcon icon={['fab', 'facebook-f']} inverse transform="shrink-9" />
                  </span>
                </a>
                <a
                  href="https://www.linkedin.com/company/docba"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="footer-ikonice px-1 py-0"
                >
                  <span className="fa-layers fa-2x">
                    <FontAwesomeIcon icon={['fas', 'square']} color="#0e76a8" />
                    <FontAwesomeIcon icon={['fab', 'linkedin-in']} inverse transform="shrink-9" />
                  </span>
                </a>
                <a
                  href="https://goo.gl/maps/qGhN1z9yyhbxWEBW6"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="footer-ikonice px-1 py-0"
                >
                  <span className="fa-layers fa-2x">
                    <FontAwesomeIcon icon={['fas', 'square']} color="#ff3d00" />
                    <FontAwesomeIcon icon={['fab', 'google']} inverse transform="shrink-9" />
                  </span>
                </a>
                <p className="footer-text small my-0 pl-3">
                  <FontAwesomeIcon icon={['far', 'copyright']} /> 2020 DOC.BA | {leng[0].footerarr}
                </p>
              </div>
            </div>
          </Col>
        </Row>
      </Container>

      <Container className="d-block d-sm-none mt-n3" style={{ backgroundColor: '#25292b' }}>
        <Row>
          <Col xs={12} className="d-flex justify-content-center align-items-center">
            <div className="mb-1">
              <Link to={leng[0].rhslagbrand}>
                <img src={logoGrey} alt="Logo DOC.ba" height="28" />
              </Link>
              <div className="fix-mob-totop">
                <ToTop />
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col
            xs={12}
            className="d-flex justify-content-center align-items-center text-decoration-none my-2"
          >
            <div className="footer-div py-2">
              <span className="footer-span-p">
                <Link to={leng[0].rhslaghome} className="footer-link">
                  {leng[0].navbarhome}
                </Link>
              </span>
              <span className="footer-span">
                <Link to={leng[0].rhslagabout} className="footer-link">
                  {leng[0].navbarabout}
                </Link>
              </span>
              <span className="footer-span">
                <Link to={leng[0].rhslagservices} className="footer-link">
                  {leng[0].navbarservices}
                </Link>
              </span>
              <span className="footer-span-z">
                <Link to={leng[0].rhslagcontact} className="footer-link">
                  {leng[0].navbarcontact}
                </Link>
              </span>
            </div>
          </Col>
        </Row>
        <Row>
          <Col xs={12} className="d-flex justify-content-center align-items-center">
            <p className="footer-text-mob small mb-3 mt-1">
              <FontAwesomeIcon icon={['far', 'copyright']} /> 2020 DOC.BA |{leng[0].footerarr}
            </p>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
