import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './ToTop.scss';
/**
 * ToTop Component
 *
 * @augments {Component<Props, State>}
 */
const ToTop = () => {
  return (
    <a href="#top" className="text-white">
      <span className="totop">
        <FontAwesomeIcon icon="chevron-up" />
      </span>
    </a>
  );
};

export default ToTop;
