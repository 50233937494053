import React, { Fragment, useState, useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
// Helmet za meta tagove dinamicko mjenjenje
import { Helmet } from 'react-helmet';
// Bootstrap components
import { Container, Row, Col } from 'react-bootstrap';
// Font Awesome Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// SCSS for Services page
import './About.scss';
// Pages Data JSON
import pagesData from '../../data/pages.js';
// Home components
import Header from '../../Layout/Header';
import HeaderBox from '../../Layout/HeaderBox';
import HeaderBoxIcons from '../../Layout/HeaderBoxIcons';
import HeaderScrollDown from '../../Layout/HeaderScrollDown';
import HeaderSocialLink from '../../Layout/HeaderSocialLink';
// Page components
import CaseStady from '../../Layout/CaseStudy';
import TheSolution from '../../Layout/ProjectsPagesTheSolution';
//import TeamMembersAboutBox from '../../Layout/TeamMembersAboutBox';
//import TeamMembersAboutImg from '../../Layout/TeamMembersAboutImg';
import ContainerAboveFooter from '../../Layout/ContainerAboveFooter';
import Deviders from '../../Layout/Deviders';
// Consumer za jezike ContextAPI
import LanguageConsumer from '../../context/Language';
/**
 * ABOUT page component
 */
const pagesidx = 0;

const About = () => {
  let data = useContext(LanguageConsumer);
  const [leng, setLeng] = useState(pagesData);

  let history = useHistory();

  useEffect(() => {
    if (data) {
      setLeng(data.stranice);
      if (data.curentLng) {
        history.push('/bs/o-nama');
      } else {
        history.push('/about');
      }
    }
  }, [data, history]);

  return (
    <Fragment>
      <Helmet>
        <title>{leng[pagesidx].rhtitle}</title>
        <meta name="description" content={leng[pagesidx].rhdesc} />
        <meta property="og:description" content={leng[pagesidx].rhdesc} />
        <meta property="og:locale" content={leng[pagesidx].rhlocale} />
      </Helmet>
      <Header
        height={'42.5em'}
        bgColor={leng[pagesidx].bcHeadera}
        bgImg={leng[pagesidx].headerimg}
        headerId={'about'}
      >
        <Container className="header-main px-0 h-100">
          <Row className="no-gutters d-flex align-content-center align-content-sm-end align-content-lg-end h-100">
            <HeaderBox title={leng[pagesidx].title} subtitle={leng[pagesidx].subtitle} />
            <Col
              xs={12}
              lg={9}
              className="fix-iznad-scroll mx-auto d-flex justify-content-center text-white p-2 p-lg-3"
            >
              <HeaderBoxIcons boxBroj={'5'} boxText={leng[pagesidx].boxicont1}>
                <FontAwesomeIcon icon={['fad', 'heart']} size={'3x'} />
              </HeaderBoxIcons>
              <HeaderBoxIcons boxBroj={'7'} boxText={leng[pagesidx].boxicont2}>
                <FontAwesomeIcon icon={['fad', 'users']} size={'3x'} />
              </HeaderBoxIcons>
              <HeaderBoxIcons boxBroj={'6'} boxText={leng[pagesidx].boxicont3}>
                <FontAwesomeIcon icon={['fad', 'user-astronaut']} size={'3x'} />
              </HeaderBoxIcons>
              <HeaderBoxIcons boxBroj={'3'} boxText={leng[pagesidx].boxicont4}>
                <FontAwesomeIcon icon={['fad', 'handshake']} size={'3x'} />
              </HeaderBoxIcons>
            </Col>
            <HeaderScrollDown btnscrolldown={leng[pagesidx].btnscrolldown} />
          </Row>
        </Container>
        <HeaderSocialLink />
      </Header>

      <CaseStady
        casestadytitle={leng[pagesidx].casestadytitle}
        casestadysubtitle={leng[pagesidx].casestadysubtitle}
        casestadytext={leng[pagesidx].casestadytext}
        casestadybtn={leng[pagesidx].casestadybtn}
      />

      <TheSolution
        strikepoz={'strike-r'}
        strikecol={'strike-w'}
        solutionbgcol={leng[pagesidx].solutionbgcol}
        solutionlight={leng[pagesidx].solutionlight}
        solutionbold={leng[pagesidx].solutionbold}
        solutiontext1={leng[pagesidx].solutiontext1}
        solutiontext2={leng[pagesidx].solutiontext2}
        solutiontext3={leng[pagesidx].solutiontext3}
        potitle={leng[pagesidx].potitle}
        poicon1={leng[pagesidx].poicon1}
        poicon1txt={leng[pagesidx].poicon1txt}
        poicon2={leng[pagesidx].poicon2}
        poicon2txt={leng[pagesidx].poicon2txt}
        poicon3={leng[pagesidx].poicon3}
        poicon3txt={leng[pagesidx].poicon3txt}
        poicon4={leng[pagesidx].poicon4}
        poicon4txt={leng[pagesidx].poicon4txt}
      />

      <Deviders striketitle="" /* {leng[pagesidx].devidertext} */ strikepoz={'strike-r'} />

      {/* <Container className="px-0">
        <Row className="no-gutters d-flex justify-content-center justify-content-md-end align-items-center">
          <Col
            sm={12}
            md={6}
            lg={6}
            className="section-title-ot1 d-flex align-items-center order-1 order-sm-1 order-md-1 order-lg-1 bg-primary text-white p-5"
          >
            <TeamMembersAboutBox osobaidx={0} />
          </Col>
          <Col
            sm={7}
            md={5}
            lg={6}
            className="section-kocka-ot1 d-flex align-items-center order-0 order-sm-0 order-md-0 order-lg-0 text-white"
          >
            <TeamMembersAboutImg osobaidx={0} slike={1} />
          </Col>
        </Row>
        <Row className="no-gutters d-flex justify-content-center justify-content-md-start align-items-center">
          <Col
            sm={12}
            md={6}
            lg={6}
            className="section-title-ot2 d-flex align-items-center order-1 order-sm-1 order-md-0 order-lg-0 bg-doc-primary-light text-white py-5 py-lg-3 px-5"
          >
            <TeamMembersAboutBox osobaidx={1} />
          </Col>
          <Col
            sm={7}
            md={5}
            lg={6}
            className="section-kocka-ot2 d-flex align-items-center order-0 order-sm-0 order-md-1 order-lg-1 text-white"
          >
            <TeamMembersAboutImg osobaidx={1} slike={2} />
          </Col>
        </Row>
        <Row className="no-gutters d-flex justify-content-center justify-content-md-end align-items-center">
          <Col
            sm={12}
            md={6}
            lg={6}
            className="section-title-ot1m d-flex align-items-center order-1 order-sm-1 order-md-1 order-lg-1 bg-secondary text-white py-5 py-lg-3 px-5"
          >
            <TeamMembersAboutBox osobaidx={2} btncolor={'text-secondary'} />
          </Col>
          <Col
            sm={7}
            md={5}
            lg={6}
            className="section-kocka-ot2 d-flex align-items-center order-0 order-sm-0 order-md-0 order-lg-0 text-white"
          >
            <TeamMembersAboutImg osobaidx={2} slike={2} />
          </Col>
        </Row>
        <Row className="no-gutters d-flex justify-content-center justify-content-md-start align-items-center">
          <Col
            sm={12}
            md={6}
            lg={6}
            className="section-title-ot1 d-flex align-items-center order-1 order-sm-1 order-md-0 order-lg-0 bg-doc-primary-light text-white p-5"
          >
            <TeamMembersAboutBox osobaidx={3} />
          </Col>
          <Col
            sm={7}
            md={5}
            lg={6}
            className="section-kocka-ot1 d-flex align-items-center order-0 order-sm-0 order-md-1 order-lg-1 text-white"
          >
            <TeamMembersAboutImg osobaidx={3} slike={1} />
          </Col>
        </Row>
        <Row className="no-gutters d-flex justify-content-center justify-content-md-end align-items-center">
          <Col
            sm={12}
            md={6}
            lg={6}
            className="section-title-ot1m d-flex align-items-center order-1 order-sm-1 order-md-1 order-lg-1 bg-primary text-white py-5 py-lg-3 px-5"
          >
            <TeamMembersAboutBox osobaidx={4} />
          </Col>
          <Col
            sm={7}
            md={5}
            lg={6}
            className="section-kocka-ot2 d-flex align-items-center order-0 order-sm-0 order-md-0 order-lg-0 text-white"
          >
            <TeamMembersAboutImg osobaidx={4} slike={2} />
          </Col>
        </Row>
        <Row className="no-gutters d-flex justify-content-center justify-content-md-start align-items-center">
          <Col
            sm={12}
            md={6}
            lg={6}
            className="section-title-ot2 d-flex align-items-center order-1 order-sm-1 order-md-0 order-lg-0 bg-doc-primary-light text-white py-5 py-lg-3 px-5"
          >
            <TeamMembersAboutBox osobaidx={5} />
          </Col>
          <Col
            sm={7}
            md={5}
            lg={6}
            className="section-kocka-ot2 d-flex align-items-center order-0 order-sm-0 order-md-1 order-lg-1 text-white"
          >
            <TeamMembersAboutImg osobaidx={5} slike={2} />
          </Col>
        </Row>
        <Row className="no-gutters d-flex justify-content-center justify-content-md-end align-items-center">
          <Col
            sm={12}
            md={6}
            lg={6}
            className="section-title-ot1m d-flex align-items-center order-1 order-sm-1 order-md-1 order-lg-1 bg-primary text-white py-5 py-lg-3 px-5"
          >
            <TeamMembersAboutBox osobaidx={6} />
          </Col>
          <Col
            sm={7}
            md={5}
            lg={6}
            className="section-kocka-ot2 d-flex align-items-center order-0 order-sm-0 order-md-0 order-lg-0 text-white"
          >
            <TeamMembersAboutImg osobaidx={6} slike={2} />
          </Col>
        </Row>
        <Row className="no-gutters d-flex justify-content-center justify-content-md-start align-items-center">
          <Col
            sm={12}
            md={6}
            lg={6}
            className="section-title-ot2 d-flex align-items-center order-1 order-sm-1 order-md-0 order-lg-0 bg-doc-primary-light text-white py-5 py-lg-3 px-5"
          >
            <TeamMembersAboutBox osobaidx={7} />
          </Col>
          <Col
            sm={7}
            md={5}
            lg={6}
            className="section-kocka-ot2 d-flex align-items-center order-0 order-sm-0 order-md-1 order-lg-1 text-white"
          >
            <TeamMembersAboutImg osobaidx={7} slike={2} />
          </Col>
        </Row>
        <Row className="no-gutters d-flex justify-content-center justify-content-md-end align-items-center">
          <Col
            sm={12}
            md={6}
            lg={6}
            className="section-title-ot1 d-flex align-items-center order-1 order-sm-1 order-md-1 order-lg-1 bg-primary text-white p-5"
          >
            <TeamMembersAboutBox osobaidx={8} />
          </Col>
          <Col
            sm={7}
            md={5}
            lg={6}
            className="section-kocka-ot1 d-flex align-items-center order-0 order-sm-0 order-md-0 order-lg-0 text-white"
          >
            <TeamMembersAboutImg osobaidx={8} slike={1} />
          </Col>
        </Row>
        <Row className="no-gutters d-flex justify-content-center justify-content-md-start align-items-center">
          <Col
            sm={12}
            md={6}
            lg={6}
            className="section-title-ot1 d-flex align-items-center order-1 order-sm-1 order-md-0 order-lg-0 bg-doc-primary-light text-white p-5"
          >
            <TeamMembersAboutBox osobaidx={9} />
          </Col>
          <Col
            sm={7}
            md={5}
            lg={6}
            className="section-kocka-ot1 d-flex align-items-center order-0 order-sm-0 order-md-1 order-lg-1 text-white"
          >
            <TeamMembersAboutImg osobaidx={9} slike={1} />
          </Col>
        </Row>
      </Container> */}

      <Container className="fix-container-ot-plavi px-0 d-none d-lg-block d-xl-block">
        <Row className="no-gutters">
          <Col xs={6} className="fix-div-ot-plavi bg-doc-primary-light"></Col>
        </Row>
      </Container>

      <ContainerAboveFooter
        lwttext1={leng[pagesidx].lwttext1}
        lwttext2={leng[pagesidx].lwttext2}
        lwttext3={leng[pagesidx].lwttext3}
        lwtlinkTo={leng[pagesidx].rhlinkco}
      />
    </Fragment>
  );
};

export default About;
