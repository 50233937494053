import React from 'react';
// Font Awesome Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
/**
 * Header Scroll Down component
 */
const HeaderScrollDown = ({ btnscrolldown }) => {
  return (
    <div className="col-12 text-white text-center mt-auto pb-4">
      <a
        id="sdtsos"
        href="#scrollto"
        className="small text-white text-decoration-none d-flex flex-column dinpro-medium m-0"
      >
        <span className="title-ikonice align-middle">
          <FontAwesomeIcon icon={['fal', 'mouse-alt']} size={'2x'} />
        </span>
        {btnscrolldown}
      </a>
    </div>
  );
};

export default HeaderScrollDown;
