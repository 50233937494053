import { useState, useEffect } from 'react';

// Window Resize Utility Component Hooks
const UseWindowWidth = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const handleWindowResize = () => {
    setWindowWidth(window.innerWidth);
  };
  useEffect(() => {
    window.addEventListener('resize', handleWindowResize);
    return () => window.removeEventListener('resize', handleWindowResize);
  }, []);
  return windowWidth;
};

export default UseWindowWidth;
