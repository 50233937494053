import React, { Fragment, useState, useEffect, useContext } from 'react';
import { NavLink, useHistory } from 'react-router-dom';
// Helmet za meta tagove dinamicko mjenjenje
import { Helmet } from 'react-helmet';
// Bootstrap components
import { Container, Row, Col } from 'react-bootstrap';
// Font Awesome Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// SCSS for Services page
import './OurWork.scss';
// Pages Data JSON
import pagesData from '../../data/pages.js';
// Logos
import IugLogo from '../../../assets/img/logos/logoIUG.svg';
import PoliglotLogo from '../../../assets/img/logos/logoPOLIGLOT.svg';
import PomoziLogo from '../../../assets/img/logos/logoPOMOZIBA.svg';
import SunriseLogo from '../../../assets/img/logos/sunrise2.png';
import DrvLogo from '../../../assets/img/logos/logoDRV.svg';
import HrfLogo from '../../../assets/img/logos/logoHRF.svg';
import IpadramLogo from '../../../assets/img/logos/logoIPADRAM.svg';
import MetadataLogo from '../../../assets/img/logos/logoMETADATA.svg';
import NosaLogo from '../../../assets/img/logos/logoNOSA.svg';
import OccLogo from '../../../assets/img/logos/logoOCC.svg';
import ReprLogo from '../../../assets/img/logos/logoREPR.svg';
import StillesLogo from '../../../assets/img/logos/logoSTILLES.svg';
import CollectivibeLogo from '../../../assets/img/logos/logoCOLLECTIVIBE.png';

// Overly
import iugFeatured from '../../../assets/img/logos/featuredIUG.png';
import poliglotFeatured from '../../../assets/img/logos/featuredPOLIGLOT.png';
// Home components
import Header from '../../Layout/Header';
import HeaderBox from '../../Layout/HeaderBox';
import HeaderBoxIcons from '../../Layout/HeaderBoxIcons';
import HeaderScrollDown from '../../Layout/HeaderScrollDown';
import HeaderSocialLink from '../../Layout/HeaderSocialLink';
// Page components
import CaseStady from '../../Layout/CaseStudy';
import ProjectsOurWorkBox from '../../Layout/ProjectsOurWorkBox';
import ContainerAboveFooter from '../../Layout/ContainerAboveFooter';
// Consumer za jezike ContextAPI
import LanguageConsumer from '../../context/Language';
/**
 * Our Work page component
 */
const pagesidx = 2;

const OurWork = () => {
  let data = useContext(LanguageConsumer);
  const [leng, setLeng] = useState(pagesData);

  let history = useHistory();

  useEffect(() => {
    if (data) {
      setLeng(data.stranice);
      if (data.curentLng) {
        history.push('/bs/portfolio');
      } else {
        history.push('/our-work');
      }
    }
  }, [data, history]);

  return (
    <Fragment>
      <Helmet>
        <title>{leng[pagesidx].rhtitle}</title>
        <meta name="description" content={leng[pagesidx].rhdesc} />
        <meta property="og:description" content={leng[pagesidx].rhdesc} />
        <meta property="og:locale" content={leng[pagesidx].rhlocale} />
      </Helmet>
      <Header
        height={'42.5em'}
        bgColor={leng[pagesidx].bcHeadera}
        bgImg={leng[pagesidx].headerimg}
        headerId={'our-work'}
      >
        <Container className="header-main px-0 h-100">
          <Row className="no-gutters d-flex align-content-center align-content-sm-end align-content-lg-end h-100">
            <HeaderBox title={leng[pagesidx].title} subtitle={leng[pagesidx].subtitle} />
            <Col
              xs={12}
              lg={9}
              className="fix-iznad-scroll mx-auto d-flex justify-content-center text-white p-2 p-lg-3"
            >
              <HeaderBoxIcons boxBroj={'12'} boxText={leng[pagesidx].boxicont1}>
                <FontAwesomeIcon icon={['fad', 'box-check']} size={'3x'} />
              </HeaderBoxIcons>
              <HeaderBoxIcons boxBroj={'68'} boxText={leng[pagesidx].boxicont2}>
                <FontAwesomeIcon icon={['fad', 'laptop-code']} size={'3x'} />
              </HeaderBoxIcons>
              <HeaderBoxIcons boxBroj={'9'} boxText={leng[pagesidx].boxicont3}>
                <FontAwesomeIcon icon={['fad', 'space-shuttle']} size={'3x'} rotation={270} />
              </HeaderBoxIcons>
              <HeaderBoxIcons boxBroj={'50+'} boxText={leng[pagesidx].boxicont4}>
                <FontAwesomeIcon icon={['fad', 'mail-bulk']} size={'3x'} />
              </HeaderBoxIcons>
            </Col>
            <HeaderScrollDown btnscrolldown={leng[pagesidx].btnscrolldown} />
          </Row>
        </Container>
        <HeaderSocialLink />
      </Header>

      <CaseStady
        casestadytitle={leng[pagesidx].casestadytitle}
        casestadysubtitle={leng[pagesidx].casestadysubtitle}
        casestadytext={leng[pagesidx].casestadytext}
        casestadybtn={leng[pagesidx].casestadybtn}
      />

      <ProjectsOurWorkBox btnloadmore={leng[pagesidx].btnloadmore} />

      <Container className="px-0 mb-2">
        <Row className="no-gutters">
          <div className="col-lg-4 col-md-5 col-sm-12 col-12 bg-doc-red text-white p-5 pr-md-0">
            <div className="fix-strelica">
              <h1 className="dinpro-light mb-0">
                {leng[pagesidx].ovclight1} {leng[pagesidx].ovclight2}
                <br />
                <span className="dinpro-bold">{leng[pagesidx].ovcbold}</span>
                <span className="pl-3 span-ikonica-fa align-middle">
                  <FontAwesomeIcon icon={['fal', 'chevron-right']} />
                </span>
              </h1>
            </div>
          </div>
          <div className="col-lg-8 col-md-7 col-sm-12 col-12 bg-white">
            <div className="d-flex justify-content-between align-items-center flex-wrap px-2 px-lg-5 px-md-4 px-sm-3 py-3">
              <a id="ovc01" className="p-3 mx-auto">
                <img src={CollectivibeLogo} alt="" className="ovc_discover__image" />
              </a>
              <a id="ovc02" className="p-3 mx-auto">
                <img src={SunriseLogo} alt="" className="ovc_discover__image" />
              </a>
              <a id="ovc03" className="p-3 mx-auto">
                <img src={PomoziLogo} alt="" className="ovc_discover__image" />
              </a>
              <a id="ovc04" className="p-3 mx-auto">
                <img src={DrvLogo} alt="" className="ovc_discover__image" />
              </a>
              <a id="ovc05" className="p-3 mx-auto">
                <img src={HrfLogo} alt="" className="ovc_discover__image" />
              </a>
              <a id="ovc06" className="p-3 mx-auto">
                <img src={IpadramLogo} alt="" className="ovc_discover__image" />
              </a>
              <a id="ovc07" className="p-3 mx-auto d-none d-sm-block d-md-block">
                <img src={IugLogo} alt="" className="ovc_discover__image" />
              </a>
              <a id="ovc08" className="p-3 mx-auto d-none d-sm-block d-md-block">
                <img src={MetadataLogo} alt="" className="ovc_discover__image" />
              </a>
              <a id="ovc09" className="p-3 mx-auto d-none d-sm-block d-md-block">
                <img src={NosaLogo} alt="" className="ovc_discover__image" />
              </a>
              <a id="ovc10" className="p-3 mx-auto d-none d-md-block d-lg-block">
                <img src={OccLogo} alt="" className="ovc_discover__image" />
              </a>
              <a id="ovc11" className="p-3 mx-auto d-none d-md-none d-lg-block">
                <img src={ReprLogo} alt="" className="ovc_discover__image" />
              </a>
              <a id="ovc12" className="p-3 mx-auto d-none d-md-none d-lg-block">
                <img src={StillesLogo} alt="" className="ovc_discover__image" />
              </a>
              {/* <a
                id="ovc13"
                href={leng[pagesidx].rhlinkpoliglot}
                className="p-3 mx-auto d-none d-lg-none d-xl-block"
              >
                <img src={PoliglotLogo} alt="" className="ovc_discover__image" />
              </a>
              <a
                id="ovc14"
                href={leng[pagesidx].rhlinkdoc}
                className="p-3 mx-auto d-none d-xl-block"
              >
                <img src={SunriseLogo} alt="" className="ovc_discover__image" />
              </a>
              <a
                id="ovc15"
                href={leng[pagesidx].rhlinkpomoziba}
                className="p-3 mx-auto d-none d-xl-block"
              >
                <img src={PomoziLogo} alt="" className="ovc_discover__image" />
              </a>
              <a
                id="ovc16"
                href={leng[pagesidx].rhlinkpomoziba}
                className="p-3 mx-auto d-none d-xl-block"
              >
                <img src={DrvLogo} alt="" className="ovc_discover__image" />
              </a> */}
            </div>
          </div>
        </Row>
      </Container>

      <Container className="px-0 pt-4 pt-sm-0">
        <Row className="no-gutters">
          <div className="col-lg-12 col-md-12 col-sm-12 col-12 text-dark mb-4 mt-0 mt-sm-5 mb-sm-5 px-3 px-sm-0">
            <h4 className="strike-r">
              <span className="strike-b dinpro-bold">{leng[pagesidx].devidertext}</span>
            </h4>
          </div>
        </Row>
      </Container>

      <Container className="px-0">
        <Row className="no-gutters d-flex justify-content-center justify-content-md-end align-items-center">
          <div className="section-title-ow1 d-flex align-items-center order-1 order-sm-1 order-md-1 order-lg-1 col-lg-6 col-md-6 col-sm-12 bg-doc-light-oker p-5">
            <div>
              <h3 className="dinpro-light pb-3">{leng[pagesidx].dtnaslov}</h3>
              <p>{leng[pagesidx].dttext}</p>
              <NavLink
                exact
                to={leng[pagesidx].rhlinkpoliglot}
                className="text-decoration-none text-dark"
              >
                {leng[pagesidx].btnreadmore}
              </NavLink>
            </div>
          </div>
          <div className="section-kocka-ow1 d-flex align-items-center order-0 order-sm-0 order-md-0 order-lg-0 col-lg-6 col-md-5 col-sm-7 text-white">
            <div className="card img-efect text-white rounded-0 border-0 mx-auto">
              <NavLink
                exact
                to={leng[pagesidx].rhlinkpoliglot}
                className="text-decoration-none stretched-link"
              >
                <img src={poliglotFeatured} className="card-img rounded-0 border-0" alt="" />
              </NavLink>
              <div className="card-img-overlay d-flex align-items-center">
                <h5 className="card-title text-center d-flex justify-content-center mx-auto">
                  <img src={PoliglotLogo} className="card-img w-75" alt="" />
                </h5>
              </div>
            </div>
          </div>
        </Row>
        <Row className="no-gutters d-flex justify-content-center justify-content-md-start align-items-start">
          <div className="section-title-ow2 d-flex align-items-center order-1 order-sm-1 order-md-0 order-lg-0 col-lg-6 col-md-6 col-sm-12 bg-doc-light-oker p-5">
            <div>
              <h3 className="dinpro-light pb-3">{leng[pagesidx].ltnaslov}</h3>
              <p>{leng[pagesidx].lttext}</p>
              <NavLink
                exact
                to={leng[pagesidx].rhlinkiug}
                className="text-decoration-none text-dark"
              >
                {leng[pagesidx].btnreadmore}
              </NavLink>
            </div>
          </div>
          <div className="section-kocka-ow2 d-flex align-items-center align-items-md-start order-0 order-sm-0 order-md-1 order-lg-1 col-lg-6 col-md-5 col-sm-7 text-white">
            <div className="card img-efect text-white rounded-0 border-0 mx-auto">
              <NavLink
                exact
                to={leng[pagesidx].rhlinkiug}
                className="text-decoration-none stretched-link"
              >
                <img src={iugFeatured} className="card-img rounded-0 border-0" alt="" />
              </NavLink>
              <div className="card-img-overlay d-flex align-items-center">
                <h5 className="card-title text-center d-flex justify-content-center mx-auto my-0">
                  <img src={IugLogo} className="card-img w-50" alt="" />
                </h5>
              </div>
            </div>
          </div>
        </Row>
      </Container>

      <Container className="fix-container-ow-oker px-0 d-none d-lg-block d-xl-block">
        <Row className="no-gutters">
          <div className="fix-div-ow-oker col-6 bg-doc-light-oker"></div>
        </Row>
      </Container>

      <ContainerAboveFooter
        lwtbgColor={'bg-doc-red'}
        lwttext1={leng[pagesidx].lwttext1}
        lwttext2={leng[pagesidx].lwttext2}
        lwttext3={leng[pagesidx].lwttext3}
        lwtlinkTo={leng[pagesidx].rhlinkco}
      />
    </Fragment>
  );
};

export default OurWork;
