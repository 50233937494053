import React from 'react';
import PropTypes from 'prop-types';
// Layout components
import Navbar from '../../components/Layout/Navbar';
// Main logo
import logoWhite from '../../assets/img/logo-white.svg';
/**
 * Header Component
 *
 * @augments {Component<Props, State>}
 */
const Header = ({ height, bgColor, bgImg, headerId, classa, children }) => {
  const style = {
    height: height,
    backgroundColor: bgColor,
    backgroundImage: `url(${!bgImg ? 'https://dummyimage.com/1920x600/888/fff' : bgImg})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center bottom',
    backgroundRepeat: 'no-repeat',
  };

  return (
    <header id={headerId} className={classa} style={style}>
      <Navbar brandname="DOC.ba" logo={logoWhite} />
      {children}
    </header>
  );
};

Header.defaultProps = {
  height: '100vh',
};

Header.propTypes = {
  /** Background image of header */
  bgImg: PropTypes.string,
  height: PropTypes.string.isRequired,
  bgColor: PropTypes.string,
  headerId: PropTypes.string,
  classa: PropTypes.string,
};

export default Header;
