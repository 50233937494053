/**
 * !!! PROJECTS DATA !!!
 */
// Logos images
import logoIUG from '../../assets/img/logos/logoIUG.svg';
import logoPOLIGLOT from '../../assets/img/logos/logoPOLIGLOT.svg';
import logoPOMOZIBA from '../../assets/img/logos/logoPOMOZIBA.svg';
import logoDRV from '../../assets/img/logos/logoDRV.svg';
import logoMETADATA from '../../assets/img/logos/logoMETADATA.svg';
import logoOCC from '../../assets/img/logos/logoOCC.svg';
import logoIPADRAM from '../../assets/img/logos/logoIPADRAM.svg';
import logoREPR from '../../assets/img/logos/logoREPR.svg';
import logoHRF from '../../assets/img/logos/logoHRF.svg';
import logoNOSA from '../../assets/img/logos/logoNOSA.svg';
import logoSTILLES from '../../assets/img/logos/logoSTILLES.svg';
import logoCOLLECTIVIBE from '../../assets/img/logos/logoCOLLECTIVIBE.png';
// Featured images
import featIUG from '../../assets/img/logos/featuredIUG.png';
import featPOLIGLOT from '../../assets/img/logos/featuredPOLIGLOT.png';
import featPOMOZIBA from '../../assets/img/logos/featuredPOMOZIBA.png';
import featDRV from '../../assets/img/logos/featuredDRV.jpg';
import featMETADATA from '../../assets/img/logos/featuredMETADATA.jpg';
import featOCC from '../../assets/img/logos/featuredOCC.jpg';
import featIPADRAM from '../../assets/img/logos/featuredIPADRAM.jpg';
import featREPR from '../../assets/img/logos/featuredREPR.png';
import featHRF from '../../assets/img/logos/featuredHRF.jpg';
import featNOSA from '../../assets/img/logos/featuredNOSA.jpg';
import featSTILLES from '../../assets/img/logos/featuredSTILLES.jpg';
import featCOLLECTIVIBE from '../../assets/img/logos/featuredCOLLECTIVIBE.jpg';
// Static header image
import bgImgMainIUG from '../../assets/img/iug_img/bg-header-IUG.png';
import bgImgMainPOLIGLOT from '../../assets/img/poliglot_img/bg-header-POLIGLOT.png';
import bgImgMainPOMOZIBA from '../../assets/img/pomoziba_img/bg-header-POMOZIBA.png';
import bgImgMainDRV from '../../assets/img/drv_img/bg-header-DRV.png';
import bgImgMainMETADATA from '../../assets/img/metadata_img/bg-header-METADATA.png';
import bgImgMainOCC from '../../assets/img/occ_img/bg-header-OCC.png';
import bgImgMainIPADRAM from '../../assets/img/ipadram_img/bg-header-IPADRAM.png';
import bgImgMainREPR from '../../assets/img/repr_img/bg-header-REPR.png';
import bgImgMainHRF from '../../assets/img/hrf_img/bg-header-HRF.png';
import bgImgMainNOSA from '../../assets/img/novosarajevo_img/bg-header-NOSA.png';
import bgImgMainSTILLES from '../../assets/img/stilles_img/bg-header-STILLES.png';
import bgImgMainCOLLECTIVIBE from '../../assets/img/collectivibe_img/bg-header-COLLECTIVIBE.png';
// IMAGES IUG
import kocka1IUG from '../../assets/img/iug_img/kocka1IUG.png';
import kocka2IUG from '../../assets/img/iug_img/kocka2IUG.png';
import kocka3IUG from '../../assets/img/iug_img/kocka3IUG.png';
import kocka4IUG from '../../assets/img/iug_img/kocka4IUG.png';
import kocka5IUG from '../../assets/img/iug_img/kocka5IUG.png';
// IMAGES POLIGLOT
import kocka1POLIGLOT from '../../assets/img/poliglot_img/kocka1POLIGLOT.png';
import kocka2POLIGLOT from '../../assets/img/poliglot_img/kocka2POLIGLOT.png';
import kocka3POLIGLOT from '../../assets/img/poliglot_img/kocka3POLIGLOT.png';
import kocka4POLIGLOT from '../../assets/img/poliglot_img/kocka4POLIGLOT.png';
import kocka5POLIGLOT from '../../assets/img/poliglot_img/kocka5POLIGLOT.png';
// IMAGES POMOZIBA
import kocka1POMOZIBA from '../../assets/img/pomoziba_img/kocka1POMOZIBA.png';
import kocka2POMOZIBA from '../../assets/img/pomoziba_img/kocka2POMOZIBA.png';
import kocka3POMOZIBA from '../../assets/img/pomoziba_img/kocka3POMOZIBA.png';
import kocka4POMOZIBA from '../../assets/img/pomoziba_img/kocka4POMOZIBA.png';
// IMAGES DRV
import kocka1DRV from '../../assets/img/drv_img/kocka1DRV.png';
import kocka2DRV from '../../assets/img/drv_img/kocka2DRV.png';
import kocka3DRV from '../../assets/img/drv_img/kocka3DRV.png';
// IMAGES METADATA
import kocka1METADATA from '../../assets/img/metadata_img/kocka1METADATA.png';
import kocka2METADATA from '../../assets/img/metadata_img/kocka2METADATA.png';
import kocka3METADATA from '../../assets/img/metadata_img/kocka3METADATA.png';
import kocka4METADATA from '../../assets/img/metadata_img/kocka4METADATA.png';
// IMAGES ONLINE CITY COUNCILE
import kocka1OCC from '../../assets/img/occ_img/kocka1OCC.png';
import kocka2OCC from '../../assets/img/occ_img/kocka2OCC.png';
// IMAGES IPADRAM
import kocka1IPADRAM from '../../assets/img/ipadram_img/kocka1IPADRAM.png';
import kocka2IPADRAM from '../../assets/img/ipadram_img/kocka2IPADRAM.png';
import kocka3IPADRAM from '../../assets/img/ipadram_img/kocka3IPADRAM.png';
// IMAGES REAL ESTATE PRICE REGISTRY
import kocka1REPR from '../../assets/img/repr_img/kocka1REPR.png';
import kocka2REPR from '../../assets/img/repr_img/kocka2REPR.png';
// IMAGES HEALTH REPORTING FORMS
import kocka1HRF from '../../assets/img/hrf_img/kocka1HRF.png';
import kocka2HRF from '../../assets/img/hrf_img/kocka2HRF.png';
import kocka3HRF from '../../assets/img/hrf_img/kocka3HRF.png';
import kocka4HRF from '../../assets/img/hrf_img/kocka4HRF.png';
// IMAGES NOVO SARAJEVO
import kocka1NOSA from '../../assets/img/novosarajevo_img/kocka1NOSA.png';
import kocka2NOSA from '../../assets/img/novosarajevo_img/kocka2NOSA.png';
// IMAGES STILLES
import kocka1STILLES from '../../assets/img/stilles_img/kocka1STILLES.png';
import kocka2STILLES from '../../assets/img/stilles_img/kocka2STILLES.png';
// IMAGES COLLECTIVIBE
import kocka1COLLECTIVIBE from '../../assets/img/collectivibe_img/kocka1COLLECTIVIBE.jpg';
import kocka2COLLECTIVIBE from '../../assets/img/collectivibe_img/kocka2COLLECTIVIBE.jpg';
// ?   poicon1PROJEKAT
// THE SOLUTION POLIGLOT
import poicon1POLIGLOT from '../../assets/icons/poicon14.svg';
import poicon2POLIGLOT from '../../assets/icons/poicon15.svg';
import poicon3POLIGLOT from '../../assets/icons/poicon16.svg';
import poicon4POLIGLOT from '../../assets/icons/poicon17.svg';
// THE SOLUTION IUG
import poicon1IUG from '../../assets/icons/poicon14.svg';
import poicon2IUG from '../../assets/icons/poicon15.svg';
import poicon3IUG from '../../assets/icons/poicon16.svg';
import poicon4IUG from '../../assets/icons/poicon17.svg';
// THE SOLUTION POMOZIBA
import poicon1POMOZIBA from '../../assets/icons/poicon18.svg';
import poicon2POMOZIBA from '../../assets/icons/poicon19.svg';
import poicon3POMOZIBA from '../../assets/icons/poicon6.svg';
import poicon4POMOZIBA from '../../assets/icons/poicon20.svg';
// THE SOLUTION NOVO SARAJEVO
import poicon1NOSA from '../../assets/icons/poicon6.svg';
import poicon2NOSA from '../../assets/icons/poicon21.svg';
import poicon3NOSA from '../../assets/icons/poicon22.svg';
import poicon4NOSA from '../../assets/icons/poicon23.svg';
// THE SOLUTION HEALTH REPORTING FORMS
import poicon1HRF from '../../assets/icons/poicon18.svg';
import poicon2HRF from '../../assets/icons/poicon24.svg';
import poicon3HRF from '../../assets/icons/poicon25.svg';
import poicon4HRF from '../../assets/icons/poicon26.svg';
// THE SOLUTION ONLINE CITY COUNCILE
import poicon1OCC from '../../assets/icons/poicon18.svg';
import poicon2OCC from '../../assets/icons/poicon27.svg';
import poicon3OCC from '../../assets/icons/poicon6.svg';
import poicon4OCC from '../../assets/icons/poicon23.svg';
// THE SOLUTION IPADRAM
import poicon1IPADRAM from '../../assets/icons/poicon28.svg';
import poicon2IPADRAM from '../../assets/icons/poicon29.svg';
import poicon3IPADRAM from '../../assets/icons/poicon11.svg';
import poicon4IPADRAM from '../../assets/icons/poicon30.svg';
// THE SOLUTION STILLES
import poicon1STILLES from '../../assets/icons/poicon18.svg';
import poicon2STILLES from '../../assets/icons/poicon31.svg';
import poicon3STILLES from '../../assets/icons/poicon6.svg';
import poicon4STILLES from '../../assets/icons/poicon23.svg';
// THE SOLUTION METADATA
import poicon1METADATA from '../../assets/icons/poicon18.svg';
import poicon2METADATA from '../../assets/icons/poicon32.svg';
import poicon3METADATA from '../../assets/icons/poicon33.svg';
import poicon4METADATA from '../../assets/icons/poicon23.svg';
// THE SOLUTION DRV
import poicon1DRV from '../../assets/icons/poicon19.svg';
import poicon2DRV from '../../assets/icons/poicon34.svg';
import poicon3DRV from '../../assets/icons/poicon6.svg';
import poicon4DRV from '../../assets/icons/poicon23.svg';
// THE SOLUTION REAL ESTATE PRICE REGISTRY
import poicon1REPR from '../../assets/icons/poicon18.svg';
import poicon2REPR from '../../assets/icons/poicon35.svg';
import poicon3REPR from '../../assets/icons/poicon6.svg';
import poicon4REPR from '../../assets/icons/poicon23.svg';
// ?   relevant1PROJEKT     i za        /dirproject/relevant1PROJEKT.svg
// RELEVANT LINKS default
import relevant1 from '../../assets/icons/relevant1.svg';
import relevant2 from '../../assets/icons/relevant2.svg';
import relevant3 from '../../assets/icons/relevant3.svg';
// CLIENTS TESTEMONIAL
import testemonialIUG from '../../assets/img/iug_img/testimonial.png';
import testemonialPOLIGLOT from '../../assets/img/poliglot_img/testimonial.png';
import testemonialPOMOZIBA from '../../assets/img/pomoziba_img/testimonial.png';

const projects = [
  // TODO - COLLECTIVIBE
  {
    id: 1,
    title: 'COLLECTIVIBE',
    subtitle: 'VIRTUAL EVENTS FOR GENUINE CONNECTIONS',
    urlTo: '/collectivibe',
    src: logoCOLLECTIVIBE,
    srcfi: featCOLLECTIVIBE,
    logow: 'w-75',
    bcHeadera: '#0083cb',
    headerimg: bgImgMainCOLLECTIVIBE,
    boxicont1: 'MJESECI NA PROJEKTU',
    boxicont2: 'LINIJA KODA',
    boxicont3: 'ŠOLJICA KAFE POPIJENO',
    boxicont4: 'GODINA POČETKA',
    // BUTTON
    btnscrolldown: 'POMAKNITE DOLE DA VIDITE VIŠE',
    btnseeprojpage: 'VIDI PROJEKAT',
    // META TAG i TITLE u head za React-Helmet
    rhdesc:
      'DOC.BA je tvrtka sa mladim stručnjacima iz različitih oblasti informacionih tehnologija, računarskih nauka, dizajna i digitalnog marketinga.',
    rhlocale: 'bs_BA',
    rhlinkco: '/bs/kontakt',
    // CASE STUDY
    casestadytitle: 'STUDIJA SLUČAJA',
    casestadybtn: 'btn-doc-hrf-secondary',
    casestadytext:
      'Ovaj projekt se temelji na poboljšanju postojeće platforme za virtualne događaje. Klijent je zahtijevao nadogradnju funkcionalnosti i redizajn.',
    // THE SOLUTION:
    solutionlight: 'FINALNO',
    solutionbold: 'RJEŠENJE',
    solutionbgcol: 'bg-doc-hrf-primary',
    solutiontext1:
      'Trenutno radimo na novoj verziji platforme za virtualne događaje. Ova verzija uključuje nadogradnju postojećih funkcionalnosti, dodavanje novih funkcionalnosti, kao i dizajniranje novog, modernog, i korisnički orijentiranog dizajna.',
    // PROJECT OVERVIEW
    potitle: 'PREGLED PROJEKTA',
    poicon1: poicon1REPR,
    poicon1txt: 'PROCJENA TEHNOLOGIJE',
    poicon2: poicon1IUG,
    poicon2txt: 'UI/UX DIZAJN',
    poicon3: poicon3REPR,
    poicon3txt: 'RAZVOJ APLIKACIJE',
    poicon4: poicon4REPR,
    poicon4txt: 'OBJAVA',
    // KOCKA
    kocka: [
      {
        img: kocka1COLLECTIVIBE,
        title: 'UI/UX DIZAJN',
        bgcol: 'bg-doc-hrf-secondary',
        text1:
          'Novi dizajn prilagođen je jednostavnoj i intuitivnoj upotrebi korisnika koji žele prisustvovati virtualnim događajima i na kraju postati dio virtualne zajednice. Moramo omogućiti vlasniku događaja da prilagodi vizualni identite, ali da i dalje da bude usklađeno sa cijelom platformom.',
      },
      {
        img: kocka2COLLECTIVIBE,
        title: 'RAZVOJ APLIKACIJE',
        bgcol: 'bg-doc-hrf-primary',
        text1:
          'Glavni dio aplikacije je platforma za hosting virtualnih događaja. Drugi važan dio je online zajednica što podrazumijeva implementaciju posebnih funkcionalnosti poput objavljivanja različitih vrsta sadržaja i interakcije članova. Slijedeći zahtjeve, naš razvojni tim isporučuje modernu, funkcionalnu aplikaciju, koristeći najnoviju tehnologiju i prateći najnovije trendove dizajna.',
      },
    ],
    // RELATIVE LINKS
    rellinklight: 'RELEVANTNI',
    rellinkbold: 'LINKOVI',
    rellinkbgcolor: 'bg-doc-hrf-secondary',
    // RELATIVE LINKS icons
    rellinktitle: 'COLLECTIVIBE',
    rellinkicon1: relevant1,
    rellinktext1: 'WEB STRANICA',
    rellinkhref1: 'https://collectivibe.com/',
    // CONTAINER ABOVE FOOTER
    lwttext1: 'POKRENIMO',
    lwttext2: '',
    lwttext3: 'SARADNJU',
  },
  // TODO - POMOZIBA
  {
    id: 2,
    title: 'POMOZI.BA',
    subtitle: 'ONLINE PLATFORMA ZA HUMANITARNE KAMPANJE I DONACIJE',
    urlTo: '/bs/pomoziba',
    src: logoPOMOZIBA,
    srcfi: featPOMOZIBA,
    logow: 'w-75',
    bcHeadera: '#fd5300',
    headerimg: bgImgMainPOMOZIBA,
    boxicont1: 'MJESECI NA PROJEKTU',
    boxicont2: 'LINIJA KODA',
    boxicont3: 'ŠOLJICA KAFE POPIJENO',
    boxicont4: 'PROJEKAT ZAVRŠEN',
    // BUTTON
    btnscrolldown: 'POMAKNITE DOLE DA VIDITE VIŠE',
    btnseeprojpage: 'VIDI PROJEKAT',
    // META TAG i TITLE u head za React-Helmet
    rhdesc:
      'DOC.BA je tvrtka sa mladim stručnjacima iz različitih oblasti informacionih tehnologija, računarskih nauka, dizajna i digitalnog marketinga.',
    rhlocale: 'bs_BA',
    rhlinkco: '/bs/kontakt',
    // CASE STUDY
    casestadytitle: 'STUDIJA SLUČAJA',
    casestadybtn: 'btn-doc-pomoziba-secondary',
    casestadytext:
      'Pomozi.ba je vodeća humanitarna organizacija koja pruža podršku i pomoć unesrećenim licima gotovo čitavu dekadu. Kroz razne aktivnosti, pomogli su i pomažu bezbroj ljudi u raznim životnim nedaćama. Organizacija ogroman broj upita za pomoć dobiva online, te su time prepoznali bitnost i efikasnost ulaganja u IT, s ciljem uštede vremena, optimizacije radnih procesa, što u konačnici otvara prostor da se pomogne većem broju ljudi.',
    // THE SOLUTION:
    solutionlight: 'FINALNO',
    solutionbold: 'RJEŠENJE',
    solutionbgcol: 'bg-doc-pomoziba-primary',
    solutiontext1:
      'Analiziranjem klijenta i tržišnih potreba, pristupili smo projektu izrade i razvoja regionalne platforme za humanitarne kampanje, s ciljem maksimizacije broja upita i slučajeva koje udruženje pomozi.ba može obraditi. Glavni cilj je bio olakšati način na koji unesrećeni ljudi mogu aplicirati za pomoć, te generisati potrebni iznos novca.',
    solutiontext2:
      'Također smo razvili i integrisali automatizovane Chat Bot sekvence, s ciljem optimizacije enormno visokog broja upita koje udruženje dobije na dnevnoj bazi. Automatizovali smo veliki dio najčešće traženih informacija, te smo time kreirali više vremena njihovim administratorima u obradi svih zahtjeva i upita.',
    solutiontext3: '',
    // PROJECT OVERVIEW
    potitle: 'PREGLED PROJEKTA',
    poicon1: poicon1POMOZIBA,
    poicon1txt: 'PROCJENA TEHNOLOGIJE',
    poicon2: poicon2POMOZIBA,
    poicon2txt: 'ANALIZA I DIZAJN APLIKACIJE',
    poicon3: poicon3POMOZIBA,
    poicon3txt: 'RAZVOJ',
    poicon4: poicon4POMOZIBA,
    poicon4txt: 'CHAT BOT AUTOMATIZACIJA',
    // KOCKA
    kocka: [
      {
        img: kocka1POMOZIBA,
        title: 'PROCJENA TEHNOLOGIJE',
        bgcol: 'bg-doc-pomoziba-secondary',
        text1:
          'Kompletan web portal je zasnovan na Node.js platformi, što omogućava arhitekturi platforme da brzo i stabilno obradi jako velik broj korisnika. Razmatrajući činjenicu da je web platforma zasnovana na online donacijama i kampanjama, odlučili smo se za Adonis.js MVC back-end framework, koji je zadovoljio sve segmente projekta po dizajnu.',
      },
      {
        img: kocka2POMOZIBA,
        title: 'ANALIZA I DIZAJN APLIKACIJE',
        bgcol: 'bg-doc-pomoziba-primary',
        text1:
          'S obzirom na činjenicu da klijent nije posjedovao platformu za podnošenje zahtjeva za pomoć, odrađena je detaljna analiza i optimizacija upita krajnjih korisnika. Finalni izgled i dizajn su prije svega prilagođeni jednostavnosti korištenja i intuitivnosti od strane korisnika pri pokretanju humanitarnih kampanja, kao i pri doniranju za iste.',
      },
      {
        img: kocka3POMOZIBA,
        title: 'RAZVOJ',
        bgcol: 'bg-doc-pomoziba-secondary',
        text1:
          'Glavni dio aplikacije jeste platforma za postavljanje humanitarnih kampanja, s ciljem prikupljanja donacija. Iz ovog razloga, aplikacija sadrži funkcionalnost online plaćanja, koju prate visoke i ozbiljne sigurnosne mjere, vođene najmodernijim standardima. Ručno pregledavanje i odobrenje svake pojedinačne kampanje je obavezno, te je iz sigurnosnih razloga ovaj aspekt platforme ostavljen neautomatizovan.',
      },
      {
        img: kocka4POMOZIBA,
        title: 'CHAT BOT AUTOMATIZACIJA',
        bgcol: 'bg-doc-pomoziba-primary',
        text1:
          'S obzirom na činjenicu da je udruženje pomoziba najveća humanitarna organizacija u BiH, kao i jedna od najvećih u regionu, broj upita koje obrađuju na dnevnoj bazi je veoma visok. S najmodernijim tehnologijama Chat Bot automatizacije, kreirali smo i integrisali automatske poruke s relevantnim informacijama. Prednosti su višestruke, od pružanja korisničke podrške 24/7, do mogućnosti segmentiranja posjetilaca u razne kategorije, pa sve do uštede vremena i ljudskih resursa u obradi svih upita.',
      },
    ],
    // RELATIVE LINKS
    rellinklight: 'RELEVANTNI',
    rellinkbold: 'LINKOVI',
    rellinkbgcolor: 'bg-doc-pomoziba-secondary',
    // RELATIVE LINKS icons
    rellinktitle: 'POMOZI.BA',
    rellinkicon1: relevant1,
    rellinktext1: 'WEB STRANICA',
    rellinkhref1: 'https://www.pomozi.ba/',
    rellinkicon2: relevant2,
    rellinktext2: 'FACEBOOK',
    rellinkhref2: 'https://www.facebook.com/pomozi.ba/',
    rellinkicon3: relevant3,
    rellinktext3: 'INSTAGRAM',
    rellinkhref3: 'https://www.instagram.com/pomozi.ba/',
    // CLIENTS TESTEMONIAL
    testemonialh4: 'SVJEDOČANSTVO KLIJENTA',
    testemonialimg: testemonialPOMOZIBA,
    testemonialtxt:
      'Upravljanje jednom od najvećih humanitarnih organizacija u regionu iziskuje visoke količine vremena, te je u konačnici jako stresan posao. DOC.ba ekipa nam je omogućila da automatizujemo ogroman broj upita koje dobijamo, te je razvila platformu za online kampanje i donacije koja nam omogućava da pomognemo većem broju ljudi, s manje uloženog vremena, truda i energije.',
    testemonialstr: 'Elvir K.,',
    testemonialem: 'osnivač POMOZI.ba',
    // CONTAINER ABOVE FOOTER
    lwttext1: 'POKRENIMO',
    lwttext2: '',
    lwttext3: 'SARADNJU',
  },
  // TODO - REAL ESTATE PRICE REGISTRY
  {
    id: 3,
    title: 'REGISTAR CIJENA NEKRETNINA FBIH',
    subtitle: 'JAVNI PRISTUP PODACIMA',
    urlTo: '/bs/registar-cijena-nekretnina',
    src: logoREPR,
    srcfi: featREPR,
    logow: 'w-50',
    bcHeadera: '#d9391e',
    headerimg: bgImgMainREPR,
    boxicont1: 'UKLJUČENE FEDERALNE INSTITUCIJE',
    boxicont2: 'UKLJUČENE LOKALNE ADMINISTRACIJE',
    boxicont3: 'TIPOVA PODATAKA DOSTUPNO',
    boxicont4: 'PROJEKAT ZAVRŠEN',
    // BUTTON
    btnscrolldown: 'POMAKNITE DOLE DA VIDITE VIŠE',
    btnseeprojpage: 'VIDI PROJEKAT',
    // META TAG i TITLE u head za React-Helmet
    rhdesc:
      'DOC.BA je tvrtka sa mladim stručnjacima iz različitih oblasti informacionih tehnologija, računarskih nauka, dizajna i digitalnog marketinga.',
    rhlocale: 'bs_BA',
    rhlinkco: '/bs/kontakt',
    // CASE STUDY
    casestadytitle: 'STUDIJA SLUČAJA',
    casestadybtn: 'btn-doc-repr-secondary',
    casestadytext:
      'Prezentacija javnih podataka Registra Cijena Nekretnina FBIH, rezultat je saradnje između nekoliko institucija, Federalne geodetske uprave, Federalnog ministarstva finansija, Porezne uprave FBIH, kao i lokalnih samoupravnih jedinica u FBIH, na implementaciji aktivnosti na CILAP projektu. Projekat "Razvoj kapaciteta za poboljšanje upravljanja zemljištem i postupaka u Bosni i Hercegovini" je dugoročni projekat čija je svrha izgradnja kapaciteta kao i prenosa znanja, sve s ciljem povećanja efikasnosti i pouzdanosti procesa zemljišne administracije u BiH. ',
    // THE SOLUTION:
    solutionlight: 'FINALNO',
    solutionbold: 'RJEŠENJE',
    solutionbgcol: 'bg-doc-repr-primary',
    solutiontext1:
      'Svrha uspostavljanja i održavanja centralnog registra cijena nekretnina FBIH je planiranje cjenovnog nadzora kao i analize tržišta nekretnina, te da pruži informacije svim učesnicima i investitorima tržišta nekretnina u FBIH. Do sada su u sistem uključene 63 zainteresovane lokalne samoupravne jedinice i svi porezni uredi u FBIH.',
    // PROJECT OVERVIEW
    potitle: 'PREGLED PROJEKTA',
    poicon1: poicon1REPR,
    poicon1txt: 'PROCJENA TEHNOLOGIJE',
    poicon2: poicon2REPR,
    poicon2txt: 'RAZLIČITI TIPOVI PODATAKA',
    poicon3: poicon3REPR,
    poicon3txt: 'RAZVOJ APLIKACIJE',
    poicon4: poicon4REPR,
    poicon4txt: 'PRIMJENA',
    // KOCKA
    kocka: [
      {
        img: kocka1REPR,
        title: 'PRIKAZ TRI TIPA PODATAKA',
        bgcol: 'bg-doc-repr-secondary',
        text1:
          'Prikazivanje različitih vrsta podataka: U svrhu transparentnosti i povećanja sigurnosti tržišta nekretnina, dostupni su sljedeći podaci Registra cijena nekretnina: vrsta i podvrsta nekretnina u prometu; približno mjesto nekretnine kojom se trguje (pin na karti); datum zaključenja ugovora o prodaji nekretnina; područje nekretnina u prometu; ukupna cijena nekretnine po ugovoru; podatke o tome je li u predmetnom ugovoru bilo više nekretnina i vrsta.',
      },
      {
        img: kocka2REPR,
        title: 'RAZVOJ APLIKACIJE',
        bgcol: 'bg-doc-repr-primary',
        text1:
          'Ova aplikacija sadrži interaktivnu mapu, napredne opcije i filtere pretraživanja, a sve za lakše korištenje od strane civilnog građanstva kao i institucija. Preglednik cijena nekretnina FBIH možete pokrenuti s Vašeg tableta ili mobilnog uređaja, kompjutera, kao i drugih tipova uređaja, te je i sadržaj prilagođen prikazu na svim raspoloživim tipovima uređaja, kao i svim direktivama i ISO standardima. Pokriva funkcionalnosti kao što su napredna pretraga, pregled mape, višejezičnost, upravljanje meta podacima, upravljanje korisnicima i još mnogo toga. Prije implementacije vizualnog prikaza aplikacije, istražili smo odnos boja koje su adekvatne za duže radne sesije na kompjuteru, kako bi upotreba krajnjim korisnicima bila dodatno olakšana. ',
      },
    ],
    // RELATIVE LINKS
    rellinklight: 'RELEVANTNI',
    rellinkbold: 'LINKOVI',
    rellinkbgcolor: 'bg-doc-repr-secondary',
    // RELATIVE LINKS icons
    rellinktitle: 'REGISTAR CIJENA NEKRETNINA',
    rellinkicon1: relevant1,
    rellinktext1: 'WEB STRANICA',
    rellinkhref1: 'https://katastar.ba/rcn',
    // CONTAINER ABOVE FOOTER
    lwttext1: 'POKRENIMO',
    lwttext2: '',
    lwttext3: 'SARADNJU',
  },
  // TODO - METADATA CATALOGUE
  {
    id: 4,
    title: 'METADATA',
    subtitle: 'KATALOG',
    urlTo: '/bs/katalog-meta-podataka',
    src: logoMETADATA,
    srcfi: featMETADATA,
    logow: 'w-50',
    bcHeadera: '#2c4251',
    headerimg: bgImgMainMETADATA,
    boxicont1: 'MJESECI NA PROJEKTU',
    boxicont2: 'LINIJA KODA',
    boxicont3: 'ŠOLJICA KAFE POPIJENO',
    boxicont4: 'PROJEKAT ZAVRŠEN',
    // BUTTON
    btnscrolldown: 'POMAKNITE DOLE DA VIDITE VIŠE',
    btnseeprojpage: 'VIDI PROJEKAT',
    // META TAG i TITLE u head za React-Helmet
    rhdesc:
      'DOC.BA je tvrtka sa mladim stručnjacima iz različitih oblasti informacionih tehnologija, računarskih nauka, dizajna i digitalnog marketinga.',
    rhlocale: 'bs_BA',
    rhlinkco: '/bs/kontakt',
    // CASE STUDY
    casestadytitle: 'STUDIJA SLUČAJA',
    casestadybtn: 'btn-doc-metadata-plava',
    casestadytext:
      'Metadata katalog je GIS projekat pokrenut od strane Federalne geodetske uprave. Cilj razvoja metadata profila kao i sistema upravljanja meta podacima je da uspostavi prostorne podatke infrastrukture u FBIH. To je prva pristupna tačka za infrastrukturu prostornih podataka i očekuje se da će biti sastavni dio budućeg geoportala.',
    // THE SOLUTION:
    solutionlight: 'FINALNO',
    solutionbold: 'RJEŠENJE',
    solutionbgcol: 'bg-doc-metadata-siva',
    solutiontext1:
      'U skladu s potrebama i standardima klijenta koji su se morali pratiti u implementaciji projekta, kreirali smo sistem za dodavanje, održavanje, traženje, validaciju kao i "uvoz/izvoz" meta podataka. Sistem je u potpunosti u skladu s INSPIRE direktivom 2007/2/EZ, kao i ISO 19115-1:2014 standardom. Naš zadatak je bio da postignemo sve funkcionalne i ne-funkcionalne zahtjeve ovog GIS projekta.',
    // PROJECT OVERVIEW
    potitle: 'PREGLED PROJEKTA',
    poicon1: poicon1METADATA,
    poicon1txt: 'ANALIZA I PROCJENA TEHNOLOGIJE PRIKUPLJANJA PODATAKA',
    poicon2: poicon2METADATA,
    poicon2txt: 'DIZAJN I RAZVOJ APLIKACIJE',
    poicon3: poicon3METADATA,
    poicon3txt: 'IMPLEMENTACIJA PROFILA META PODATAKA',
    poicon4: poicon4METADATA,
    poicon4txt: 'POKRETANJE PRODUKCIJE I OBUKA',
    // KOCKA
    kocka: [
      {
        img: kocka1METADATA,
        title: 'PROCJENA TEHNOLOGIJE',
        bgcol: 'bg-doc-metadata-plava',
        text1:
          '"The Metadata Catalog" je visoko specijalizirani softver sa specifičnim zahtjevima koji traže detaljnu analizu. Nakon analize, izbor tehnologije je pao na GeoNetwork, katalošku aplikaciju za upravljanje prostorne referencirane izvore. Ovaj alat daje moćne funkcionalnosti za uređivanje i pretragu meta podataka, kao i interaktivni prikaz sa mapama na webu.',
      },
      {
        img: kocka2METADATA,
        title: 'DIZAJN I RAZVOJ APLIKACIJE',
        bgcol: 'bg-doc-metadata-siva',
        text1:
          'Bilo je potrebno više mjeseci da se razvije ovaj kompleksni sistem, koji je ujedno morao biti u skladu s INSPIRE direktivom, kao i pratećim ISO standardom. Pokrio je funkcionalnosti kao što su napredna pretraga, pregled mape, višejezičnost, upravljanje meta podacima i korisnicima kao i mnoge druge. ',
      },
      {
        img: kocka3METADATA,
        title: 'IMPLEMENTACIJA METADATA PROFILA U RAZVOJU SOFTVERA',
        bgcol: 'bg-doc-metadata-plava',
        text1:
          'Profil metapodataka (model) za skupove prostornih podataka/nizova prostornih podataka i usluga prostornih podataka infrastrukture prostornih podataka odgovara temama definiranim u Prilozima I. II. i III. direktive INSPIRE 2007/2/EC. Zbog specifičnog područja za koje je stvoren katalog metapodataka, profil metapodataka trebao je provedbu dodatne teme.',
      },
      {
        img: kocka4METADATA,
        title: 'POKRETANJE PRODUKCIJE I OBUKA',
        bgcol: 'bg-doc-metadata-siva',
        text1:
          'Ovakav sistem sa svojim nefunkcionalnim zahtjevima podrazumijevao je specifičnu proizvodnu arhitekturu. Morali smo koristiti nekoliko tehnologija: web-provider Nginx za povratni proxy i balansiranje opterećenja, Apache Tomcat server, PostgreSQL sustav za upravljanje relacijskim bazama podataka i Windows Server 2016. Uspješno su održane radionice s korisnicima koji će biti najviše uključeni u upravljanje metapodacima.',
      },
    ],
    // RELATIVE LINKS
    rellinklight: 'RELEVANTNI',
    rellinkbold: 'LINKOVI',
    rellinkbgcolor: 'bg-doc-metadata-plava',
    // RELATIVE LINKS icons
    rellinktitle: 'KATALOG META PODATAKA',
    rellinkicon1: relevant1,
    rellinktext1: 'WEB STRANICA',
    rellinkhref1: 'https://katastar.ba/',
    // CONTAINER ABOVE FOOTER
    lwttext1: 'POKRENIMO',
    lwttext2: '',
    lwttext3: 'SARADNJU',
  },
  // TODO - DRV
  {
    id: 5,
    title: 'DRV',
    subtitle: 'DIGITALNI RED VOŽNJE',
    urlTo: '/bs/drv',
    src: logoDRV,
    srcfi: featDRV,
    logow: 'w-75',
    bcHeadera: '#0092d0',
    headerimg: bgImgMainDRV,
    boxicont1: 'MJESECI NA PROJEKTU',
    boxicont2: 'LINIJA KODA',
    boxicont3: 'ŠOLJICA KAFE POPIJENO',
    boxicont4: 'PROJEKAT ZAVRŠEN',
    // BUTTON
    btnscrolldown: 'POMAKNITE DOLE DA VIDITE VIŠE',
    btnseeprojpage: 'VIDI PROJEKAT',
    // META TAG i TITLE u head za React-Helmet
    rhdesc:
      'DOC.BA je tvrtka sa mladim stručnjacima iz različitih oblasti informacionih tehnologija, računarskih nauka, dizajna i digitalnog marketinga.',
    rhlocale: 'bs_BA',
    rhlinkco: '/bs/kontakt',
    // CASE STUDY
    casestadytitle: 'STUDIJA SLUČAJA',
    casestadybtn: 'btn-doc-drv-purple',
    casestadytext:
      'Projekt se temelji na projektu TISAR. Glavni cilj projekta TISAR je implementacija ICT platforme koja povezuje podatke o javnom prevozu i planiranju putovanja. Ovaj sistem rješava nedostatak sistema planiranja putovanja koji koristi multimodalni prijevoz (prebacivanje između različitih načina prijevoza od početne do odredišne točke).',
    // THE SOLUTION:
    solutionlight: 'FINALNO',
    solutionbold: 'RJEŠENJE',
    solutionbgcol: 'bg-doc-drv-plava',
    solutiontext1:
      'Digitalni vozni red (DRV) platforma je za centralizirano upravljanje i raspored praćenja jednog prijevoznika. Najveća prednost DRV platforme je interoperabilnost s drugim sustavima kao što su Google Maps, OpenStreetMap, Tisar i mnogi drugi. DRV omogućava agencijama da jednim klikom osvježe podatke o vožnji na cijelom Internetu u sekundi, poboljšaju njihovu vidljivost na svjetski poznatim uslugama kao i direktne objave voznog reda s DRV-a na svojoj web stranici. Ti podaci uključuju prikaz rute i multimodalno planiranje GIS-a.',
    // PROJECT OVERVIEW
    potitle: 'PREGLED PROJEKTA',
    poicon1: poicon1DRV,
    poicon1txt: 'ANALIZA I PRIKUPLJANJE PODATAKA',
    poicon2: poicon2DRV,
    poicon2txt: 'KREIRANJE GTFS',
    poicon3: poicon3DRV,
    poicon3txt: 'RAZVOJ SOFTVERA',
    poicon4: poicon4DRV,
    poicon4txt: 'PRIMJENA',
    // KOCKA
    kocka: [
      {
        img: kocka1DRV,
        title: 'ANALIZA I PRIKUPLJANJE PODATAKA',
        bgcol: 'bg-doc-drv-purple',
        text1:
          'Jedan od glavnih izazova na projektu je bilo odsustvo kvalitetnih podataka. Postojeći podaci nisu bili prikladni za obradu (necentralizovani, neorganizovani, zastarjeli), niti su pokrivali sva potrebna područja. Iz ovog razloga, sprovedena je detaljna analiza s ciljem određivanja modela tačnih podataka na osnovu kojih će se podaci ubacivati u bazu.',
      },
      {
        img: kocka2DRV,
        title: 'KREIRANJE GTFS',
        bgcol: 'bg-doc-drv-plava',
        text1:
          'GTFS je definicija standardizovanog formata za vremenske tabele i prostorno orijetnisane podatke vezane za usluge javnog prevoza. Omogućava operaterima javnog prevoza da objavljuju transportne informacije kao i developerima da koriste te podatke u kreiranju aplikacija. Kreiranje nosećeg GTFS-a adrijatičkog regiona i pratećeg internacionalnog saobraćaja je povezalo sva prikupljena vremena vožnji kao i prostornih podataka.',
      },
      {
        img: kocka3DRV,
        title: 'RAZVOJ SOFTVERA',
        bgcol: 'bg-doc-drv-purple',
        text1:
          'DRV je potpuno integrisan u rješenja ponuđena od strane Google servisa, kroz sve njihove usluge na svim platformama. Transportne rute su digitalizovane na vektorskoj bazi, prikazujući detaljnu rutu. Aplikacija dozvoljava da analizirate Vaš kompletan put od početka do kraja, kao i da identifikujete korisničku lokaciju (GPS).',
      },
    ],
    // RELATIVE LINKS
    rellinklight: 'RELEVANTNI',
    rellinkbold: 'LINKOVI',
    rellinkbgcolor: 'bg-doc-drv-plava',
    // RELATIVE LINKS icons
    rellinktitle: 'DRV',
    rellinkicon1: relevant1,
    rellinktext1: 'WEB STRANICA',
    rellinkhref1: 'https://drv.doc.ba/',
    // CONTAINER ABOVE FOOTER
    lwttext1: 'POKRENIMO',
    lwttext2: '',
    lwttext3: 'SARADNJU',
  },
  // TODO - ONLINE CITY COUNCILE
  {
    id: 6,
    title: 'ONLINE GRADSKO VIJEĆE',
    subtitle: 'GRAD SARAJEVO',
    urlTo: '/bs/online-gradsko-vijeće',
    src: logoOCC,
    srcfi: featOCC,
    logow: 'w-50',
    bcHeadera: '#990000',
    headerimg: bgImgMainOCC,
    boxicont1: 'GLAVNA AŽURIRANJA SOFTVERA',
    boxicont2: 'PRILAGOĐENIH TIPOVA OBJAVA',
    boxicont3: 'AKTIVNIH KORISNIKA',
    boxicont4: 'PROJEKAT ZAVRŠEN',
    // BUTTON
    btnscrolldown: 'POMAKNITE DOLE DA VIDITE VIŠE',
    btnseeprojpage: 'VIDI PROJEKAT',
    // META TAG i TITLE u head za React-Helmet
    rhdesc:
      'DOC.BA je tvrtka sa mladim stručnjacima iz različitih oblasti informacionih tehnologija, računarskih nauka, dizajna i digitalnog marketinga.',
    rhlocale: 'bs_BA',
    rhlinkco: '/bs/kontakt',
    // CASE STUDY
    casestadytitle: 'STUDIJA SLUČAJA',
    casestadybtn: 'btn-doc-occ-secondary text-white',
    casestadytext:
      'Službenici gradskog vijeća proveli su dosta vremena isporučujući printani materijal za sjednice vijeća. Digitalno dostavljanje materijala uveliko je olakšalo i ubrzalo poslovne procese. Također je smanjilo potrošnju papira, što je pozitivan utjecaj na životnu sredinu.',
    // THE SOLUTION:
    solutionlight: 'FINALNO',
    solutionbold: 'RJEŠENJE',
    solutionbgcol: 'bg-doc-occ-primary',
    solutiontext1:
      'Službeni projekt elektroničke komunikacije za Gradsko vijeće Sarajevo uključuje pripremu aplikacije, implementaciju, testiranje i sve funkcionalnosti koje prate procese rada vijeća. Kako bi se pojednostavilo upravljanje svim materijalima i odnosima između različitih područja unutar rada gradskog vijeća, ova web aplikacija izgrađena je korištenjem WordPress CMS (Content Management System) platforme. Aplikacija sadrži brojne funkcionalnosti od kojih ćemo izdvojiti nekoliko: Dinamično povezivanje sadržaja, Izrada PDF izvještaja iz ulaznog materijala, Slanje SMS-ova i e-poruka.',
    // PROJECT OVERVIEW
    potitle: 'PREGLED PROJEKTA',
    poicon1: poicon1OCC,
    poicon1txt: 'PROCJENA TEHNOLOGIJE',
    poicon2: poicon2OCC,
    poicon2txt: 'POSEBNI TIPOVI OBJAVA I PREGLEDA',
    poicon3: poicon3OCC,
    poicon3txt: 'WEB DIZAJN I RAZVOJ',
    poicon4: poicon4OCC,
    poicon4txt: 'PRIMJENA ',
    // KOCKA
    kocka: [
      {
        img: kocka1OCC,
        title: 'PROCJENA TEHNOLOGIJE',
        bgcol: 'bg-doc-occ-secondary',
        text1:
          'Korištene tehnologije za izradu ove web aplikacije su izlistane za svaki sloj aplikacije. MySQL i "File sistem" se koriste kao podatkovni sloj, ili sloj za bazu podataka. Za sloj aplikacije, korišten je PHP, i na kraju, ali ne i najmanje bitno, tehnologije za prezentacijske slojeve, HTML 5, CSS3, JavaScript, jQuery.',
      },
      {
        img: kocka2OCC,
        title: 'POSEBNI TIPOVI OBJAVA I PREGLEDA',
        bgcol: 'bg-doc-occ-primary',
        text1:
          'Aplikacija posjeduje određen broj posebno prilagođenih objava koje su međusobno povezane različitim konekcijama. Ovi tipovi objava se koriste u vođenju raznih segmenata koji funkcionišu u sklopu gradskog vijeća, kao što su sastanci vijeća, javne nabavke itd. Svi tipovi objava su praćeni posebno prilagođenim prikazom istih.',
      },
    ],
    // RELATIVE LINKS
    rellinklight: 'RELEVANTNI',
    rellinkbold: 'LINKOVI',
    rellinkbgcolor: 'bg-doc-occ-secondary',
    // RELATIVE LINKS icons
    rellinktitle: 'ONLINE GRADSKO VIJEĆE',
    rellinkicon1: relevant1,
    rellinktext1: 'WEB STRANICA',
    rellinkhref1: 'https://gradskovijece.sarajevo.ba/',
    // CONTAINER ABOVE FOOTER
    lwttext1: 'POKRENIMO',
    lwttext2: '',
    lwttext3: 'SARADNJU',
  },
  // TODO - HEALTH REPORTING FORMS
  {
    id: 7,
    title: 'OBRASCI ZDRAVSTVENIH IZVJEŠTAJA',
    subtitle: 'ZAVOD ZA JAVNO ZDRAVSTVO FBIH',
    urlTo: '/bs/obrasci-zdravstvenih-izvještaja',
    src: logoHRF,
    srcfi: featHRF,
    logow: 'w-75',
    bcHeadera: '#0083cb',
    headerimg: bgImgMainHRF,
    boxicont1: 'MJESECI NA PROJEKTU',
    boxicont2: 'ANALIZIRANIH SETOVA PODATAKA',
    boxicont3: 'ŠOLJICA KAFE POPIJENO',
    boxicont4: 'PROJEKAT ZAVRŠEN',
    // BUTTON
    btnscrolldown: 'POMAKNITE DOLE DA VIDITE VIŠE',
    btnseeprojpage: 'VIDI PROJEKAT',
    // META TAG i TITLE u head za React-Helmet
    rhdesc:
      'DOC.BA je tvrtka sa mladim stručnjacima iz različitih oblasti informacionih tehnologija, računarskih nauka, dizajna i digitalnog marketinga.',
    rhlocale: 'bs_BA',
    rhlinkco: '/bs/kontakt',
    // CASE STUDY
    casestadytitle: 'STUDIJA SLUČAJA',
    casestadybtn: 'btn-doc-hrf-primary',
    casestadytext:
      'Sve javne i privatne zdravstvene institucije, kao i privatne prakse u Federaciji Bosne i Hercegovine, dužne su popunjavati i slati individualne registre bolesnika, unutar spektra medicinskih usluga koje pružaju, nadležnim Kantonalnim Institucijama zdravstva.',
    // THE SOLUTION:
    solutionlight: 'FINALNO',
    solutionbold: 'RJEŠENJE',
    solutionbgcol: 'bg-doc-hrf-secondary',
    solutiontext1:
      'U saradnji s našim parternom, dobili smo zadatak kreiranja softverskog rješenja koje ima za cilj da bude registar individualnih izvještaja bolesnika, kao i da ima mogućnost generisanja sažetih izvještaja zdravstva za potrebe Zavoda za Javno Zdravstvo FBIH. Kreirano je 20 individualnih formi i više od 30 formi namijenjenih za sažete izvještaje, te je u svaku uključen standard CRUD funkcionalnosti. S ciljem očuvanja prethodno unesenih podataka, primijenjena je funkcionalnost "import". Kao dodatak ovim funkcionalnostima, bilo je potrebna implementacija korisničkih uloga, sistema izvještaja, "import" funkcionalnost kroz ExceL tabele kao i mnoge druge funkcionalnosti.',
    // PROJECT OVERVIEW
    potitle: 'PREGLED PROJEKTA',
    poicon1: poicon1HRF,
    poicon1txt: 'PROCJENA TEHNOLOGIJE',
    poicon2: poicon2HRF,
    poicon2txt: 'SIGURNOST',
    poicon3: poicon3HRF,
    poicon3txt: 'INDIVIDUALNE I SABIRNE FORME',
    poicon4: poicon4HRF,
    poicon4txt: 'SISTEM IZVJEŠTAVANJA',
    // KOCKA
    kocka: [
      {
        img: kocka1HRF,
        title: 'PROCJENA TEHNOLOGIJE',
        bgcol: 'bg-doc-hrf-primary',
        text1:
          'S obzirom na specifične zahtjeve projekta, bilo je neophodno prvobitno pristupiti razvoju "baze podataka", te su iz ovog razloga korišteni MSSQL i ASP.NET. Microsoft SQL Server je sistem za upravljanje relacijskim bazama podataka, razvijen od strane Microsofta i ASP.NET proširuje .NET razvojnu platformu s alatima namijenjenim posebno za razvoj web aplikacija.',
      },
      {
        img: kocka2HRF,
        title: 'SIGURNOST',
        bgcol: 'bg-doc-hrf-secondary',
        text1:
          'Zbog visoke osjetljivosti podataka koji se unose u sistem, bilo je neophodno definisati vrhunske sigurnosne standarde, koji će zaštititi podatke od eventualnih krađa i napada. Sigurnost je implementirana na razne načine, kao što su klasično logovanje s kompleksnim šiframa, te implementacijom VPN sigurnosti.',
      },
      {
        img: kocka3HRF,
        title: 'INDIVIDUALNE I ZBIRNE FORME',
        bgcol: 'bg-doc-hrf-primary',
        text1:
          'Softver omogućava unificirani pogled na platformu, s najjednostavnijim mogućim unosom podataka u individualne forme od strane zdravstvenih institucija. Na osnovu ovih formi, sistem će kreirati registar iz kojeg korisnici mogu generisati izvještaje, kao i vršiti analize i preporuke u donošenju odluka za zdravstveni sektor.',
      },
      {
        img: kocka4HRF,
        title: 'SISTEM IZVJEŠTAVANJA',
        bgcol: 'bg-doc-hrf-secondary',
        text1:
          'Veoma važna funkcionalnost ovog softvera jeste generisanje izvještaja za svaku individualnu formu, kao i za vremenske okvire potrebne od strane korisnika. Ovi izvještaji se mogu generisati dinamično u tri različita formata, .xlsx, .docx i .pdf. Korisnicima je potrebno da pojednostavljeno mogu odabrati vremenski period za željeni izvještaj, kao i željeni format izvještaja.',
      },
    ],
    // RELATIVE LINKS
    rellinklight: 'RELEVANTNI',
    rellinkbold: 'LINKOVI',
    rellinkbgcolor: 'bg-doc-hrf-primary',
    // RELATIVE LINKS icons
    rellinktitle: 'OBRASCI ZDRAVSTVENIH IZVJEŠTAJA',
    rellinkicon1: relevant1,
    rellinktext1: 'WEB STRANICA',
    rellinkhref1: 'https://doc.ba/zdravstvo/index.html',
    // CONTAINER ABOVE FOOTER
    lwttext1: 'POKRENIMO',
    lwttext2: '',
    lwttext3: 'SARADNJU',
  },
  // TODO - IPADRAM
  {
    id: 8,
    title: 'IPA DRAM',
    subtitle: 'CIVILNA ZAŠTITA I MENADŽMENT PROCJENE RIZIKA',
    urlTo: '/bs/ipadram',
    src: logoIPADRAM,
    srcfi: featIPADRAM,
    logow: 'w-75',
    bcHeadera: '#eb262a',
    headerimg: bgImgMainIPADRAM,
    boxicont1: 'MJESECI NA PROJEKTU',
    boxicont2: 'ANALIZIRANIH SETOVA PODATAKA',
    boxicont3: 'ŠOLJICA KAFE POPIJENO',
    boxicont4: 'PROJEKAT ZAVRŠEN',
    // BUTTON
    btnscrolldown: 'POMAKNITE DOLE DA VIDITE VIŠE',
    btnseeprojpage: 'VIDI PROJEKAT',
    // META TAG i TITLE u head za React-Helmet
    rhdesc:
      'DOC.BA je tvrtka sa mladim stručnjacima iz različitih oblasti informacionih tehnologija, računarskih nauka, dizajna i digitalnog marketinga.',
    rhlocale: 'bs_BA',
    rhlinkco: '/bs/kontakt',
    // CASE STUDY
    casestadytitle: 'STUDIJA SLUČAJA',
    casestadybtn: 'btn-doc-ipadram-primary',
    casestadytext:
      'IPA DRAM projekat se bavi procjenom rizika s fokusom na poplave, požare i zemljotrese, te je unajmio DOC.ba kompaniju da prikupi primarne baze podataka zahtijevane od strane ERRA platforme. ERRA platforma je softver koji se koristi pri analizi navedenih rizika, međutim, kako bi platforma funkcionisala adekvatno, treba podatke iz više različitih državnih institucija. Pokriva Bosnu i Hercegovinu, Srbiju i Crnu Goru.',
    // THE SOLUTION:
    solutionlight: 'FINALNO',
    solutionbold: 'RJEŠENJE',
    solutionbgcol: 'bg-doc-ipadram-secondary',
    solutiontext1:
      'Našoj kompaniji pružene su kontaktne točke za svaku zemlju od kojih su potrebni podaci i popis prioritetnih skupova podataka. Metodologija prikupljanja podataka bila je pisanje pisama i održavanje sastanaka s agencijama koje proizvode podatke kako bi se pokušalo prikupiti 51 prioritetni skup podataka koji su potrebni projektu za sve zemlje. Dodatna složenost prisutna je u Bosni i Hercegovini gdje je potrebno prikupiti ove skupove podataka za dva entiteta i njihove odgovarajuće Agencije za civilnu zaštitu (CP).',
    // PROJECT OVERVIEW
    potitle: 'PREGLED PROJEKTA',
    poicon1: poicon1IPADRAM,
    poicon1txt: 'TEHNIČKA I PRAVNA ANALIZA',
    poicon2: poicon2IPADRAM,
    poicon2txt: 'TOK PODATAKA I PREPORUKE',
    poicon3: poicon3IPADRAM,
    poicon3txt: 'KREIRANJE IZVJEŠTAJA',
    poicon4: poicon4IPADRAM,
    poicon4txt: 'PRIKUPLJANJE JAVNIH PODATAKA',
    // KOCKA
    kocka: [
      {
        img: kocka1IPADRAM,
        title: 'TEHNIČKA I PRAVNA ANALIZA',
        bgcol: 'btn-doc-ipadram-primary',
        text1:
          'Pravna analiza je veoma važan korak ovog projekta, obzirom da je definisala način prikupljanja setova podataka. Setovi podataka su javno dostupni u najboljim slučajevima, ili visoko ograničeni u drugim slučajevima, zahtijevajući od korisnika podataka da objasni način korištenja, da plati naknadu, te se zabranjuje bilo kakvo dijeljenje podataka. Ponekad tehnička analiza nije potrebna, pogotovo za raster setove podataka dijeljenih preko WMS servisa, a koji imaju malo ili nimalo atributa. Ovo je bio zahtjevan zadatak pri obradi vektorskih podataka, obzirom da smo trebali uraditi evaluaciju tipa geometrije koja nam je potrebna, koja pravila i kvalitet topologije trebamo, te koji atributi u poređenju s onim što se pružalo.',
      },
      {
        img: kocka2IPADRAM,
        title: 'TOK PODATAKA I PREPORUKE',
        bgcol: 'bg-doc-ipadram-secondary',
        text1:
          'Zahvaljujući olakšavajućim okolnostima naše kompanije koja se nalazi u BiH i izvrsnoj lokalnoj podršci, održali smo produktivne sastanke i postigli dogovor o stvaranju protoka podataka i preporuka za BiH.',
      },
      {
        img: kocka3IPADRAM,
        title: 'PRIKUPLJANJE JAVNIH PODATAKA',
        bgcol: 'bg-doc-ipadram-primary',
        text1:
          'Budući da većina informacija nije bila dostupna, određeni podaci prikupljeni su na mreži. Prikupili smo statistiku stanovništva sa zvaničnih web lokacija statističkih institucija za BiH, Srbiju i Crnu Goru. Te su informacije bile javno dostupne i besplatne. Podaci o granicama općina, država itd; prikupljeni su korištenjem Open street map.',
      },
    ],
    // RELATIVE LINKS
    rellinklight: 'RELEVANTNI',
    rellinkbold: 'LINKOVI',
    rellinkbgcolor: 'bg-doc-ipadram-secondary',
    // RELATIVE LINKS icons
    rellinktitle: 'IPA DRAM',
    rellinkicon1: relevant1,
    rellinktext1: 'WEB STRANICA',
    rellinkhref1: 'http://erradram.mydewetra.org/apps/mydewetra/index_ipadram.html',
    // CONTAINER ABOVE FOOTER
    lwttext1: 'POKRENIMO',
    lwttext2: '',
    lwttext3: 'SARADNJU',
  },
  // TODO - NOVO SARAJEVO
  {
    id: 9,
    title: 'NOVO SARAJEVO',
    subtitle: 'SLUŽBENA STRANICA OPĆINE',
    urlTo: '/bs/novo-sarajevo',
    src: logoNOSA,
    srcfi: featNOSA,
    logow: 'w-50',
    bcHeadera: '#2d3b60',
    headerimg: bgImgMainNOSA,
    boxicont1: 'MJESECI NA PROJEKTU',
    boxicont2: 'UNEŠENIH OBJAVA',
    boxicont3: 'ŠOLJICA KAFE POPIJENO',
    boxicont4: 'PROJEKAT ZAVRŠEN',
    // BUTTON
    btnscrolldown: 'POMAKNITE DOLE DA VIDITE VIŠE',
    btnseeprojpage: 'VIDI PROJEKAT',
    // META TAG i TITLE u head za React-Helmet
    rhdesc:
      'DOC.BA je tvrtka sa mladim stručnjacima iz različitih oblasti informacionih tehnologija, računarskih nauka, dizajna i digitalnog marketinga.',
    rhlocale: 'bs_BA',
    rhlinkco: '/bs/kontakt',
    // CASE STUDY
    casestadytitle: 'STUDIJA SLUČAJA',
    casestadybtn: 'btn-doc-nosa-primary',
    casestadytext:
      'Teritorija opštine Novo Sarajevo zauzima centralni dio uže gradske jezgre, te je to administrativni, poslovni, kulturalni, edukacijski i trgovinski dio grada Sarajeva. Opština je ujedno i osnovna jedinica lokalne samouprave. Stara web stranica je zahtjevala osvježenje, kao i pojednostavljeno upravljanje istom. ',
    // THE SOLUTION:
    solutionlight: 'FINALNO',
    solutionbold: 'RJEŠENJE',
    solutionbgcol: 'bg-doc-nosa-secondary',
    solutiontext1:
      'Jedan od najtežih koraka u projektu je bila potpuna migracija sadržaja iz jednog CMS-a (Content Management System - sistem upravljanja sadržajem) u drugi. Ovo je bio istinski izazov, uzimajući u obzir preko 10.000 objava s mnogo pratećeg multimedijalnog sadržaja. Posebna pažnja je posvećena strukturiranju sadržaja, koje je moralo pratiti samo funkcionisanje općine, što nije bilo jednostavno. Glavna navigacija je morala biti strukturirana tako da posjetioci mogu jako brzo i jednostavno pronaći informacije koje trebaju, obzirom da je to primarna svrha web stranice.',
    // PROJECT OVERVIEW
    potitle: 'PREGLED PROJEKTA',
    poicon1: poicon1NOSA,
    poicon1txt: 'RAZVOJ WEB STRANICE',
    poicon2: poicon2NOSA,
    poicon2txt: 'POSTAVKE NOVE STRUKTURE',
    poicon3: poicon3NOSA,
    poicon3txt: 'MIGRACIJA SADRŽAJA',
    poicon4: poicon4NOSA,
    poicon4txt: 'PRIMJENA',
    // KOCKA
    kocka: [
      {
        img: kocka1NOSA,
        title: 'RAZVOJ WEB STRANICE',
        bgcol: 'bg-doc-nosa-primary',
        text1:
          'Logo je dizajniran u skladu s postojećim smjernicama brenda, poštujući oblik i boje inicijalnog dizajna. Koristeći moderne linije, oblike i tipografiju, a uzimajući u obzir iskoristivost u različitim materijalima, printanim i digitalnim, došli smo do veoma jednostavnog i jedinstvenog dizajna. On može biti iskorišten u raznovrsnom rasponu svih medijskih segmenata - od tradicionalnih reklamnih materijala do digitalnih medija.',
      },
      {
        img: kocka2NOSA,
        title: 'MIGRACIJA SADRŽAJA',
        bgcol: 'bg-doc-nosa-secondary',
        text1:
          'Migracija članaka iz Joomla CMS-a u novokreiranu stranicu je bila veoma važna i kompleksna stavka ovog projekta, rađena u kontroliranim uvjetima. Od krucijalne važnosti je bilo spriječiti gubitak bilo kojeg djela sadržaja, kao i strukturiranje objava na osnovu novih kategorija i strukture web stranice.',
      },
    ],
    // RELATIVE LINKS
    rellinklight: 'RELEVANTNI',
    rellinkbold: 'LINKOVI',
    rellinkbgcolor: 'bg-doc-nosa-primary',
    // RELATIVE LINKS icons
    rellinktitle: 'NOVO SARAJEVO',
    rellinkicon1: relevant1,
    rellinktext1: 'WEB STRANICA',
    rellinkhref1: 'https://novosarajevo.ba/',
    // CONTAINER ABOVE FOOTER
    lwttext1: 'POKRENIMO',
    lwttext2: '',
    lwttext3: 'SARADNJU',
  },
  // TODO - POLIGLOT
  {
    id: 10,
    title: 'POLIGLOT',
    subtitle: 'ŠKOLA ZA STRANE JEZIKE I INFORMATIKU',
    urlTo: '/bs/poliglot',
    src: logoPOLIGLOT,
    srcfi: featPOLIGLOT,
    logow: 'w-75',
    bcHeadera: '#1b3d68',
    headerimg: bgImgMainPOLIGLOT,
    boxicont1: 'DANA NA PROJEKTU',
    boxicont2: 'UTROŠENO SATI',
    boxicont3: 'ŠOLJICA KAFE POPIJENO',
    boxicont4: 'PROJEKAT ZAVRŠEN',
    // BUTTON
    btnscrolldown: 'POMAKNITE DOLE DA VIDITE VIŠE',
    btnseeprojpage: 'VIDI PROJEKAT',
    // META TAG i TITLE u head za React-Helmet
    rhdesc:
      'DOC.BA je tvrtka sa mladim stručnjacima iz različitih oblasti informacionih tehnologija, računarskih nauka, dizajna i digitalnog marketinga.',
    rhlocale: 'bs_BA',
    rhlinkco: '/bs/kontakt',
    // CASE STUDY
    casestadytitle: 'STUDIJA SLUČAJA',
    casestadybtn: 'btn-doc-poliglot-orange text-white',
    casestadytext:
      '"Škola za strane jezike i informatiku Poliglot" se nalazi u Sarajevu, te ima dugu historiju pružanja visoko kvalitetnih kurseva stranih jezika i informatike. Kroz godine su usvajali nove tehnologije s ciljem pružanja znanja u skladu s najmodernijim standardima tržišta. Naš tim je dobio zadatak da osvježi vizualni identitet, kao i da poboljša online prisustvo škole, kroz novu web stranicu i kontinuirane digitalne marketing kampanje.',
    // THE SOLUTION:
    solutionlight: 'FINALNO',
    solutionbold: 'RJEŠENJE',
    solutionbgcol: 'bg-doc-poliglot-plava',
    solutiontext1:
      'Kao što smo spomenuli prije, Poliglot ima dugu historiju visokih standarda u ponudi kurseva, kao i percepciji brenda. Imali smo važan zadatak da njihov brend uspješno uvedemo u novo desetljeće digitalnih tehnologija, te da predstavimo njihove usluge ciljanoj skupini, na najbolji mogući način.',
    solutiontext2:
      'Redizajnirali smo vizualne aspekte škole, imajući u vidu originalni vizualni identitet, te smo mu dali nov i moderan izgled. Po finaliziranju redizajna loga, razvili smo novu web stranicu u skladu s novim vizualnim aspektima. S obzirom na to da nam je korisničko iskustvo također bilo bitno, sav smo sadržaj napravili lako dostupnim i razumljivim. Na kraju, nastavili smo s našim angažmanom na društvenim mrežama, povećavajući u konačnici broj posjeta na njima, kao i na samoj web stranici.',
    solutiontext3: '',
    // PROJECT OVERVIEW
    potitle: 'PREGLED PROJEKTA',
    poicon1: poicon1POLIGLOT,
    poicon1txt: 'LOGO I STACIONARNI DIZAJN',
    poicon2: poicon2POLIGLOT,
    poicon2txt: 'DIZAJN I RAZVOJ WEB STRANICE',
    poicon3: poicon3POLIGLOT,
    poicon3txt: 'KAMPANJE NA DRUŠTVENIM MREŽAMA',
    poicon4: poicon4POLIGLOT,
    poicon4txt: 'HOSTING I ODRŽAVANJE',
    // KOCKA
    kocka: [
      {
        img: kocka1POLIGLOT,
        title: 'DIZAJN LOGA',
        bgcol: 'bg-doc-poliglot-orange',
        text1:
          'Prateći posljednje vizualne trendove i standarde, DOC.ba je osvježio i redizajnirao logo našeg dugogodišnjeg klijenta "Škola za strane jezike i informatiku Poliglot". Glavni cilj je bio ostati što bliže izvornom vizualnom identitetu, pritom ga čineći elegantnijim i modernijim.',
      },
      {
        img: kocka2POLIGLOT,
        title: 'DIZAJN BROŠURE',
        bgcol: 'bg-doc-poliglot-plava',
        text1:
          'U zavisnosti od vrste usluge ili proizvoda, različite promotivne aktivnosti, kao i ciljane skupine, imaće drugačije rezultate. Poliglot je prepoznao prednost kreiranja tradicionalnih papirnih brošura vezanih za jedan od njihovih projekata, a mi u DOC-u smo bili više nego sretni odgovoriti na njihove potrebe.',
      },
      {
        img: kocka3POLIGLOT,
        title: 'BILBORD DIZAJN',
        bgcol: 'bg-doc-poliglot-orange',
        text1:
          'Kao i s papirnim brošurama, bilbord je jedan od najčešće korištenih promotivnih metoda. Ispunili smo želje našeg klijenta za efikasnim i utjecajnim bilbordom, koji će zasigurno generisati nove upite. Ovo je ujedno bila i odlična prilika da predstavimo novi vizualni identitet kao i novi logo urađen za njih.',
      },
      {
        img: kocka4POLIGLOT,
        title: 'DIZAJN I RAZVOJ WEB STRANICE',
        bgcol: 'bg-doc-poliglot-plava',
        text1:
          'Kao i sa svim preostalim aspektima njihovog brenda, Poliglot je investirao vrijeme i resurse u kreiranje nove web stranice škole stranih jezika.',
        text2:
          'Glavni cilj ovog projekta bio je iskorištavanje najmodernijih tehnologija, kao i posljednjih vizualnih standarda, s ciljem pružanja poboljšanog iskustva korisnicima i posjetiocima web stranice Poliglota.',
        text3:
          'Na kraju, kreirali smo elegantnu i potpuno responzivnu web stranicu, prilagođeno olakšanoj upotrebi, sa svim pratećim informacijama o školi stranih jezika kao i njihovih usluga. ',
      },
      {
        img: kocka5POLIGLOT,
        title: 'KAMPANJE NA DRUŠTVENIM MREŽAMA',
        bgcol: 'bg-doc-poliglot-orange',
        text1:
          'Prošlo je već nekoliko godina otkako je Poliglot prepoznao činjenicu da su njihovi trenutni, kao i budući korisnici, itekako prisutni na društvenim mrežama. S obzirom na ovu činjenicu, već dugi niz godina sarađujemo na velikom broju odrađenih kampanja, koje uključuju Facebook, Instagram, kao i Google platformu.',
        text2:
          'Naš tim kreira kompletnu strategiju spomenutih kampanja, držeći u fokusu efikasnost i profitabilnost za naše klijente. Svaki aspekt online prisustva škole za strane jezike, pomno je planiran i prilagođen specifičnim potrebama ciljane skupine. Najveći dokaz ekonomske efikasnosti ovih kampanja, jeste činjenica da Poliglot iz godine u godinu ulaže sve veći broj resursa, u konačnici čineći alternativne načine promocije nepotrebnim.',
      },
    ],
    // RELATIVE LINKS
    rellinklight: 'RELEVANTNI',
    rellinkbold: 'LINKOVI',
    rellinkbgcolor: 'bg-doc-poliglot-plava',
    // RELATIVE LINKS icons
    rellinktitle: 'POLIGLOT',
    rellinkicon1: relevant1,
    rellinktext1: 'WEB STRANICA',
    rellinkhref1: 'https://www.poliglot.ba/',
    rellinkicon2: relevant2,
    rellinktext2: 'FACEBOOK',
    rellinkhref2: 'https://www.facebook.com/poliglot.ba/',
    rellinkicon3: relevant3,
    rellinktext3: 'INSTAGRAM',
    rellinkhref3: 'https://www.instagram.com/poliglot_1940/',
    // CLIENTS TESTEMONIAL
    testemonialh4: 'SVJEDOČANSTVO KLIJENTA',
    testemonialimg: testemonialPOLIGLOT,
    testemonialtxt:
      'Sarađujući s DOC.ba ekipom, najveća vrijednost koju dobivam je u tome da sve svoje IT probleme mogu riješiti sa samo jednim telefonskim pozivom. Bilo da se radi o e-mailovima škole, web stranici, ili možda digitalnim platformama, DOC.ba će riješiti sve Vaše probleme.',
    testemonialstr: 'Mirnesa Dž.,',
    testemonialem: 'menadžerica POLIGLOT-a',
    // CONTAINER ABOVE FOOTER
    lwttext1: 'POKRENIMO',
    lwttext2: '',
    lwttext3: 'SARADNJU',
  },
  // TODO - IUG
  {
    id: 11,
    title: 'IUG',
    subtitle: 'INTERNACIONALNI UNIVERZITET GORAŽDE',
    urlTo: '/bs/iug',
    src: logoIUG,
    srcfi: featIUG,
    logow: 'w-50',
    bcHeadera: '#1e376f',
    headerimg: bgImgMainIUG,
    boxicont1: 'DANA NA PROJEKTU',
    boxicont2: 'UTROŠENO SATI',
    boxicont3: 'ŠOLJICA KAFE POPIJENO',
    boxicont4: 'PROJEKAT ZAVRŠEN',
    // BUTTON
    btnscrolldown: 'POMAKNITE DOLE DA VIDITE VIŠE',
    btnseeprojpage: 'VIDI PROJEKAT',
    // META TAG i TITLE u head za React-Helmet
    rhdesc:
      'DOC.BA je tvrtka sa mladim stručnjacima iz različitih oblasti informacionih tehnologija, računarskih nauka, dizajna i digitalnog marketinga.',
    rhlocale: 'bs_BA',
    rhlinkco: '/bs/kontakt',
    // CASE STUDY
    casestadytitle: 'STUDIJA SLUČAJA',
    casestadybtn: 'btn-doc-iug-tirkiz',
    casestadytext:
      'IUG je skraćenica za Internacionalni Univerzitet u Goraždu, lociranom u Bosansko-Podrinjskom Kantonu u Bosni i Hercegovini. Klijent je prepoznao potrebe tržišta za adekvatnim online prisustvom kao i poboljšanjima u vizualnom identitetu brenda. S obzirom na to, naš zadatak je bio da dostavimo neophodna poboljšanja vizualnom identitetu i prisustvu brenda, te da poboljšamo broj posjeta i pregleda na društvenim mrežama.',
    // THE SOLUTION:
    solutionlight: 'FINALNO',
    solutionbold: 'RJEŠENJE',
    solutionbgcol: 'bg-doc-iug-plava',
    solutiontext1:
      'Na osnovu potreba klijenta, odradili smo istraživanje tržišta s ciljem identifikacije najnovijih trendova vezanih za edukacijsku/univerzitetsku nišu, te smo došli do optimalnog finalnog rješenja za klijenta. Koristili smo posljednje tehnologije u procesu, imajući u fokusu vizualni izgled kao i samu funkcionalnost u dostavljenim rješenjima.',
    solutiontext2:
      'Projekat je započet direktivom našem dizajn sektoru, s ciljem kreiranja novog vizualnog identiteta koji prati misiju i viziju IUG, kao i unaprijed definisane smjernice i boje. Definisanjem novog vizualnog identiteta, kreirali smo novu i modernu web stranicu, usko sarađujući s klijentom pri definisanju sadržaja, kao i poliranju izgleda i strukture stranice.',
    solutiontext3: '',
    // PROJECT OVERVIEW
    potitle: 'PREGLED PROJEKTA',
    poicon1: poicon1IUG,
    poicon1txt: 'LOGO I STACIONARNI DIZAJN',
    poicon2: poicon2IUG,
    poicon2txt: 'DIZAJN I RAZVOJ WEB STRANICE',
    poicon3: poicon3IUG,
    poicon3txt: 'KAMPANJE NA DRUŠTVENIM MREŽAMA',
    poicon4: poicon4IUG,
    poicon4txt: 'HOSTING I ODRŽAVANJE',
    // KOCKA
    kocka: [
      {
        img: kocka1IUG,
        title: 'DIZAJN LOGA',
        bgcol: 'bg-doc-iug-tirkiz',
        text1:
          'Novi logo je dizajniran u skladu s postojećim smjernicama brenda, poštujući oblik i boje inicijalnog dizajna. Koristeći moderne linije i oblike, zadržavajući iskoristivost u printanim i digitalnim medijima, došli smo do jednostavnog, ipak unikatnog dizajna.',
      },
      {
        img: kocka2IUG,
        title: 'DIZAJN VIZIT KARTICA',
        bgcol: 'bg-doc-iug-plava',
        text1:
          'Vizit kartice su neophodne za svaki biznis, te je samim time to bila jedna od inicijalnih stavki naše saradnje. Željeli su zamijeniti stare i dotrajale vizit kartice s novim i modernim, koje će biti u skladu s novim vizualnim identitetom univerziteta.',
      },
      {
        img: kocka3IUG,
        title: 'BILBORD DIZAJN',
        bgcol: 'bg-doc-iug-orange',
        text1:
          'Približavanje početka nove školske godine, potrebe klijenta se šire, te se javila potreba za bilbord plakatom. Koristeći novi vizualni identitet i sadržaj, klijentu smo dostavili "čist", lako čitljiv i upadljiv bilbord dizajn.',
      },
      {
        img: kocka4IUG,
        title: 'DIZAJN I RAZVOJ WEB STRANICE',
        bgcol: 'bg-doc-iug-plava',
        text1:
          'Želeći da imaju poboljšan izgled, kao i online prisustvo, te lakšu komunikaciju s potencijalnim studentima i javnosti u globalu, IUG je zahtijevao dizajn i razvoj nove web stranice, u skladu s novim vizualnim identitetom.',
        text2:
          'Prateći njihov zahtjev, naši doktori za razvoj web stranica su se posvetili proizvodnji moderne i funkcionalne web stranice, vodeći se posljednjim dizajnerskim trendovima.',
        text3:
          'Web stranica je u potpunosti responzivna i prilagođena za sve vrste uređaja i pretraživača.',
      },
      {
        img: kocka5IUG,
        title: 'KAMPANJE NA DRUŠTVENIM MREŽAMA',
        bgcol: 'bg-doc-iug-crvena',
        text1:
          'Naš sektor za digitalni marketing je razvio sveobuhvatnu kampanju za društvene mreže, s glavnim ciljem podizanja svijesti o univerzitetu, s fokusom na lokalni region. Kampanja je također bila način da se naglasi rebrending univerziteta, kao i da se osvježe sve društvene mreže i prateći sadržaj.',
        text2:
          'Platforme korištene u promotivnoj kampanji univerziteta su bile Facebook, Instagram i Google, s planom širenja iste na YouTube, po dostavljanju video reklame od strane klijenta.',
      },
    ],
    // RELATIVE LINKS
    rellinklight: 'RELEVANTNI',
    rellinkbold: 'LINKOVI',
    rellinkbgcolor: 'bg-doc-iug-plava',
    // RELATIVE LINKS icons
    rellinktitle: 'IUG',
    rellinkicon1: relevant1,
    rellinktext1: 'WEB STRANICA',
    rellinkhref1: 'https://www.iug.ba/',
    rellinkicon2: relevant2,
    rellinktext2: 'FACEBOOK',
    rellinkhref2: 'https://www.facebook.com/',
    rellinkicon3: relevant3,
    rellinktext3: 'INSTAGRAM',
    rellinkhref3: 'https://www.instagram.com/',
    // CLIENTS TESTEMONIAL
    testemonialh4: 'SVJEDOČANSTVO KLIJENTA',
    testemonialimg: testemonialIUG,
    testemonialtxt:
      'Prije naše saradnje sa DOC.ba ekipom, morali smo sarađivati s više različitih agencija i kompanija, a sve vezano za naše digitalno prisustvo. Sa DOC.ba, zadovoljili smo sve naše digitalne potrebe i to samo s jednom kompanijom. Pravovremene reakcije, najmodernije tehnologije, i personalizirani pristup našem univerzitetu je ono što DOC.ba ekipu odvaja od ostalih sličnih kompanija s kojima smo sarađivali u prošlosti.',
    testemonialstr: 'mr.sci. Elma B.,',
    testemonialem: 'osnivač IUG',
    // CONTAINER ABOVE FOOTER
    lwttext1: 'POKRENIMO',
    lwttext2: '',
    lwttext3: 'SARADNJU',
  },
  // TODO - STILLES INTERIORS
  {
    id: 12,
    title: 'STILLES INTERIJERI',
    subtitle: 'DIZAJN NAMJEŠTAJA',
    urlTo: '/bs/stilles',
    src: logoSTILLES,
    srcfi: featSTILLES,
    logow: 'w-75',
    bcHeadera: '#8c7c72',
    headerimg: bgImgMainSTILLES,
    boxicont1: 'MJESECI NA PROJEKTU',
    boxicont2: 'NAGATIVNE POVRATNE INFORMACIJE',
    boxicont3: 'ŠOLJICA KAFE POPIJENO',
    boxicont4: 'PROJEKAT ZAVRŠEN',
    // BUTTON
    btnscrolldown: 'POMAKNITE DOLE DA VIDITE VIŠE',
    btnseeprojpage: 'VIDI PROJEKAT',
    // META TAG i TITLE u head za React-Helmet
    rhdesc:
      'DOC.BA je tvrtka sa mladim stručnjacima iz različitih oblasti informacionih tehnologija, računarskih nauka, dizajna i digitalnog marketinga.',
    rhlocale: 'bs_BA',
    rhlinkco: '/bs/kontakt',
    // CASE STUDY
    casestadytitle: 'STUDIJA SLUČAJA',
    casestadybtn: 'btn-doc-stilles-primary',
    casestadytext:
      'Kompanija Stilles d.o.o. postoji na tržištu BiH od 2000. godine. Ima 28 zaposlenih, prodajni salon od 300m2 u Sarajevu i proizvodni pogon površine 1000m2 također u Sarajevu. Željeli su dostojnu prezentaciju svoje kompanije putem web stranice koja mora odražavati kreativnost i jednostavnost.',
    // THE SOLUTION:
    solutionlight: 'FINALNO',
    solutionbold: 'RJEŠENJE',
    solutionbgcol: 'bg-doc-stilles-secondary',
    solutiontext1:
      'Stilles kreativni tim unosi svoje znanje i iskustvo, maštu i inovaciju u svaki dizajn enterijera, držeći se osnovne ideje od samog početka do konačne realizacije. Iz tog razloga, naše rješenje je moralo biti i maštovito i inovativno. Stvorili smo ovu vrlo dinamičnu, a opet jednostavnu web stranicu sa svim važnim segmentima.',
    // PROJECT OVERVIEW
    potitle: 'PREGLED PROJEKTA',
    poicon1: poicon1STILLES,
    poicon1txt: 'RAZVOJ WEB STRANICE',
    poicon2: poicon2STILLES,
    poicon2txt: 'VIŠEJEZIČNA WEB STRANICA',
    poicon3: poicon3STILLES,
    poicon3txt: 'DINAMIČNI DIZAJN',
    poicon4: poicon4STILLES,
    poicon4txt: 'PRIMJENA',
    // KOCKA
    kocka: [
      {
        img: kocka1STILLES,
        title: 'RAZVOJ WEB STRANICE',
        bgcol: 'bg-doc-stilles-primary',
        text1:
          'Web stranica je trebala biti jednostavna za održavanje i dodavanje novog sadržaja. Tehnologije korištene za izradu ove web stranice navedene su za svaki aplikacijski sloj. Za sloj baze podataka koriste se MySQL i File sistem. Za aplikacijski sloj koristili smo PHP, a za prezentacijski sloj: HTML 5, CSS3, JavaScript, jQuery.',
      },
      {
        img: kocka2STILLES,
        title: 'VIŠEJEZIČNA WEB STRANICA',
        bgcol: 'bg-doc-stilles-secondary',
        text1:
          'Da bi pokrili odgovarajuća tržišta, klijenti često žele da njihove web stranice budu višejezične. To je slučaj i s ovom web stranicom koja ima implementirana dva jezika. Jezik je odabran jednostavnim klikom na odgovarajuću zastavu unutar navigacije.',
      },
    ],
    // RELATIVE LINKS
    rellinklight: 'RELEVANTNI',
    rellinkbold: 'LINKOVI',
    rellinkbgcolor: 'bg-doc-stilles-primary',
    // RELATIVE LINKS icons
    rellinktitle: 'STILLES INTERIJERI',
    rellinkicon1: relevant1,
    rellinktext1: 'WEB STRANICA',
    rellinkhref1: 'http://stillesenterijeri.com/',
    // CONTAINER ABOVE FOOTER
    lwttext1: 'POKRENIMO',
    lwttext2: '',
    lwttext3: 'SARADNJU',
  },
];

export default projects;
