import React, { Suspense, lazy } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
// Layout components
import ScrollToTop from './components/Layout/ScrollToTop';
import Footer from './components/Layout/Footer';
// Pages
import Home from './components/Pages/Home';
import About from './components/Pages/About';
import Services from './components/Pages/Services';
import OurWork from './components/Pages/OurWork';
import Contact from './components/Pages/Contact';
// Helmet za dinamicki title
import { Helmet } from 'react-helmet';
// Consumer za jezike ContextAPI
import ChangeLanguage from './components/Layout/ChangeLanguage';
// Pages Department
const GraphicDesign = lazy(() => import('./components/Pages/GraphicDesign'));
const DigitalMarketing = lazy(() => import('./components/Pages/DigitalMarketing'));
const SoftwareDevelopement = lazy(() => import('./components/Pages/SoftwareDevelopement'));
// Team Member Lazy Load
const NedimH = lazy(() => import('./components/Pages/TeamMembers/NedimH'));
const TarikR = lazy(() => import('./components/Pages/TeamMembers/TarikR'));
const AmelaF = lazy(() => import('./components/Pages/TeamMembers/AmelaF'));
const ErminR = lazy(() => import('./components/Pages/TeamMembers/ErminR'));
//const AlmirD = lazy(() => import('./components/Pages/TeamMembers/AlmirD'));
//const TarikT = lazy(() => import('./components/Pages/TeamMembers/TarikT'));
const SemirM = lazy(() => import('./components/Pages/TeamMembers/SemirM'));
//const HarisD = lazy(() => import('./components/Pages/TeamMembers/HarisD'));
const FilipP = lazy(() => import('./components/Pages/TeamMembers/FilipP'));
//const NadirH = lazy(() => import('./components/Pages/TeamMembers/NadirH'));
// Projects pages
const Iug = lazy(() => import('./components/Pages/Projects/IUG'));
const Poliglot = lazy(() => import('./components/Pages/Projects/POLIGLOT'));
const Pomoziba = lazy(() => import('./components/Pages/Projects/POMOZIBA'));
const Drv = lazy(() => import('./components/Pages/Projects/DRV'));
const MetadataCatalogue = lazy(() => import('./components/Pages/Projects/METADATA'));
const Occ = lazy(() => import('./components/Pages/Projects/OCC'));
const Ipadram = lazy(() => import('./components/Pages/Projects/IPADRAM'));
const Repr = lazy(() => import('./components/Pages/Projects/REPR'));
const Hrf = lazy(() => import('./components/Pages/Projects/HRF'));
const NovoSarajevo = lazy(() => import('./components/Pages/Projects/NOVOSARAJEVO'));
const Stilles = lazy(() => import('./components/Pages/Projects/STILLES'));
const Collectivibe = lazy(() => import('./components/Pages/Projects/COLLECTIVIBE'));

const App = () => {
  return (
    <>
      <Helmet titleTemplate="%s | DOC" defaultTitle="DOC" />
      <Router>
        <Suspense fallback={<div>Loading...</div>}>
          <ScrollToTop />
          <Switch>
            <ChangeLanguage>
              <Route exact path="/" component={Home} />
              <Route exact path="/bs" component={Home} />
              <Route exact path="/about" component={About} />
              <Route exact path="/bs/o-nama" component={About} />
              <Route exact path="/services" component={Services} />
              <Route exact path="/bs/usluge" component={Services} />
              <Route exact path="/our-work" component={OurWork} />
              <Route exact path="/bs/portfolio" component={OurWork} />
              <Route exact path="/contact" component={Contact} />
              <Route exact path="/bs/kontakt" component={Contact} />

              <Route exact path="/software-development" component={SoftwareDevelopement} />
              <Route exact path="/bs/razvoj-softvera" component={SoftwareDevelopement} />
              <Route exact path="/digital-marketing" component={DigitalMarketing} />
              <Route exact path="/bs/digitalni-marketing" component={DigitalMarketing} />
              <Route exact path="/graphic-design" component={GraphicDesign} />
              <Route exact path="/bs/grafički-dizajn" component={GraphicDesign} />

              <Route exact path="/iug" component={Iug} />
              <Route exact path="/bs/iug" component={Iug} />
              <Route exact path="/poliglot" component={Poliglot} />
              <Route exact path="/bs/poliglot" component={Poliglot} />
              <Route exact path="/pomoziba" component={Pomoziba} />
              <Route exact path="/bs/pomoziba" component={Pomoziba} />
              <Route exact path="/drv" component={Drv} />
              <Route exact path="/bs/drv" component={Drv} />
              <Route exact path="/metadata-catalogue" component={MetadataCatalogue} />
              <Route exact path="/bs/katalog-meta-podataka" component={MetadataCatalogue} />
              <Route exact path="/online-city-council" component={Occ} />
              <Route exact path="/bs/online-gradsko-vijeće" component={Occ} />
              <Route exact path="/ipadram" component={Ipadram} />
              <Route exact path="/bs/ipadram" component={Ipadram} />
              <Route exact path="/real-estate-price-registry" component={Repr} />
              <Route exact path="/bs/registar-cijena-nekretnina" component={Repr} />
              <Route exact path="/health-reporting-forms" component={Hrf} />
              <Route exact path="/bs/obrasci-zdravstvenih-izvještaja" component={Hrf} />
              <Route exact path="/novo-sarajevo" component={NovoSarajevo} />
              <Route exact path="/bs/novo-sarajevo" component={NovoSarajevo} />
              <Route exact path="/stilles" component={Stilles} />
              <Route exact path="/bs/stilles" component={Stilles} />
              <Route exact path="/collectivibe" component={Collectivibe} />
              <Route exact path="/bs/collectivibe" component={Collectivibe} />

              <Route exact path="/nedimh" component={NedimH} />
              <Route exact path="/bs/nedimh" component={NedimH} />
              <Route exact path="/tarikr" component={TarikR} />
              <Route exact path="/bs/tarikr" component={TarikR} />
              <Route exact path="/amelaf" component={AmelaF} />
              <Route exact path="/bs/amelaf" component={AmelaF} />
              <Route exact path="/erminr" component={ErminR} />
              <Route exact path="/bs/erminr" component={ErminR} />
              {/* <Route exact path="/almirdž" component={AlmirD} />
              <Route exact path="/bs/almirdž" component={AlmirD} /> */}
              {/* <Route exact path="/tarikt" component={TarikT} />
              <Route exact path="/bs/tarikt" component={TarikT} /> */}
              <Route exact path="/semirm" component={SemirM} />
              <Route exact path="/bs/semirm" component={SemirM} />
              {/* <Route exact path="/harisd" component={HarisD} />
              <Route exact path="/bs/harisd" component={HarisD} /> */}
              <Route exact path="/filipp" component={FilipP} />
              <Route exact path="/bs/filipp" component={FilipP} />
              {/* <Route exact path="/nadirh" component={NadirH} />
              <Route exact path="/bs/nadirh" component={NadirH} /> */}
              {/* <Redirect to="/" /> */}
              <Footer />
            </ChangeLanguage>
          </Switch>
        </Suspense>
      </Router>
    </>
  );
};

export default App;
