import React, { useState } from 'react';
// Axios
import Axios from 'axios';
// Bootstrap components
import { Form, Button, Col, Toast } from 'react-bootstrap';
// Font Awesome Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// SCSS for Contact page
import './ContactForm.scss';
/**
 * Contact Form component
 */
const ContactForm = ({
  cffirstname,
  cflasttname,
  cfemail,
  cfphone,
  cfsubject,
  cfmessage,
  cfsubmit,
  cfsuccess,
  cfsucmess,
  cferror,
  cferrormes,
  cffirstnamefcfi,
  cflasttnamefcfi,
  cfemailfcfi,
  cfphonefcfi,
  cfsubjectfcfi,
  cfmessagefcfi,
}) => {
  const [validated, setValidated] = useState(false);
  const [double, setDouble] = useState(false); // Submit button
  const [showA, setShowA] = useState(false); // Poruka success
  const [showB, setShowB] = useState(false); // Poruka error

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
    setValidated(true);

    // Send form (little express backend API)
    const formValues = event.target;
    const formData = {
      first_name: formValues.first_name.value,
      last_name: formValues.last_name.value,
      email: formValues.email.value,
      phone: formValues.phone.value,
      subject: formValues.subject.value,
      message: formValues.message.value,
    };

    if (form.checkValidity() === true) {
      setDouble(true); // Submit button disabled postavio da se preventa double click
      Axios({
        method: 'POST',
        url: 'https://api.doc.ba/send-mail',
        data: formData,
      }).then((res) => {
        if (res.status === 200) {
          setShowA(true);
          setTimeout(() => {
            // Postavljen timeout za reset forme
            form.reset(); // Resetovana forma
            setValidated(false);
            setDouble(false); // Submit button disabled vracen nakon reseta forme na enabled ako se salje nova poruka
          }, 4200);
        } else {
          setShowB(true);
        }
      });
      event.preventDefault();
    }
  };

  return (
    <Form
      noValidate
      validated={validated}
      onSubmit={handleSubmit}
      className="form-contact"
      id="form-contact"
    >
      <Form.Row>
        <Form.Group as={Col} xs="6" controlId="validationFirstName">
          <Form.Label>
            {cffirstname}
            <strong className="text-danger">*</strong>
          </Form.Label>
          <Form.Control
            type="text"
            className="rounded-0 border-dark"
            placeholder=""
            name="first_name"
            required
          />
          <Form.Control.Feedback type="valid"></Form.Control.Feedback>
          <Form.Control.Feedback type="invalid">{cffirstnamefcfi}</Form.Control.Feedback>
        </Form.Group>
        <Form.Group as={Col} xs="6" controlId="validationLastName">
          <Form.Label>{cflasttname}</Form.Label>
          <Form.Control
            type="text"
            className="rounded-0 border-dark"
            placeholder=""
            name="last_name"
          />
          <Form.Control.Feedback type="valid"></Form.Control.Feedback>
          <Form.Control.Feedback type="invalid">{cflasttnamefcfi}</Form.Control.Feedback>
        </Form.Group>
      </Form.Row>

      <Form.Row>
        <Form.Group as={Col} xs="6" controlId="validationEmail">
          <Form.Label>
            {cfemail}
            <strong className="text-danger">*</strong>
          </Form.Label>
          <Form.Control
            type="email"
            className="rounded-0 border-dark"
            placeholder=""
            name="email"
            required
          />
          <Form.Control.Feedback type="valid"></Form.Control.Feedback>
          <Form.Control.Feedback type="invalid">{cfemailfcfi}</Form.Control.Feedback>
        </Form.Group>
        <Form.Group as={Col} xs="6" controlId="validationPhoneNo">
          <Form.Label>{cfphone}</Form.Label>
          <Form.Control type="text" className="rounded-0 border-dark" placeholder="" name="phone" />
          <Form.Control.Feedback type="valid"></Form.Control.Feedback>
          <Form.Control.Feedback type="invalid">{cfphonefcfi}</Form.Control.Feedback>
        </Form.Group>
      </Form.Row>

      <Form.Row>
        <Form.Group as={Col} xs="12" controlId="validationSubject">
          <Form.Label>{cfsubject}</Form.Label>
          <Form.Control
            type="text"
            className="rounded-0 border-dark"
            placeholder=""
            name="subject"
          />
          <Form.Control.Feedback type="valid"></Form.Control.Feedback>
          <Form.Control.Feedback type="invalid">{cfsubjectfcfi}</Form.Control.Feedback>
        </Form.Group>
      </Form.Row>

      <Form.Row>
        <Form.Group as={Col} xs="12" controlId="validationMessage">
          <Form.Label>
            {cfmessage}
            <strong className="text-danger">*</strong>
          </Form.Label>
          <Form.Control
            as="textarea"
            rows="3"
            className="rounded-0 border-dark er-text-area"
            name="message"
            required
          />
          <Form.Control.Feedback type="valid"></Form.Control.Feedback>
          <Form.Control.Feedback type="invalid">{cfmessagefcfi}</Form.Control.Feedback>
        </Form.Group>
      </Form.Row>

      <Form.Row>
        <Form.Group as={Col} xs="12" sm="3" md="6" lg="5" xl="3">
          <Button
            type="submit"
            className="er-btn-font btn-block"
            variant="outline-dark"
            disabled={double}
          >
            {cfsubmit} <FontAwesomeIcon icon={['fad', 'paper-plane']} />
          </Button>
        </Form.Group>
        <Form.Group as={Col} xs="12" sm="9" md="6" lg="7" xl="9">
          <div className="d-flex flex-column align-items-end">
            <Toast
              onClose={() => [setShowA(false), setDouble(false)]}
              show={showA}
              delay={4000}
              animation={true}
              autohide
              className="bg-success rounded-0 border-0 w-100"
            >
              <Toast.Header>
                <FontAwesomeIcon icon={['fad', 'check-double']} size={'lg'} />
                <strong className="mr-auto ml-2">{cfsuccess}</strong>
              </Toast.Header>
              <Toast.Body>{cfsucmess}</Toast.Body>
            </Toast>
            <Toast
              onClose={() => setShowB(false)}
              show={showB}
              delay={4000}
              animation={true}
              autohide
              className="bg-danger rounded-0 border-0 w-100"
            >
              <Toast.Header>
                <FontAwesomeIcon icon={['fad', 'exclamation-circle']} size={'lg'} />
                <strong className="mr-auto ml-2">{cferror}</strong>
              </Toast.Header>
              <Toast.Body>{cferrormes}</Toast.Body>
            </Toast>
          </div>
        </Form.Group>
      </Form.Row>
    </Form>
  );
};

export default ContactForm;
