import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
// Google Tag Manager
import TagManager from 'react-gtm-module';
// Global scss
import './assets/scss/index.scss';
// Font DINPro by FILIP
import './assets/fonts/DINPro.scss';
// Global css
import './index.scss';
// Global js
import './assets/js/index';
import App from './App';
import * as serviceWorker from './serviceWorker';
// Fontawesome
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/pro-solid-svg-icons';
import { far } from '@fortawesome/pro-regular-svg-icons';
import { fal } from '@fortawesome/pro-light-svg-icons';
import { fad } from '@fortawesome/pro-duotone-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons';
library.add(fas, far, fal, fad, fab);

// Google Tag Manager
const tagManagerArgs = {
  gtmId: 'GTM-5FWF24G',
};
TagManager.initialize(tagManagerArgs);

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
