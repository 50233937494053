/**
 * !!! PROJECTS DATA !!!
 */
// Logos images
import logoIUG from '../../assets/img/logos/logoIUG.svg';
import logoPOLIGLOT from '../../assets/img/logos/logoPOLIGLOT.svg';
import logoPOMOZIBA from '../../assets/img/logos/logoPOMOZIBA.svg';
import logoDRV from '../../assets/img/logos/logoDRV.svg';
import logoMETADATA from '../../assets/img/logos/logoMETADATA.svg';
import logoOCC from '../../assets/img/logos/logoOCC.svg';
import logoIPADRAM from '../../assets/img/logos/logoIPADRAM.svg';
import logoREPR from '../../assets/img/logos/logoREPR.svg';
import logoHRF from '../../assets/img/logos/logoHRF.svg';
import logoNOSA from '../../assets/img/logos/logoNOSA.svg';
import logoSTILLES from '../../assets/img/logos/logoSTILLES.svg';
import logoCOLLECTIVIBE from '../../assets/img/logos/logoCOLLECTIVIBE.png';
// Featured images
import featIUG from '../../assets/img/logos/featuredIUG.png';
import featPOLIGLOT from '../../assets/img/logos/featuredPOLIGLOT.png';
import featPOMOZIBA from '../../assets/img/logos/featuredPOMOZIBA.png';
import featDRV from '../../assets/img/logos/featuredDRV.jpg';
import featMETADATA from '../../assets/img/logos/featuredMETADATA.jpg';
import featOCC from '../../assets/img/logos/featuredOCC.jpg';
import featIPADRAM from '../../assets/img/logos/featuredIPADRAM.jpg';
import featREPR from '../../assets/img/logos/featuredREPR.png';
import featHRF from '../../assets/img/logos/featuredHRF.jpg';
import featNOSA from '../../assets/img/logos/featuredNOSA.jpg';
import featSTILLES from '../../assets/img/logos/featuredSTILLES.jpg';
import featCOLLECTIVIBE from '../../assets/img/logos/featuredCOLLECTIVIBE.jpg';
// Static header image
import bgImgMainIUG from '../../assets/img/iug_img/bg-header-IUG.png';
import bgImgMainPOLIGLOT from '../../assets/img/poliglot_img/bg-header-POLIGLOT.png';
import bgImgMainPOMOZIBA from '../../assets/img/pomoziba_img/bg-header-POMOZIBA.png';
import bgImgMainDRV from '../../assets/img/drv_img/bg-header-DRV.png';
import bgImgMainMETADATA from '../../assets/img/metadata_img/bg-header-METADATA.png';
import bgImgMainOCC from '../../assets/img/occ_img/bg-header-OCC.png';
import bgImgMainIPADRAM from '../../assets/img/ipadram_img/bg-header-IPADRAM.png';
import bgImgMainREPR from '../../assets/img/repr_img/bg-header-REPR.png';
import bgImgMainHRF from '../../assets/img/hrf_img/bg-header-HRF.png';
import bgImgMainNOSA from '../../assets/img/novosarajevo_img/bg-header-NOSA.png';
import bgImgMainSTILLES from '../../assets/img/stilles_img/bg-header-STILLES.png';
import bgImgMainCOLLECTIVIBE from '../../assets/img/collectivibe_img/bg-header-COLLECTIVIBE.png';
// IMAGES IUG
import kocka1IUG from '../../assets/img/iug_img/kocka1IUG.png';
import kocka2IUG from '../../assets/img/iug_img/kocka2IUG.png';
import kocka3IUG from '../../assets/img/iug_img/kocka3IUG.png';
import kocka4IUG from '../../assets/img/iug_img/kocka4IUG.png';
import kocka5IUG from '../../assets/img/iug_img/kocka5IUG.png';
// IMAGES POLIGLOT
import kocka1POLIGLOT from '../../assets/img/poliglot_img/kocka1POLIGLOT.png';
import kocka2POLIGLOT from '../../assets/img/poliglot_img/kocka2POLIGLOT.png';
import kocka3POLIGLOT from '../../assets/img/poliglot_img/kocka3POLIGLOT.png';
import kocka4POLIGLOT from '../../assets/img/poliglot_img/kocka4POLIGLOT.png';
import kocka5POLIGLOT from '../../assets/img/poliglot_img/kocka5POLIGLOT.png';
// IMAGES POMOZIBA
import kocka1POMOZIBA from '../../assets/img/pomoziba_img/kocka1POMOZIBA.png';
import kocka2POMOZIBA from '../../assets/img/pomoziba_img/kocka2POMOZIBA.png';
import kocka3POMOZIBA from '../../assets/img/pomoziba_img/kocka3POMOZIBA.png';
import kocka4POMOZIBA from '../../assets/img/pomoziba_img/kocka4POMOZIBA.png';
// IMAGES DRV
import kocka1DRV from '../../assets/img/drv_img/kocka1DRV.png';
import kocka2DRV from '../../assets/img/drv_img/kocka2DRV.png';
import kocka3DRV from '../../assets/img/drv_img/kocka3DRV.png';
// IMAGES METADATA
import kocka1METADATA from '../../assets/img/metadata_img/kocka1METADATA.png';
import kocka2METADATA from '../../assets/img/metadata_img/kocka2METADATA.png';
import kocka3METADATA from '../../assets/img/metadata_img/kocka3METADATA.png';
import kocka4METADATA from '../../assets/img/metadata_img/kocka4METADATA.png';
// IMAGES ONLINE CITY COUNCILE
import kocka1OCC from '../../assets/img/occ_img/kocka1OCC.png';
import kocka2OCC from '../../assets/img/occ_img/kocka2OCC.png';
// IMAGES IPADRAM
import kocka1IPADRAM from '../../assets/img/ipadram_img/kocka1IPADRAM.png';
import kocka2IPADRAM from '../../assets/img/ipadram_img/kocka2IPADRAM.png';
import kocka3IPADRAM from '../../assets/img/ipadram_img/kocka3IPADRAM.png';
// IMAGES REAL ESTATE PRICE REGISTRY
import kocka1REPR from '../../assets/img/repr_img/kocka1REPR.png';
import kocka2REPR from '../../assets/img/repr_img/kocka2REPR.png';
// IMAGES HEALTH REPORTING FORMS
import kocka1HRF from '../../assets/img/hrf_img/kocka1HRF.png';
import kocka2HRF from '../../assets/img/hrf_img/kocka2HRF.png';
import kocka3HRF from '../../assets/img/hrf_img/kocka3HRF.png';
import kocka4HRF from '../../assets/img/hrf_img/kocka4HRF.png';
// IMAGES NOVO SARAJEVO
import kocka1NOSA from '../../assets/img/novosarajevo_img/kocka1NOSA.png';
import kocka2NOSA from '../../assets/img/novosarajevo_img/kocka2NOSA.png';
// IMAGES STILLES
import kocka1STILLES from '../../assets/img/stilles_img/kocka1STILLES.png';
import kocka2STILLES from '../../assets/img/stilles_img/kocka2STILLES.png';
// IMAGES COLLECTIVIBE
import kocka1COLLECTIVIBE from '../../assets/img/collectivibe_img/kocka1COLLECTIVIBE.jpg';
import kocka2COLLECTIVIBE from '../../assets/img/collectivibe_img/kocka2COLLECTIVIBE.jpg';
// ?   poicon1PROJEKAT
// THE SOLUTION POLIGLOT
import poicon1POLIGLOT from '../../assets/icons/poicon14.svg';
import poicon2POLIGLOT from '../../assets/icons/poicon15.svg';
import poicon3POLIGLOT from '../../assets/icons/poicon16.svg';
import poicon4POLIGLOT from '../../assets/icons/poicon17.svg';
// THE SOLUTION IUG
import poicon1IUG from '../../assets/icons/poicon14.svg';
import poicon2IUG from '../../assets/icons/poicon15.svg';
import poicon3IUG from '../../assets/icons/poicon16.svg';
import poicon4IUG from '../../assets/icons/poicon17.svg';
// THE SOLUTION POMOZIBA
import poicon1POMOZIBA from '../../assets/icons/poicon18.svg';
import poicon2POMOZIBA from '../../assets/icons/poicon19.svg';
import poicon3POMOZIBA from '../../assets/icons/poicon6.svg';
import poicon4POMOZIBA from '../../assets/icons/poicon20.svg';
// THE SOLUTION NOVO SARAJEVO
import poicon1NOSA from '../../assets/icons/poicon6.svg';
import poicon2NOSA from '../../assets/icons/poicon21.svg';
import poicon3NOSA from '../../assets/icons/poicon22.svg';
import poicon4NOSA from '../../assets/icons/poicon23.svg';
// THE SOLUTION HEALTH REPORTING FORMS
import poicon1HRF from '../../assets/icons/poicon18.svg';
import poicon2HRF from '../../assets/icons/poicon24.svg';
import poicon3HRF from '../../assets/icons/poicon25.svg';
import poicon4HRF from '../../assets/icons/poicon26.svg';
// THE SOLUTION ONLINE CITY COUNCILE
import poicon1OCC from '../../assets/icons/poicon18.svg';
import poicon2OCC from '../../assets/icons/poicon27.svg';
import poicon3OCC from '../../assets/icons/poicon6.svg';
import poicon4OCC from '../../assets/icons/poicon23.svg';
// THE SOLUTION IPADRAM
import poicon1IPADRAM from '../../assets/icons/poicon28.svg';
import poicon2IPADRAM from '../../assets/icons/poicon29.svg';
import poicon3IPADRAM from '../../assets/icons/poicon11.svg';
import poicon4IPADRAM from '../../assets/icons/poicon30.svg';
// THE SOLUTION STILLES
import poicon1STILLES from '../../assets/icons/poicon18.svg';
import poicon2STILLES from '../../assets/icons/poicon31.svg';
import poicon3STILLES from '../../assets/icons/poicon6.svg';
import poicon4STILLES from '../../assets/icons/poicon23.svg';
// THE SOLUTION METADATA
import poicon1METADATA from '../../assets/icons/poicon18.svg';
import poicon2METADATA from '../../assets/icons/poicon32.svg';
import poicon3METADATA from '../../assets/icons/poicon33.svg';
import poicon4METADATA from '../../assets/icons/poicon23.svg';
// THE SOLUTION DRV
import poicon1DRV from '../../assets/icons/poicon19.svg';
import poicon2DRV from '../../assets/icons/poicon34.svg';
import poicon3DRV from '../../assets/icons/poicon6.svg';
import poicon4DRV from '../../assets/icons/poicon23.svg';
// THE SOLUTION REAL ESTATE PRICE REGISTRY
import poicon1REPR from '../../assets/icons/poicon18.svg';
import poicon2REPR from '../../assets/icons/poicon35.svg';
import poicon3REPR from '../../assets/icons/poicon6.svg';
import poicon4REPR from '../../assets/icons/poicon23.svg';
// ?   relevant1PROJEKT     i za        /dirproject/relevant1PROJEKT.svg
// RELEVANT LINKS default
import relevant1 from '../../assets/icons/relevant1.svg';
import relevant2 from '../../assets/icons/relevant2.svg';
import relevant3 from '../../assets/icons/relevant3.svg';
// CLIENTS TESTEMONIAL
import testemonialIUG from '../../assets/img/iug_img/testimonial.png';
import testemonialPOLIGLOT from '../../assets/img/poliglot_img/testimonial.png';
import testemonialPOMOZIBA from '../../assets/img/pomoziba_img/testimonial.png';

const projects = [
  // TODO - COLLECTIVIBE
  {
    id: 1,
    title: 'COLLECTIVIBE',
    subtitle: 'VIRTUAL EVENTS FOR GENUINE CONNECTIONS',
    urlTo: '/collectivibe',
    src: logoCOLLECTIVIBE,
    srcfi: featCOLLECTIVIBE,
    logow: 'w-75',
    bcHeadera: '#0083cb',
    headerimg: bgImgMainCOLLECTIVIBE,
    boxicont1: 'MONTHS OF WORK',
    boxicont2: 'LINES OF CODE',
    boxicont3: 'COFFEE CUPS DRANK',
    boxicont4: 'YEAR STARTED',
    // BUTTON
    btnscrolldown: 'SCROLL DOWN TO SEE MORE',
    btnseeprojpage: 'SEE PROJECT PAGE',
    // META TAG i TITLE u head za React-Helmet
    rhdesc:
      'DOC.BA is a company with young experts in different fields of informational technologies, computer sciences, design and digital marketing.',
    rhlocale: 'en_US',
    rhlinkco: '/contact',
    // CASE STUDY
    casestadytitle: 'CASE STUDY',
    casestadybtn: 'btn-doc-hrf-primary',
    casestadytext:
      'This project is based on the improvement of the existing platform for virtual events. The client requested an upgrade in functionalities and design.',
    // THE SOLUTION:
    solutionlight: 'THE',
    solutionbold: 'SOLUTION',
    solutionbgcol: 'bg-doc-hrf-secondary',
    solutiontext1:
      'We are currently working on a new version of the platform for virtual events. This version involves upgrading existing functionalities, adding new functionalities as well as designing a new, modern, and user-oriented design.',
    // PROJECT OVERVIEW
    potitle: 'PROJECT OVERVIEW',
    poicon1: poicon1REPR,
    poicon1txt: 'TECHNOLOGY ASSESSMENT',
    poicon2: poicon1IUG,
    poicon2txt: 'UI/UX DESIGN',
    poicon3: poicon3REPR,
    poicon3txt: 'APP DEVELOPMENT',
    poicon4: poicon4REPR,
    poicon4txt: 'DEPLOYMENT',
    // KOCKA
    kocka: [
      {
        img: kocka1COLLECTIVIBE,
        title: 'UI/UX DESIGN',
        bgcol: 'bg-doc-hrf-primary',
        text1:
          'The new design is adapted for simple and intuitive use by users who wish to attend virtual events and eventualy become part of virtual community. We need to allow the event owner to customize the visual identity, but still keep it harmonized.',
      },
      {
        img: kocka2COLLECTIVIBE,
        title: 'DEVELOPMENT',
        bgcol: 'bg-doc-hrf-secondary',
        text1:
          'The main part of the application is the platform for hosting virtual events. The other important part is the online community segmet which includes special funtionalities like publishing different types of content and members interaction. Following the requirements, our web development team has been engaged, delivering a modern, funtional application, using the up to date technology and following the latest design trends.',
      },
    ],
    // RELATIVE LINKS
    rellinklight: 'RELEVANT',
    rellinkbold: 'LINKS',
    rellinkbgcolor: 'bg-doc-hrf-primary',
    // RELATIVE LINKS icons
    rellinktitle: 'COLLECTIVIBE',
    rellinkicon1: relevant1,
    rellinktext1: 'WEBSITE',
    rellinkhref1: 'https://collectivibe.com/',
    // CONTAINER ABOVE FOOTER
    lwttext1: "LET'S",
    lwttext2: 'WORK',
    lwttext3: 'TOGETHER',
  },
  // TODO - POMOZIBA
  {
    id: 2,
    title: 'POMOZI.BA',
    subtitle: 'ONLINE FUNDRAISING PLATFORM',
    urlTo: '/pomoziba',
    src: logoPOMOZIBA,
    srcfi: featPOMOZIBA,
    logow: 'w-75',
    bcHeadera: '#fd5300',
    headerimg: bgImgMainPOMOZIBA,
    boxicont1: 'MONTHS OF WORK',
    boxicont2: 'LINES OF CODE',
    boxicont3: 'COFFEE CUPS DRANK',
    boxicont4: 'YEAR LAUNCHED',
    // BUTTON
    btnscrolldown: 'SCROLL DOWN TO SEE MORE',
    btnseeprojpage: 'SEE PROJECT PAGE',
    // META TAG i TITLE u head za React-Helmet
    rhdesc:
      'DOC.BA is a company with young experts in different fields of informational technologies, computer sciences, design and digital marketing.',
    rhlocale: 'en_US',
    rhlinkco: '/contact',
    // CASE STUDY
    casestadytitle: 'CASE STUDY',
    casestadybtn: 'btn-doc-pomoziba-secondary',
    casestadytext:
      'Pomozi.ba is one of the leading charity organizations, providing care and aid to those in need for almost a decade. Through their activities they help countless people in need of health treatment, money donations, food etc. A huge number of their inquires happen online, and they have recognized importance of investing in IT in order to save time, optimize their work and in the end, help more people.',
    // THE SOLUTION:
    solutionlight: 'THE',
    solutionbold: 'SOLUTION',
    solutionbgcol: 'bg-doc-pomoziba-primary',
    solutiontext1:
      'After analyzing client and their market needs, we have been tasked in development of regional crowd funding platform, in order to maximize number of requests they can handle, as well as make it easier for the people in need to apply and raise funds for their cause.',
    solutiontext2:
      'We also developed and integrated Chat Bot automation to optimize a very high number of inquiries they handle on a daily basis. We have automated a big part of information requests, and thus created more time for administrators of the page to dedicate themselves to increase conversation quality.',
    solutiontext3: '',
    // PROJECT OVERVIEW
    potitle: 'PROJECT OVERVIEW',
    poicon1: poicon1POMOZIBA,
    poicon1txt: 'TECHNOLOGY ASSESSMENT',
    poicon2: poicon2POMOZIBA,
    poicon2txt: 'APPLICATION ANALYSIS & DESIGN',
    poicon3: poicon3POMOZIBA,
    poicon3txt: 'DEVELOPMENT',
    poicon4: poicon4POMOZIBA,
    poicon4txt: 'CHAT BOT AUTOMATIZATION',
    // KOCKA
    kocka: [
      {
        img: kocka1POMOZIBA,
        title: 'TECHNOLOGY ASSESSMENT',
        bgcol: 'bg-doc-pomoziba-secondary',
        text1:
          'Complete web portal is based on the Node.js platform, which enables its architecture to provide a large number of users with a fast and stable web portal operation. Considering that the web platform is based on online donations and campaigns, we decided on Adonis.js MVC back-end framework, which satisfied all segments of the project by its design.',
      },
      {
        img: kocka2POMOZIBA,
        title: 'APPLICATION ANALYSIS & DESIGN',
        bgcol: 'bg-doc-pomoziba-primary',
        text1:
          'Given the fact that the client did not have a platform for posting requests for help, a detailed analysis of client requests and optimization was performed. The design is adapted for simple and intuitive use by users who wish to participate in campaign donations.',
      },
      {
        img: kocka3POMOZIBA,
        title: 'DEVELOPMENT',
        bgcol: 'bg-doc-pomoziba-secondary',
        text1:
          'The main part of the application is a platform for posting requests for help and donations. For this reason, the application contains the functionality of online payment, which required serious security measures to be taken during development. Manual checks are required on all requests, without automation for security reasons.',
      },
      {
        img: kocka4POMOZIBA,
        title: 'CHAT BOT AUTOMATIZATION',
        bgcol: 'bg-doc-pomoziba-primary',
        text1:
          'Given the fact that Pomozi.ba is the biggest charity organization in the BiH, and one of the biggest in the region, the number of inquiries they get on a daily basis is really huge. With the latest technology in Messenger Chat Bot automation, we were able to create and integrate automated responses with relevant information. Benefits are multiple, from being able to provide information to the prospects 24/7, to being able to segment visitors based on information they were looking for, as well as save human resources needed to manage all of the inquires.',
      },
    ],
    // RELATIVE LINKS
    rellinklight: 'RELEVANT',
    rellinkbold: 'LINKS',
    rellinkbgcolor: 'bg-doc-pomoziba-secondary',
    // RELATIVE LINKS icons
    rellinktitle: 'POMOZI.BA',
    rellinkicon1: relevant1,
    rellinktext1: 'WEBSITE',
    rellinkhref1: 'https://www.pomozi.ba/',
    rellinkicon2: relevant2,
    rellinktext2: 'ON FACEBOOK',
    rellinkhref2: 'https://www.facebook.com/pomozi.ba/',
    rellinkicon3: relevant3,
    rellinktext3: 'ON INSTAGRAM',
    rellinkhref3: 'https://www.instagram.com/pomozi.ba/',
    // CLIENTS TESTEMONIAL
    testemonialh4: 'CLIENT TESTIMONIAL',
    testemonialimg: testemonialPOMOZIBA,
    testemonialtxt:
      'Running one of the biggest charity organizations in the region is time consuming and highly stressful job. DOC.ba team enabled us to automatize a huge portion of our inquires with their chat bot, and have also developed crowd funding platform that enables us to help more people, with less time spent.',
    testemonialstr: 'Elvir K.,',
    testemonialem: 'manager at POMOZI.ba',
    // CONTAINER ABOVE FOOTER
    lwttext1: "LET'S",
    lwttext2: 'WORK',
    lwttext3: 'TOGETHER',
  },
  // TODO - REAL ESTATE PRICE REGISTRY
  {
    id: 3,
    title: 'REAL ESTATE PRICE REGISTRY',
    subtitle: 'PUBLIC ACCESS TO DATA',
    urlTo: '/real-estate-price-registry',
    src: logoREPR,
    srcfi: featREPR,
    logow: 'w-50',
    bcHeadera: '#d9391e',
    headerimg: bgImgMainREPR,
    boxicont1: 'FEDERAL INSTITUTIONS INVOLVED',
    boxicont2: 'LOCAL GOVERNMENT UNITS INVOLVED',
    boxicont3: 'DATA TYPES AVAILABLE',
    boxicont4: 'YEAR LAUNCHED',
    // BUTTON
    btnscrolldown: 'SCROLL DOWN TO SEE MORE',
    btnseeprojpage: 'SEE PROJECT PAGE',
    // META TAG i TITLE u head za React-Helmet
    rhdesc:
      'DOC.BA is a company with young experts in different fields of informational technologies, computer sciences, design and digital marketing.',
    rhlocale: 'en_US',
    rhlinkco: '/contact',
    // CASE STUDY
    casestadytitle: 'CASE STUDY',
    casestadybtn: 'btn-doc-repr-secondary',
    casestadytext:
      'The public data presentation of the Real Estate Price Registry of the Federation of BiH is the result of several institutions cooperation: the Federal Surveying and Property Administration, the Federal Ministry of Finance and the Tax Administration of the Federation of BiH and local self-government units in the Federation of BiH in the implementation of the CILAP project activities. Project "Capacity Building for Improving Land Administration and Procedures in Bosnia and Herzegovina" - CILAP, is a long-term project whose purpose is capacity building and knowledge transfer, all with the aim of increasing the efficiency and reliability of the land administration process in Bosnia and Herzegovina.',
    // THE SOLUTION:
    solutionlight: 'THE',
    solutionbold: 'SOLUTION',
    solutionbgcol: 'bg-doc-repr-primary',
    solutiontext1:
      'The purpose of establishing and maintaining the Real Estate Price Register is to plan price monitoring and analysis of the real estate market, and to provide public insight to all participants and investors in the real estate market in the Federation of BiH with easily accessible data on realized prices and other characteristics of real estate at different locations and time periods. So far, 63 interested local self-government units and all tax offices in the Federation of BiH have been included in the Real Estate Price Register system.',
    // PROJECT OVERVIEW
    potitle: 'PROJECT OVERVIEW',
    poicon1: poicon1REPR,
    poicon1txt: 'TECHNOLOGY ASSESSMENT',
    poicon2: poicon2REPR,
    poicon2txt: 'DIFFERENT TYPES OF DATA',
    poicon3: poicon3REPR,
    poicon3txt: 'APP DEVELOPMENT',
    poicon4: poicon4REPR,
    poicon4txt: 'DEPLOYMENT',
    // KOCKA
    kocka: [
      {
        img: kocka1REPR,
        title: 'DISPLAY DIFFERENT TYPES OF DATA',
        bgcol: 'bg-doc-repr-secondary',
        text1:
          'Display different types of data: For the purposes of transparency and to increase the security of the real estate market, the following data of the Real Estate Price Register are freely available: type and subtype of real estate in traffic; approximate location of the property being traded (pinned on map); the date of conclusion of the real estate sale contract; area of real estate in traffic; the total price of the real estate under contract; information on whether there were more than one property in the contract in question and what kind.',
      },
      {
        img: kocka2REPR,
        title: 'APPLICATION DEVELOPMENT',
        bgcol: 'bg-doc-repr-primary',
        text1:
          'This app contains an interactive map and advanced search by various filters, all for ease of use by users. You can run the FBiH Real Estate Registry Viewer from your computer, tablet, mobile or other device, and content is tailored to all currently available device platforms. directive and ISO standard, but still specific for the area of interest. It covered functionalities like: advanced search, map view, multilingualism, managing metadata, managing users and many more. Before implementing a visual display of the application, we explored the relationship of colors that are appropriate for longer work on the computer to make it easier for the user to use the application.',
      },
    ],
    // RELATIVE LINKS
    rellinklight: 'RELEVANT',
    rellinkbold: 'LINKS',
    rellinkbgcolor: 'bg-doc-repr-secondary',
    // RELATIVE LINKS icons
    rellinktitle: 'REAL ESTATE PRICE REGISTRY',
    rellinkicon1: relevant1,
    rellinktext1: 'WEBSITE',
    rellinkhref1: 'https://katastar.ba/rcn',
    // CONTAINER ABOVE FOOTER
    lwttext1: "LET'S",
    lwttext2: 'WORK',
    lwttext3: 'TOGETHER',
  },
  // TODO - METADATA CATALOGUE
  {
    id: 4,
    title: 'METADATA',
    subtitle: 'CATALOGUE',
    urlTo: '/metadata-catalogue',
    src: logoMETADATA,
    srcfi: featMETADATA,
    logow: 'w-50',
    bcHeadera: '#2c4251',
    headerimg: bgImgMainMETADATA,
    boxicont1: 'MONTHS OF WORK',
    boxicont2: 'LINES OF CODE',
    boxicont3: 'COFFEE CUPS DRANK',
    boxicont4: 'YEAR LAUNCHED',
    // BUTTON
    btnscrolldown: 'SCROLL DOWN TO SEE MORE',
    btnseeprojpage: 'SEE PROJECT PAGE',
    // META TAG i TITLE u head za React-Helmet
    rhdesc:
      'DOC.BA is a company with young experts in different fields of informational technologies, computer sciences, design and digital marketing.',
    rhlocale: 'en_US',
    rhlinkco: '/contact',
    // CASE STUDY
    casestadytitle: 'CASE STUDY',
    casestadybtn: 'btn-doc-metadata-plava',
    casestadytext:
      'Metadata catalogue is GIS project brought to us by Federal Administration for Geodetic and Property Affairs. The goal of developing metadata profiles and metadata management systems is to establish spatial data infrastructure of the Federation of Bosnia and Herzegovina. It is the first access point for spatial data infrastructure and is expected to be an integral component of the future geoportal.',
    // THE SOLUTION:
    solutionlight: 'THE',
    solutionbold: 'SOLUTION',
    solutionbgcol: 'bg-doc-metadata-siva',
    solutiontext1:
      'In accordance with clients needs and standards that had to be followed in the implementation of this project, we created a system for adding, maintaining, searching, validating and import/export of metadata. The System is fully in compliance with INSPIRE directive 2007/2/EZ and ISO 19115-1:2014 standard. It was our task to meet all functional and non-functional requirements of this geographic information system project.',
    // PROJECT OVERVIEW
    potitle: 'PROJECT OVERVIEW',
    poicon1: poicon1METADATA,
    poicon1txt: 'ANALYSIS AND DATA COLLECTION TECHNOLOGY ASSESSMENT',
    poicon2: poicon2METADATA,
    poicon2txt: 'APPLICATION DESIGN & DEV',
    poicon3: poicon3METADATA,
    poicon3txt: 'METADATA PROFILE IMPLEMENTATION',
    poicon4: poicon4METADATA,
    poicon4txt: 'DEPLOYMENT TO PRODUCTION & TRAINING',
    // KOCKA
    kocka: [
      {
        img: kocka1METADATA,
        title: 'TECHNOLOGY ASSESSMENT',
        bgcol: 'bg-doc-metadata-plava',
        text1:
          'The Metadata Catalog is a highly specialized software with specific requirements that required detailed analysis. After the analysis, the technology of choice was GeoNetwork, a catalog application to manage spatially referenced resources. It provides powerful metadata editing and search functions as well as an interactive web map viewer.',
      },
      {
        img: kocka2METADATA,
        title: 'APPLICATION DESIGN & DEV',
        bgcol: 'bg-doc-metadata-siva',
        text1:
          'It took months to develop this complex system that needed to be in compliance with INSPIRE directive and ISO standard, but still specific for the area of interest. It covered functionalities like: advanced search, map view, multilingualism, managing metadata, managing users and many more. Before implementing a visual display of the application, we explored the relationship of colors that are appropriate for longer work on the computer to make it easier for the user to use the application.',
      },
      {
        img: kocka3METADATA,
        title: 'SOFTWARE DEVELOPMENT METADATA PROFILE IMPLEMENTATION',
        bgcol: 'bg-doc-metadata-plava',
        text1:
          'Metadata profile (model) for sets of spatial data/arrays of spatial data and spatial data services of the spatial data infrastructure is corresponding to the topics defined in Annexes I, II. and III. of INSPIRE Directive 2007/2/EC. Because of the specific area for which the metadata catalog is created, the metadata profile needed the implementation of an additional topic. ',
      },
      {
        img: kocka4METADATA,
        title: 'DEPLOYMENT TO PRODUCTION & TRAINING',
        bgcol: 'bg-doc-metadata-siva',
        text1:
          'System like this with its non-functional requirements implied a specific production architecture. We had to use several technologies: Nginx web server for reverse proxy and load balancer, Apache Tomcat server, PostgreSQL relational database management system and Windows Server 2016. Training workshops have been successfully held with users who will be most involved in metadata management.',
      },
    ],
    // RELATIVE LINKS
    rellinklight: 'RELEVANT',
    rellinkbold: 'LINKS',
    rellinkbgcolor: 'bg-doc-metadata-plava',
    // RELATIVE LINKS icons
    rellinktitle: 'METADATA CATALOGUE',
    rellinkicon1: relevant1,
    rellinktext1: 'WEBSITE',
    rellinkhref1: 'https://katastar.ba/',
    // CONTAINER ABOVE FOOTER
    lwttext1: "LET'S",
    lwttext2: 'WORK',
    lwttext3: 'TOGETHER',
  },
  // TODO - DRV
  {
    id: 5,
    title: 'DRV',
    subtitle: 'DIGITAL TIME TABLES ',
    urlTo: '/drv',
    src: logoDRV,
    srcfi: featDRV,
    logow: 'w-75',
    bcHeadera: '#0092d0',
    headerimg: bgImgMainDRV,
    boxicont1: 'MONTHS OF WORK',
    boxicont2: 'LINES OF CODE',
    boxicont3: 'COFFEE CUPS DRANK',
    boxicont4: 'YEAR LAUNCHED',
    // BUTTON
    btnscrolldown: 'SCROLL DOWN TO SEE MORE',
    btnseeprojpage: 'SEE PROJECT PAGE',
    // META TAG i TITLE u head za React-Helmet
    rhdesc:
      'DOC.BA is a company with young experts in different fields of informational technologies, computer sciences, design and digital marketing.',
    rhlocale: 'en_US',
    rhlinkco: '/contact',
    // CASE STUDY
    casestadytitle: 'CASE STUDY',
    casestadybtn: 'btn-doc-drv-purple',
    casestadytext:
      'The project is based on the TISAR project. Main goal of the TISAR project is to implement an ICT platform that connects public transport and travel planning data. This system addresses the lack of a travel planning system using multimodal transport (switching between different modes of transport from starting point to destination point).',
    // THE SOLUTION:
    solutionlight: 'THE',
    solutionbold: 'SOLUTION',
    solutionbgcol: 'bg-doc-drv-plava',
    solutiontext1:
      'Digital timetables (DRV) are a platform for centralized management and monitoring schedules one carrier. The biggest advantage of the DRV platform is interoperability with other systems such as Google Maps, OpenStreetMap, Tisar and many others. The DRV allows the agencies to one-click update the driving data throughout the Internet in seconds, improve their visibility on world-renowned services, and directly from the DRV to announce the timetables on their website. These data include the display of the route and multimodal GIS planning.',
    // PROJECT OVERVIEW
    potitle: 'PROJECT OVERVIEW',
    poicon1: poicon1DRV,
    poicon1txt: 'ANALYSIS AND DATA COLLECTION',
    poicon2: poicon2DRV,
    poicon2txt: 'GTFS CREATION',
    poicon3: poicon3DRV,
    poicon3txt: 'SOFTWARE DEVELOPMENT',
    poicon4: poicon4DRV,
    poicon4txt: 'DEPLOYMENT',
    // KOCKA
    kocka: [
      {
        img: kocka1DRV,
        title: 'ANALYSIS & DATA COLLECTION',
        bgcol: 'bg-doc-drv-purple',
        text1:
          'One of the main challenges of the project was the lack of good quality data. The existing data were not suitable for processing (non-centralized, disorganized, analogue and even obsolete), nor did they cover all required areas. For this reason, a detailed analysis was performed to determine the exact data model according to which to insert the data into the database.',
      },
      {
        img: kocka2DRV,
        title: 'GTFS CRREATION',
        bgcol: 'bg-doc-drv-plava',
        text1:
          "GTFS defines the usual format for public transport timetables and associated spatial information. It allows public transport operators to publish transportation information and developers to use that data to write applications. The creation of the BACKBONE GTFS (General Transit Feed Specification) of the Adriatic region's international traffic connected all the collected timetables and spatial data.",
      },
      {
        img: kocka3DRV,
        title: 'SOFTWARE DEVELOPMENT',
        bgcol: 'bg-doc-drv-purple',
        text1:
          "DRV is fully integrated into the solutions offered by Google across its services, across all platforms, from desktops, mobile devices to tablets and other devices. Transportation routes are digitized on a vector basis, showing a detailed route itinerary. The app lets you analyze your entire journey from start to finish and identify the user's location (GPS).",
      },
    ],
    // RELATIVE LINKS
    rellinklight: 'RELEVANT',
    rellinkbold: 'LINKS',
    rellinkbgcolor: 'bg-doc-drv-plava',
    // RELATIVE LINKS icons
    rellinktitle: 'DRV',
    rellinkicon1: relevant1,
    rellinktext1: 'WEBSITE',
    rellinkhref1: 'https://drv.doc.ba/',
    // CONTAINER ABOVE FOOTER
    lwttext1: "LET'S",
    lwttext2: 'WORK',
    lwttext3: 'TOGETHER',
  },
  // TODO - ONLINE CITY COUNCILE
  {
    id: 6,
    title: 'ONLINE CITY COUNCIL',
    subtitle: 'CITY OF SARAJEVO',
    urlTo: '/online-city-council',
    src: logoOCC,
    srcfi: featOCC,
    logow: 'w-50',
    bcHeadera: '#990000',
    headerimg: bgImgMainOCC,
    boxicont1: 'MAJOR SOFTWARE UPDATES',
    boxicont2: 'CUSTOM POST TYPES',
    boxicont3: 'ACTIVE USERS',
    boxicont4: 'YEAR LAUNCHED',
    // BUTTON
    btnscrolldown: 'SCROLL DOWN TO SEE MORE',
    btnseeprojpage: 'SEE PROJECT PAGE',
    // META TAG i TITLE u head za React-Helmet
    rhdesc:
      'DOC.BA is a company with young experts in different fields of informational technologies, computer sciences, design and digital marketing.',
    rhlocale: 'en_US',
    rhlinkco: '/contact',
    // CASE STUDY
    casestadytitle: 'CASE STUDY',
    casestadybtn: 'btn-doc-occ-secondary text-white',
    casestadytext:
      'City council officers spent a lot of time delivering printed materials for council meetings. Digital delivery of materials has greatly facilitated and accelerated business processes. It also reduced paper consumption, which is a positive environmental impact.',
    // THE SOLUTION:
    solutionlight: 'THE',
    solutionbold: 'SOLUTION',
    solutionbgcol: 'bg-doc-occ-primary',
    solutiontext1:
      'Official electronic communication project for the City Council Sarajevo includes preparation of application, implementation, testing and all functionalities that accompany the work flow processes of the council. In order to simplify the management of all materials and relations between different areas within the work of the city council, this web application was built using the Wordpress CMS (Content Management System) platform. The application contains many functionalities, of which we will single out several: Dynamic content binding, Creation of PDF reports from the input material, Sending SMS and email notifications.',
    // PROJECT OVERVIEW
    potitle: 'PROJECT OVERVIEW',
    poicon1: poicon1OCC,
    poicon1txt: 'TECHNOLOGY ASSESSMENT',
    poicon2: poicon2OCC,
    poicon2txt: 'CUSTOM POST TYPES AND VIEWS',
    poicon3: poicon3OCC,
    poicon3txt: 'WEB DESIGN & DEVELOPMENT',
    poicon4: poicon4OCC,
    poicon4txt: 'DEPLOYMENT ',
    // KOCKA
    kocka: [
      {
        img: kocka1OCC,
        title: 'TECHNOLOGY ASSESSMENT',
        bgcol: 'bg-doc-occ-secondary',
        text1:
          'Technologies used to create this web application are listed for each application layer. MySQL and File system are used for database layer. For the application layer we used PHP, and last but not least, several technologies for presentation layer: HTML 5, CSS3, JavaScript, jQuery.',
      },
      {
        img: kocka2OCC,
        title: 'CUSTOM POST TYPES AND VIEWS',
        bgcol: 'bg-doc-occ-primary',
        text1:
          'This application contains a number of custom post types that are interconnected by different connections. These post types are used to manage the various entities in the functioning of the city council such as: council meetings, councilors, public procurement, etc. All post types are represented by the corresponding views.',
      },
    ],
    // RELATIVE LINKS
    rellinklight: 'RELEVANT',
    rellinkbold: 'LINKS',
    rellinkbgcolor: 'bg-doc-occ-secondary',
    // RELATIVE LINKS icons
    rellinktitle: 'ONLINE CITY COUNCIL',
    rellinkicon1: relevant1,
    rellinktext1: 'WEBSITE',
    rellinkhref1: 'https://gradskovijece.sarajevo.ba/',
    // CONTAINER ABOVE FOOTER
    lwttext1: "LET'S",
    lwttext2: 'WORK',
    lwttext3: 'TOGETHER',
  },
  // TODO - HEALTH REPORTING FORMS
  {
    id: 7,
    title: 'HEALTH REPORTING FORMS',
    subtitle: 'PUBLIC INSTITUTE OF HEALTH FB&H',
    urlTo: '/health-reporting-forms',
    src: logoHRF,
    srcfi: featHRF,
    logow: 'w-75',
    bcHeadera: '#0083cb',
    headerimg: bgImgMainHRF,
    boxicont1: 'MONTHS OF WORK',
    boxicont2: 'DATA SETS ANALYZED',
    boxicont3: 'COFFEE CUPS DRANK',
    boxicont4: 'YEAR LAUNCHED',
    // BUTTON
    btnscrolldown: 'SCROLL DOWN TO SEE MORE',
    btnseeprojpage: 'SEE PROJECT PAGE',
    // META TAG i TITLE u head za React-Helmet
    rhdesc:
      'DOC.BA is a company with young experts in different fields of informational technologies, computer sciences, design and digital marketing.',
    rhlocale: 'en_US',
    rhlinkco: '/contact',
    // CASE STUDY
    casestadytitle: 'CASE STUDY',
    casestadybtn: 'btn-doc-hrf-primary',
    casestadytext:
      'All public and private health care institutions, as well as private practitioners in the Federation, are obliged to fill in and submit individual reporting forms within their scope of work to the competent Cantonal Institute of Public Health on the forms prescribed by the Regulations. In addition, for certain occurrences that are monitored in the health sector, health institutions (like responsible healthcare professionals) fill in and submit, in the prescribed manner and within legally prescribed deadlines, the appropriate summary periodic reporting forms.',
    // THE SOLUTION:
    solutionlight: 'THE',
    solutionbold: 'SOLUTION',
    solutionbgcol: 'bg-doc-hrf-secondary',
    solutiontext1:
      'Our company, together with a partner company, was tasked with creating a software solution for individual reporting forms - registers and summary reports in healthcare for the needs of the Institute of Public Health of the Federation of BiH. 20 individual forms and more than 30 summary forms have been created and each form includes standard CRUD functionality. For the purpose of preserving the previously collected data, the functionality of data import has been implemented. In addition to these functionalities, it was also necessary to implement user roles, reporting system, import using excel tables and many others.',
    // PROJECT OVERVIEW
    potitle: 'PROJECT OVERVIEW',
    poicon1: poicon1HRF,
    poicon1txt: 'TECHNOLOGY ASSESSMENT',
    poicon2: poicon2HRF,
    poicon2txt: 'SECURITY',
    poicon3: poicon3HRF,
    poicon3txt: 'INDIVIDUAL AND SUMMARY FORMS',
    poicon4: poicon4HRF,
    poicon4txt: 'REPORTING SYSTEM',
    // KOCKA
    kocka: [
      {
        img: kocka1HRF,
        title: 'TECHNOLOGY ASSESSMENT',
        bgcol: 'bg-doc-hrf-primary',
        text1:
          'Due to the specific requirements of the project, it was necessary to approach the project in a "database first" manner and for this reason the following were used: MSSQL and ASP.NET. Microsoft SQL Server is a relational database management system developed by Microsoft and ASP.NET extends the .NET developer platform with tools and libraries specifically for building web apps.',
      },
      {
        img: kocka2HRF,
        title: 'SECURITY',
        bgcol: 'bg-doc-hrf-secondary',
        text1:
          'Given the sensitivity of the data being fed into this information system, it was necessary to define excellent security that would protect the data from possible attacks. Security is implemented in different ways such as the classic login with complex password and implementation of VPN security.',
      },
      {
        img: kocka3HRF,
        title: 'INDIVIDUAL AND SUMMARY FORMS',
        bgcol: 'bg-doc-hrf-primary',
        text1:
          'The software enables a unified look of the application solution for the simplest possible input of data into individual reporting forms (applications) to be filled in by health institutions. On the basis of these forms, system will create registers from which users can generate reports and make analysis and recommendations for Health sector decision-making. In addition, it enables the same for summary reporting forms.',
      },
      {
        img: kocka4HRF,
        title: 'REPORTING SYSTEM',
        bgcol: 'bg-doc-hrf-secondary',
        text1:
          'Very important functionality of this software is generating reports for each individual report, and for periods of time as needed by the user for each summary report. These reports can be dynamically  generated in three different formats: .xlsx, .docx and .pdf. The user needs to simply select the period for which report is created and desired format.',
      },
    ],
    // RELATIVE LINKS
    rellinklight: 'RELEVANT',
    rellinkbold: 'LINKS',
    rellinkbgcolor: 'bg-doc-hrf-primary',
    // RELATIVE LINKS icons
    rellinktitle: 'HEALTH REPORTING FORMS',
    rellinkicon1: relevant1,
    rellinktext1: 'WEBSITE',
    rellinkhref1: 'https://doc.ba/zdravstvo/index.html',
    // CONTAINER ABOVE FOOTER
    lwttext1: "LET'S",
    lwttext2: 'WORK',
    lwttext3: 'TOGETHER',
  },
  // TODO - IPADRAM
  {
    id: 8,
    title: 'IPA DRAM',
    subtitle: 'CIVIL PROTECTION AND DISASTER RISK MANAGEMENT',
    urlTo: '/ipadram',
    src: logoIPADRAM,
    srcfi: featIPADRAM,
    logow: 'w-75',
    bcHeadera: '#eb262a',
    headerimg: bgImgMainIPADRAM,
    boxicont1: 'MONTHS OF WORK',
    boxicont2: 'DATA SETS ANALYZED',
    boxicont3: 'COFFEE CUPS DRANK',
    boxicont4: 'YEAR LAUNCHED',
    // BUTTON
    btnscrolldown: 'SCROLL DOWN TO SEE MORE',
    btnseeprojpage: 'SEE PROJECT PAGE',
    // META TAG i TITLE u head za React-Helmet
    rhdesc:
      'DOC.BA is a company with young experts in different fields of informational technologies, computer sciences, design and digital marketing.',
    rhlocale: 'en_US',
    rhlinkco: '/contact',
    // CASE STUDY
    casestadytitle: 'CASE STUDY',
    casestadybtn: 'btn-doc-ipadram-primary',
    casestadytext:
      'The IPA DRAM project which deals with risk assessment focusing on floods, fires and earthquakes has procured services from DOC.ba to gather priority datasets required by the ERRA platform. The ERRA platform is a software that is used for analysis of these risks however, this platform needs data from multiple government institutions in order to work. It covers Bosnia & Herzegovina, Serbia and Montenegro.',
    // THE SOLUTION:
    solutionlight: 'THE',
    solutionbold: 'SOLUTION',
    solutionbgcol: 'bg-doc-ipadram-secondary',
    solutiontext1:
      'Our company was provided points of contact for each country that the data is needed from and a list of priority datasets. The methodology of gathering the data was to write letters and conduct meetings with the agencies that produce the data in order to try and gather 51 priority datasets needed by the project for all countries. Additional complexity is present in Bosnia & Herzegovina where these datasets need to be gathered for two entities and their respective Civil Protection Agencies (CPs).',
    // PROJECT OVERVIEW
    potitle: 'PROJECT OVERVIEW',
    poicon1: poicon1IPADRAM,
    poicon1txt: 'TECHNICAL & LEGAL ANALYSIS',
    poicon2: poicon2IPADRAM,
    poicon2txt: 'DATA FLOW & RECOMMENDATIONS',
    poicon3: poicon3IPADRAM,
    poicon3txt: 'CREATING REPORT',
    poicon4: poicon4IPADRAM,
    poicon4txt: 'PUBLIC DATA COLLECTION',
    // KOCKA
    kocka: [
      {
        img: kocka1IPADRAM,
        title: 'TECHNICAL & LEGAL ANALYSIS',
        bgcol: 'btn-doc-ipadram-primary',
        text1:
          'The legal evaluation is a very important step as it defined how we can obtain the dataset. The dataset can be publicly available in the best case and highly restricted in other cases requiring the data publisher to explain the usage, pay fees and outright prohibit data sharing. Sometimes technical analysis is not needed, especially for raster datasets shared via the WMS service that have little or no attributes. This was more demanding task when dealing with vector data as we needed to evaluate what type of geometry we need, what quality and topology rules for the geometry do we need and what attributes in comparison to what is provided.',
      },
      {
        img: kocka2IPADRAM,
        title: 'DATA FLOW RECOMMENDATIONS & CREATING REPORT',
        bgcol: 'bg-doc-ipadram-secondary',
        text1:
          'Thanks to the extenuating circumstance of our company being located in B&H and excellent local support, we held productive meetings and came to an agreement about creating data flow and recommendations for B&H.',
      },
      {
        img: kocka3IPADRAM,
        title: 'PUBLIC DATA COLLECTION',
        bgcol: 'bg-doc-ipadram-primary',
        text1:
          'Since most of the information was not available, certain data were collected online. We have collected population statistics from official websites of statistical institutions for B&H, Serbia and Montenegro. This information was publicly available and free of charge. Data on boundaries of municipalities, states, etc. were collected using Open Street Map.',
      },
    ],
    // RELATIVE LINKS
    rellinklight: 'RELEVANT',
    rellinkbold: 'LINKS',
    rellinkbgcolor: 'bg-doc-ipadram-secondary',
    // RELATIVE LINKS icons
    rellinktitle: 'IPA DRAM',
    rellinkicon1: relevant1,
    rellinktext1: 'WEBSITE',
    rellinkhref1: 'http://erradram.mydewetra.org/apps/mydewetra/index_ipadram.html',
    // CONTAINER ABOVE FOOTER
    lwttext1: "LET'S",
    lwttext2: 'WORK',
    lwttext3: 'TOGETHER',
  },
  // TODO - NOVO SARAJEVO
  {
    id: 9,
    title: 'NOVO SARAJEVO',
    subtitle: 'OFFICIAL MUNICIPALITY WEBSITE',
    urlTo: '/novo-sarajevo',
    src: logoNOSA,
    srcfi: featNOSA,
    logow: 'w-50',
    bcHeadera: '#2d3b60',
    headerimg: bgImgMainNOSA,
    boxicont1: 'MONTHS OF WORK',
    boxicont2: 'NO. OF POSTS',
    boxicont3: 'COFFEE CUPS DRANK',
    boxicont4: 'YEAR LAUNCHED',
    // BUTTON
    btnscrolldown: 'SCROLL DOWN TO SEE MORE',
    btnseeprojpage: 'SEE PROJECT PAGE',
    // META TAG i TITLE u head za React-Helmet
    rhdesc:
      'DOC.BA is a company with young experts in different fields of informational technologies, computer sciences, design and digital marketing.',
    rhlocale: 'en_US',
    rhlinkco: '/contact',
    // CASE STUDY
    casestadytitle: 'CASE STUDY',
    casestadybtn: 'btn-doc-nosa-primary',
    casestadytext:
      'The territory of the Municipality of Novo Sarajevo occupies the central part of the Sarajevo Field, and is the administrative, business, cultural, educational and trade service part of the City of Sarajevo. The municipality is the basic unit of local self-government. The old municipality website needed to be refreshed and made it easier to use and administer the site.',
    // THE SOLUTION:
    solutionlight: 'THE',
    solutionbold: 'SOLUTION',
    solutionbgcol: 'bg-doc-nosa-secondary',
    solutiontext1:
      "One of the most difficult steps in this project was to migrate all content from one CMS to another. This was a real challenge considering that it was 10,000 posts with a lot of media content. Special attention is devoted to the structure of content that had to follow the functioning of the municipality itself, which is not simple. Main navigation had to be structured so visitors can quickly find what they are looking for since this website's basic purpose is to make information available to citizens.",
    // PROJECT OVERVIEW
    potitle: 'PROJECT OVERVIEW',
    poicon1: poicon1NOSA,
    poicon1txt: 'WEBSITE DEVELOPMENT',
    poicon2: poicon2NOSA,
    poicon2txt: 'NEW STRUCTURE SETTING',
    poicon3: poicon3NOSA,
    poicon3txt: 'POST MIGRATION',
    poicon4: poicon4NOSA,
    poicon4txt: 'DEPLOYMENT',
    // KOCKA
    kocka: [
      {
        img: kocka1NOSA,
        title: 'WEBSITE DEVELOPMENT',
        bgcol: 'bg-doc-nosa-primary',
        text1:
          'The logo was designed in accordance with the existing brand guidelines, respecting the shape and colors of the initial design. Using modern lines, shape and typeface, while keeping in consideration usability in various material, print and digital, we came up with a very simple, yet unique design. It can be used across various media ranging from stationary, advertising material, as well as on website and digital media.',
      },
      {
        img: kocka2NOSA,
        title: 'POST MIGRATION',
        bgcol: 'bg-doc-nosa-secondary',
        text1:
          'Migrating articles from Joomla CMS to a newly created page was a very important and complex step that was done under controlled conditions. It was crucial not to lose any content and to rearrange posts under new categories according to new website structure.',
      },
    ],
    // RELATIVE LINKS
    rellinklight: 'RELEVANT',
    rellinkbold: 'LINKS',
    rellinkbgcolor: 'bg-doc-nosa-primary',
    // RELATIVE LINKS icons
    rellinktitle: 'NOVO SARAJEVO',
    rellinkicon1: relevant1,
    rellinktext1: 'WEBSITE',
    rellinkhref1: 'https://novosarajevo.ba/',
    // CONTAINER ABOVE FOOTER
    lwttext1: "LET'S",
    lwttext2: 'WORK',
    lwttext3: 'TOGETHER',
  },
  // TODO - POLIGLOT
  {
    id: 10,
    title: 'POLIGLOT',
    subtitle: 'FOREIGN LANGUAGES AND INFORMATICS SCHOOL',
    urlTo: '/poliglot',
    src: logoPOLIGLOT,
    srcfi: featPOLIGLOT,
    logow: 'w-75',
    bcHeadera: '#1b3d68',
    headerimg: bgImgMainPOLIGLOT,
    boxicont1: 'DAYS OF WORK',
    boxicont2: 'HOURS SPENT',
    boxicont3: 'COFFEE CUPS DRANK',
    boxicont4: 'YEAR LAUNCHED',
    // BUTTON
    btnscrolldown: 'SCROLL DOWN TO SEE MORE',
    btnseeprojpage: 'SEE PROJECT PAGE',
    // META TAG i TITLE u head za React-Helmet
    rhdesc:
      'DOC.BA is a company with young experts in different fields of informational technologies, computer sciences, design and digital marketing.',
    rhlocale: 'en_US',
    rhlinkco: '/contact',
    // CASE STUDY
    casestadytitle: 'CASE STUDY',
    casestadybtn: 'btn-doc-poliglot-orange text-white',
    casestadytext:
      '"Škola za strane jezike i informatiku Poliglot" is Sarajevo based school for languages and computing which has long history of providing high quality classes for foreign languages. Over the years, the school has been adopting new technologies with the goal of providing courses in accordance with the latest market standards. Our team was tasked with refreshing the school\'s brand visuals, as well as improvoving the online presence of Poliglot through their new web page and continuous social media campaigns.',
    // THE SOLUTION:
    solutionlight: 'THE',
    solutionbold: 'SOLUTION',
    solutionbgcol: 'bg-doc-poliglot-plava',
    solutiontext1:
      'As we mentioned before, Poliglot had long history of maintaining high standards in its brand presence and courses. We had important task to bring their brand successfully to the new decade of digital technologies, and to present their services to their audience in the best possible way.',
    solutiontext2:
      'We redesigned their visual appearance inspired by their original visual identity, and gave it a more modern look. Following the logo redesign, we developed a new web page in accordance with their new visual look. We also had user experience in mind, thus making all the necessary content and information easily accessible by web visitors. In the end, we continued our previous cooperation on social media promotion, and we continued to increase traffic on their web page, as well as social media networks.',
    solutiontext3: '',
    // PROJECT OVERVIEW
    potitle: 'PROJECT OVERVIEW',
    poicon1: poicon1POLIGLOT,
    poicon1txt: 'LOGO AND STATIONARY DESIGN',
    poicon2: poicon2POLIGLOT,
    poicon2txt: 'WEBSITE DESIGN & DEVELOP',
    poicon3: poicon3POLIGLOT,
    poicon3txt: 'SOCIAL MEDIA CAMPAIGNS',
    poicon4: poicon4POLIGLOT,
    poicon4txt: 'HOSTING & MAINTENANCE',
    // KOCKA
    kocka: [
      {
        img: kocka1POLIGLOT,
        title: 'LOGO DESIGN',
        bgcol: 'bg-doc-poliglot-orange',
        text1:
          'Following the latest visual trends and standards, DOC.ba updated and redesigned the logo of our long term client „Škola za strane jezike i informatiku Poliglot“. The main goal was to stay close to the original visual identity, whilst making a new logo look elegant and modern.',
      },
      {
        img: kocka2POLIGLOT,
        title: 'BROCHURE DESIGN',
        bgcol: 'bg-doc-poliglot-plava',
        text1:
          'Depending on the type of service or product you want to promote, and also the target audience, different methods bring different results. Poliglot has recognized the benefit of creating traditional paper brochures regarding one of their projects, and DOC.ba was happy to respond to our client needs.',
      },
      {
        img: kocka3POLIGLOT,
        title: 'BILLBOARD DESIGN',
        bgcol: 'bg-doc-poliglot-orange',
        text1:
          "Same as paper brochures, billboard is one of the most commonly used print promotion methods. We fulfilled our client's needs to have an efficient and impactful billboard poster which will generate leads. This was also an excellent opportunity to present the new visual identity and logo we developed for them.",
      },
      {
        img: kocka4POLIGLOT,
        title: 'WEBSITE DESIGN & DEV',
        bgcol: 'bg-doc-poliglot-plava',
        text1:
          'As with every other aspect of their brand, Poliglot has invested resources into creating new and modern looking web page for their language school.',
        text2:
          'The main goal of this project was to utilize the latest technologies, as well as latest visual standards, in creation of their new website. The improved user experience was the main focus of this project, and every aspect of the page was developed taking that into consideration.',
        text3:
          'In the end, we have created a user-friendly, fully responsive website which offers all of the information about Poliglot and its services in an elegant and easy-to-understand way.',
      },
      {
        img: kocka5POLIGLOT,
        title: 'SOCIAL MEDIA CAMPAIGNS',
        bgcol: 'bg-doc-poliglot-orange',
        text1:
          "It's been couple of years since Poliglot has recognized the fact that its current users, as well as future ones, are present online. Because of this, we have cooperated on numerous Social Media Campaigns that include Facebook and Instagram platforms, and also Google, whose goal was to generate leads.",
        text2:
          "Our team creates the complete strategy and content of these campaigns, while keeping the focus on being effective and profitable for our client. Every aspect of Poliglot's online presence was being carefully tailored to its specific needs. The biggest proof of cost-effectiveness of these campaigns is the fact that Poliglot is investing more and more resources every year into its online presence, thus making other alternative promoting channels obsolete.",
      },
    ],
    // RELATIVE LINKS
    rellinklight: 'RELEVANT',
    rellinkbold: 'LINKS',
    rellinkbgcolor: 'bg-doc-poliglot-plava',
    // RELATIVE LINKS icons
    rellinktitle: 'POLIGLOT',
    rellinkicon1: relevant1,
    rellinktext1: 'WEBSITE',
    rellinkhref1: 'https://www.poliglot.ba/',
    rellinkicon2: relevant2,
    rellinktext2: 'ON FACEBOOK',
    rellinkhref2: 'https://www.facebook.com/poliglot.ba/',
    rellinkicon3: relevant3,
    rellinktext3: 'ON INSTAGRAM',
    rellinkhref3: 'https://www.instagram.com/poliglot_1940/',
    // CLIENTS TESTEMONIAL
    testemonialh4: 'CLIENT TESTIMONIAL',
    testemonialimg: testemonialPOLIGLOT,
    testemonialtxt:
      'By cooperating with DOC.ba team, the biggest value I get is that I can solve all my IT problems with just one phone call. Whether it’s my company’s e-mail and web page that needs attention, or maybe Google, Facebook & Instagram, DOC.ba team will solve all my problems.',
    testemonialstr: 'Mirnesa Dž.,',
    testemonialem: 'manager at POLIGLOT',
    // CONTAINER ABOVE FOOTER
    lwttext1: "LET'S",
    lwttext2: 'WORK',
    lwttext3: 'TOGETHER',
  },
  // TODO - IUG
  {
    id: 11,
    title: 'IUG',
    subtitle: 'INTERNATIONAL UNIVERSITY OF GORAŽDE',
    urlTo: '/iug',
    src: logoIUG,
    srcfi: featIUG,
    logow: 'w-50',
    bcHeadera: '#1e376f',
    headerimg: bgImgMainIUG,
    boxicont1: 'DAYS OF WORK',
    boxicont2: 'HOURS SPENT',
    boxicont3: 'COFFEE CUPS DRANK',
    boxicont4: 'YEAR LAUNCHED',
    // BUTTON
    btnscrolldown: 'SCROLL DOWN TO SEE MORE',
    btnseeprojpage: 'SEE PROJECT PAGE',
    // META TAG i TITLE u head za React-Helmet
    rhdesc:
      'DOC.BA is a company with young experts in different fields of informational technologies, computer sciences, design and digital marketing.',
    rhlocale: 'en_US',
    rhlinkco: '/contact',
    // CASE STUDY
    casestadytitle: 'CASE STUDY',
    casestadybtn: 'btn-doc-iug-tirkiz',
    casestadytext:
      'IUG is an acronym for International University of Goražde, which is located in BPK, Goražde, Bosnia and Herzegovina. The client has recognized the market needs for adequate online presence and visual identity improvements, as well as marketing via social media and other means. Therefore, our task was to deliver the necessary improvements to client’s brand identity, online presence and improve traffic via social networks.',
    // THE SOLUTION:
    solutionlight: 'THE',
    solutionbold: 'SOLUTION',
    solutionbgcol: 'bg-doc-iug-plava',
    solutiontext1:
      'According to the client needs, we have conducted a market research to identify latest trends related to educational/university niche, and come up with solutions that would meet client needs. We used the latest technology in the process, keeping an eye on the visual appearance and functionality in the assets provided.',
    solutiontext2:
      'The project started by engaging our design team to create a new visual identity that respects the IUG mission and vision, as well as already defined guidelines and colors. Upon defining the new visual identity, we worked on designing and developing a new, modern, up to date website with close cooperation with client, in preparing necessary contents, and polishing the layouts and structure.',
    solutiontext3: '',
    // PROJECT OVERVIEW
    potitle: 'PROJECT OVERVIEW',
    poicon1: poicon1IUG,
    poicon1txt: 'LOGO AND STATIONARY DESIGN',
    poicon2: poicon2IUG,
    poicon2txt: 'WEBSITE DESIGN & DEVELOP',
    poicon3: poicon3IUG,
    poicon3txt: 'SOCIAL MEDIA CAMPAIGNS',
    poicon4: poicon4IUG,
    poicon4txt: 'HOSTING & MAINTENANCE',
    // KOCKA
    kocka: [
      {
        img: kocka1IUG,
        title: 'LOGO DESIGN',
        bgcol: 'bg-doc-iug-tirkiz',
        text1:
          'The logo was designed in accordance with the existing brand guidelines, respecting the shape and colors of the initial design. Using modern lines, shape and typeface, while keeping usability of the materials, bith printed and digital, we came up with a very simple, yet unique design. It can be used across various media ranging from stationary, advertising material, as well as on website and digital media.',
      },
      {
        img: kocka2IUG,
        title: 'BUSINESS CARDS DESIGN',
        bgcol: 'bg-doc-iug-plava',
        text1:
          'The business cards are essential for every business, and therefore that was one of the client’s initial requests, as they wished to replace their old business cards with the new ones. Therefore, we delivered a fairly simple, double-sided, multi-lingual design to meet their needs.',
      },
      {
        img: kocka3IUG,
        title: 'BILLBOARD DESIGN',
        bgcol: 'bg-doc-iug-orange',
        text1:
          'As the new academic year was about to welcome new students, the client’s need expanded, and there was a need for a billboard advertising. Using the new visual identity and content, we provided the client with a clean, readable and eye-appealing billboard design.',
      },
      {
        img: kocka4IUG,
        title: 'WEBSITE DESIGN & DEV',
        bgcol: 'bg-doc-iug-plava',
        text1:
          'Wishing to have an updated look, and improved online presence, and easier direct communication with potential students and the public in general, IUG has requested a new website to be developed in accordance with the specified needs and requirements, as well as the new visual identity.',
        text2:
          'Following the requirements, our web development doctors have been engaged, delivering a modern, functional university website, using the up to date technology and following the latest design trends.',
        text3: 'The website is fully mobile-responsive, providing quality user experience.',
      },
      {
        img: kocka5IUG,
        title: 'SOCIAL MEDIA CAMPAIGNS',
        bgcol: 'bg-doc-iug-crvena',
        text1:
          'Our digital marketing department has worked out an extensive social media campaign with the main goal of raising brand awareness of the university, focusing on the local region. The campaign was also a way to emphasize the new rebrand of the university, and to refresh the Social Media accounts with the state-of-the-art standards.',
        text2:
          'The platforms used in the advertising campaign where Facebook, Google, Instagram, and we are also planning on expanding it to Youtube, when the clients deliver the video AD.',
      },
    ],
    // RELATIVE LINKS
    rellinklight: 'RELEVANT',
    rellinkbold: 'LINKS',
    rellinkbgcolor: 'bg-doc-iug-plava',
    // RELATIVE LINKS icons
    rellinktitle: 'IUG',
    rellinkicon1: relevant1,
    rellinktext1: 'WEBSITE',
    rellinkhref1: 'https://www.iug.ba/',
    rellinkicon2: relevant2,
    rellinktext2: 'ON FACEBOOK',
    rellinkhref2: 'https://www.facebook.com/',
    rellinkicon3: relevant3,
    rellinktext3: 'ON INSTAGRAM',
    rellinkhref3: 'https://www.instagram.com/',
    // CLIENTS TESTEMONIAL
    testemonialh4: 'CLIENT TESTIMONIAL',
    testemonialimg: testemonialIUG,
    testemonialtxt:
      'Before our collaboration with DOC.ba team, we had to have multiple agencies regarding our digital presence. With DOC.ba, we have solved all of our digital needs with one company. Their timely response, latest technologies and personal approach to our University is what separates them from other similar companies we have worked with.',
    testemonialstr: 'mr.sci. Elma B.,',
    testemonialem: 'founder of IUG',
    // CONTAINER ABOVE FOOTER
    lwttext1: "LET'S",
    lwttext2: 'WORK',
    lwttext3: 'TOGETHER',
  },
  // TODO - STILLES INTERIORS
  {
    id: 12,
    title: 'STILLES INTERIORS',
    subtitle: 'FURNITURE DESIGN',
    urlTo: '/stilles',
    src: logoSTILLES,
    srcfi: featSTILLES,
    logow: 'w-75',
    bcHeadera: '#8c7c72',
    headerimg: bgImgMainSTILLES,
    boxicont1: 'MONTHS OF WORK',
    boxicont2: 'NEGATIVE FEEDBACK',
    boxicont3: 'COFFEE CUPS DRANK',
    boxicont4: 'YEAR LAUNCHED',
    // BUTTON
    btnscrolldown: 'SCROLL DOWN TO SEE MORE',
    btnseeprojpage: 'SEE PROJECT PAGE',
    // META TAG i TITLE u head za React-Helmet
    rhdesc:
      'DOC.BA is a company with young experts in different fields of informational technologies, computer sciences, design and digital marketing.',
    rhlocale: 'en_US',
    rhlinkco: '/contact',
    // CASE STUDY
    casestadytitle: 'CASE STUDY',
    casestadybtn: 'btn-doc-stilles-primary',
    casestadytext:
      'Company Stilles d.o.o. exists in the B&H market from 2000. It has 28 employees, showroom of 300sqm in Sarajevo and a production facility of 1000sqm also in Sarajevo. They wanted a decent present of their company through the website that must reflect creativity and simplicity.',
    // THE SOLUTION:
    solutionlight: 'THE',
    solutionbold: 'SOLUTION',
    solutionbgcol: 'bg-doc-stilles-secondary',
    solutiontext1:
      'Stilles creative team brings its knowledge and experience, imagination and innovation into every interior design, holding on to basic idea from the start till the final realization. For this reason, our solution needed to be imaginative and innovative as well. We created this very dynamic and yet simple website with all important segments.',
    // PROJECT OVERVIEW
    potitle: 'PROJECT OVERVIEW',
    poicon1: poicon1STILLES,
    poicon1txt: 'WEBSITE DEVELOPMENT',
    poicon2: poicon2STILLES,
    poicon2txt: 'MULTILINGUAL WEBSITE',
    poicon3: poicon3STILLES,
    poicon3txt: 'DYNAMIC DESIGN',
    poicon4: poicon4STILLES,
    poicon4txt: 'DEPLOYMENT',
    // KOCKA
    kocka: [
      {
        img: kocka1STILLES,
        title: 'WEBSITE DEVELOPMENT',
        bgcol: 'bg-doc-stilles-primary',
        text1:
          'The website needed to be easy to maintain and to add new content. Technologies used to create this website are listed for each application layer. MySQL and File system are used for database layer. For the application layer we used PHP, and for presentation layer: HTML 5, CSS3, JavaScript, jQuery.',
      },
      {
        img: kocka2STILLES,
        title: 'MULTILINGUAL WEBSITE',
        bgcol: 'bg-doc-stilles-secondary',
        text1:
          'To cover the appropriate markets, clients often want their websites to be multilingual. This is also the case with this site, which has 2 languages implemented. The language is selected simply by clicking on the appropriate flag within the navigation.',
      },
    ],
    // RELATIVE LINKS
    rellinklight: 'RELEVANT',
    rellinkbold: 'LINKS',
    rellinkbgcolor: 'bg-doc-stilles-primary',
    // RELATIVE LINKS icons
    rellinktitle: 'STILLES INTERIORS',
    rellinkicon1: relevant1,
    rellinktext1: 'WEBSITE',
    rellinkhref1: 'http://stillesenterijeri.com/',
    // CONTAINER ABOVE FOOTER
    lwttext1: "LET'S",
    lwttext2: 'WORK',
    lwttext3: 'TOGETHER',
  },
];

export default projects;
